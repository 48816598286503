import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Routes, Route, Link, Outlet } from 'react-router-dom'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import List from './Template/List'
import Show from './Template/Show'
import Edit from './Template/Edit'
import { Mixpanel } from '../Common/Mixpanel'

const TemplateDashboard = () => {
    return (
                <div>
                    <h1 className="text-3xl text-black pb-4">Templates</h1>
                    <div className="flex flex-wrap">
                        <div className="flex-grow w-full mx-auto">
                            <Routes>
                            <Route path={`:templateId/edit`} element={<Edit />}/>
                            <Route path={`:templateId`} exact element={<Show />} />
                            <Route index exact element={<List  />} />
                            </Routes>
                            <Outlet />
                        </div>
                    </div>
                </div>
    )
}

export default TemplateDashboard
