
import React, {useContext} from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import QuickStats from './QuickStats'
import ViewGraphs from './ViewGraphs'
import LatestVideos from './LatestVideos'
import LatestEvents from './LatestEvents'
import { DashboardTimeContext} from '../stores/TimePeriod'
import Loading from './loading'
import { AccountWithGraph } from '../custom_types'

export const CONNECTIONS = gql`
query Connections($limit: Int!, $period: TimePeriod!, $startTime: Date, $endTime: Date) {
  currentAccount {
    id
    videoConnections(limit: $limit, sortBy: CreatedAt, sortDirection: DESC) {
        items {
            id
            createdAt
            channel {
                id
                title
            }
            tags {
                id
                name
            }
            publicPoster
            metadata
            title
            stats(period: $period, startTime: $startTime, endTime: $endTime) {
                plays
                engagementTime
                playsPerUniqueVideo
                viewThroughRate
            }
        }
    }
  }
}
`

export const PLAYED = gql`
query LatestPlayed($limit: Int!) {
  currentAccount {
    id
    latestPlayedVideos(limit: $limit, unique: true) {
      id
      title
      createdAt
      channel {
        id
        title
      }
      tags {
        id
        name
      }
      metrics(limit: 1, sortBy:SessionStart, sortDirection:DESC) {
          items {
        viewThroughRate
        sessionStart
        client {
          city
          country
          latitude
          longitude
        }
          }
      }
      publicPoster
      title
    }
  }
}
`

export const STATS = gql`
query LatestPlayed($period: TimePeriod!, $resolution: AggregationPeriod!, $startTime: Date, $endTime: Date) {
  currentAccount {
    id
    graph: stats(period: $period, startTime: $startTime, endTime: $endTime) {
      data: playsPer(period: $resolution) {
        key
        value
      }
    }
    stats: stats(period: $period, startTime: $startTime, endTime: $endTime) {
      plays
      playsPerUniqueVideo
      engagementTime
      viewThroughRate
    }
  }
}
`

export const DASHBOARD_QUERY = gql`
query DashboardQuery($limit: Int!, $period: TimePeriod!, $resolution: AggregationPeriod!, $startTime: Date, $endTime: Date) {
  currentAccount {
    id
    videoConnections(limit: $limit, sortBy: CreatedAt, sortDirection: DESC) {
        items {
            id
            createdAt
            channel {
                id
                title
            }
            tags {
                id
                name
            }
            publicPoster
            metadata
            title
            stats(period: $period, startTime: $startTime, endTime: $endTime) {
                plays
                playsPerUniqueVideo
                engagementTime
                viewThroughRate
            }
        }
    }
      latestPlayedVideos(limit: $limit, unique: true) {
      id
      title
      createdAt
      channel {
        id
        title
      }
      tags {
        id
        name
      }
      publicPoster
      title
      metrics(limit: 25, sortBy:SessionStart, sortDirection:DESC) {
          items {
        viewThroughRate
        sessionStart
        client {
          city
          country
          latitude
          longitude
        }
          }
      }
      stats(period: $period, startTime: $startTime, endTime: $endTime) {
        plays
        playsPerUniqueVideo
        engagementTime
        viewThroughRate
      }
    }
    graph: stats(period: $period, startTime: $startTime, endTime: $endTime) {
      data: playsPer(period: $resolution) {
        key
        value
      }
    }
    stats: stats(period: $period, startTime: $startTime, endTime: $endTime) {
      plays
      engagementTime
      playsPerUniqueVideo
      viewThroughRate
    }
  }
}
`

interface Vars {
        limit: number;
        period: String;
        resolution: String;
        startTime: Date;
        endTime: Date;
}

interface VarsProps {
vars: Vars
}

const Dashboard : React.FC<VarsProps> = ({vars}) => {
    return (
        <div className="flex-grow w-full mx-auto sm:px-4 pt-6 pb-8">
          <Query query={STATS} variables={vars} >
        {(result) => {
            const { loading, error, data} = result
            if (loading) return <Loading />
                if (error) {
                    console.log(error)
                    return <div>Error</div>
                }
              return (
                <>
                  <QuickStats  {...data.currentAccount.stats}/>
                  <ViewGraphs list={data.currentAccount.graph} />
                </>
            )
            }}
          </Query>
          <div className="flex flex-wrap -mx-4">
            <Query query={CONNECTIONS} variables={vars} >
        {(result) => {
            const { loading, error, data} = result
            if (loading) return <Loading />
                if (error) {
                    console.log(error)
                    return <div>Error</div>
                }
              return (
                <>
                  <LatestVideos videos={data.currentAccount.videoConnections.items} />
                </>
            )
            }}
          </Query>
            <Query query={PLAYED} variables={vars} >
        {(result) => {
            const { loading, error, data} = result
            if (loading) return <Loading />
                if (error) {
                    console.log(error)
                    return <div>Error</div>
                }
              return (
                <>
                <LatestEvents videos={data.currentAccount.latestPlayedVideos} />
                </>
            )
            }}
          </Query>
            </div>
        </div>
    )
}


const StatsDashboard : React.FC = () => {
    const {timePeriod} = useContext(DashboardTimeContext);
    const vars = {
        limit: timePeriod.listLimit,
        period: timePeriod.graphPeriod.period,
        resolution: timePeriod.graphPeriod.resolution,
        startTime: timePeriod.startTime,
        endTime: timePeriod.endTime
    }

                return (
                  <div>
                    <div className="flex flex-wrap w-full">
                      <Dashboard  vars={vars}  />
                    </div>
                  </div>
                )
}

export default StatsDashboard
