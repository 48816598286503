import React, {useContext} from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import Loading from '../loading'
import { DashboardTimeContext} from '../../stores/TimePeriod'
import QuickStats from '../QuickStats'
import ViewGraphs from '../ViewGraphs'
import LatestVideos from '../LatestVideos'
import LatestEvents from '../LatestEvents'
import { TagWithGraph } from '../../custom_types'

export const TAGSHOW_QUERY = gql`
query TagShow($id:ID!,$limit: Int!,$period: TimePeriod!, $resolution: AggregationPeriod!) {
    tag(id:$id) {
        id
        title
        createdAt
        videoConnections(limit: $limit) {
            items {
                id
                createdAt
                channel {
                    id
                    title
                }
                tags {
                    id
                    name
                }
                publicPoster
                metadata
                title
                stats(period: $period) {
                    plays
                    engagementTime
                    viewThroughRate
                }
            }
        }
        latestPlayedVideos(limit: $limit, unique: true) {
            id
            title
            createdAt
            channel {
                id
                title
            }
            tags {
                id
                name
            }
            publicPoster
            title
            metrics(limit: 25, sortBy:SessionStart, sortDirection:DESC) {
                items {
                    viewThroughRate
                    sessionStart
                    client {
                        city
                        country
                        latitude
                        longitude
                    }
                }
            }
            stats(period: $period) {
                plays
                playsPerUniqueVideo
                engagementTime
                viewThroughRate
            }
        }graph: stats(period: $period) {
            data: playsPer(period: $resolution) {
                key
                value
            }
        }
        stats: stats(period: $period) {
            plays
            engagementTime
            viewThroughRate
        }       createdAt
    }
}
`

const Dashboard : React.FC<TagWithGraph> = ({title, stats, graph, videoConnections, latestPlayedVideos}) => {
    return (
        <div className="flex-grow w-full">
        <h1>{title}</h1>
        <div className="flex-grow mx-auto sm:px-4 pt-6 pb-8">
            <QuickStats  {...stats}/>
            <ViewGraphs list={graph} />
            <div className="flex flex-wrap -mx-4">
                <LatestVideos videos={videoConnections.items} />
                <LatestEvents videos={latestPlayedVideos} />
            </div>
        </div>
        </div>
    )
}



const Show = () => {
    let params = useParams()
    const {timePeriod, dispatch} = useContext(DashboardTimeContext);
    const vars = {
        id: params.tagId,
        limit: timePeriod.listLimit,
        period: timePeriod.graphPeriod.period,
        resolution: timePeriod.graphPeriod.resolution,
        startTime: timePeriod.startTime,
        endTime: timePeriod.endTime
    }

    const { loading, error, data} = useQuery(TAGSHOW_QUERY, {variables: vars})
    if (loading) return <Loading />
        if (error) {
            console.log(error)
            return <div>Error</div>
        }
    return (
        <div>
        <div className="flex flex-wrap">
            <Dashboard {...data.tag}  />
        </div>
        </div>
    )
}

export default Show
