import {CaptionEntry, CaptionList } from '../captions'

export function secondsToVttTimestamp(time: number): VttTimestamp {
    // Convert to milliseconds
    time = time * 1000;
    const milliseconds = Math.floor((time % 1000)),
        seconds = Math.floor((time / 1000) % 60),
        minutes = Math.floor((time / (1000 * 60)) % 60),
        hours = Math.floor((time / (1000 * 60 * 60)) % 24);

    return {
        hours,
        minutes,
        seconds,
        milliseconds
    } as VttTimestamp
}

export function captionEntryToVttEntry(entry: CaptionEntry): VttEntry {
    return {
        text: entry.text || entry.children.map((word) => {
        return word.text
    }).join("")
,
        startTime: secondsToVttTimestamp(entry.startTime),
        endTime: secondsToVttTimestamp(entry.endTime)
    } as VttEntry
}

function numberPrefixed(time: number, places:number = 2): string {
    return time.toString().padStart(places, "0")
}

export function vttTimestampToString(timestamp: VttTimestamp): string {
    return numberPrefixed(timestamp.hours) + ":" + numberPrefixed(timestamp.minutes) + ":" + numberPrefixed(timestamp.seconds) + "." + numberPrefixed(timestamp.milliseconds, 3)
}

export function vttTimestampToPresentationString(timestamp: VttTimestamp): string {
    return numberPrefixed(timestamp.minutes) + ":" + numberPrefixed(timestamp.seconds) + "." + numberPrefixed(timestamp.milliseconds, 3)
}


export function vttTimeStampStringToSeconds(timestamp: string): number {
    const elements = timestamp.split(':')

    let seconds = 0

    elements.reverse().forEach((item, index) => {
        const num = parseFloat(item)
        seconds += num * (Math.pow(60, index))
    })

    return seconds
}

export function vttEntryToString(entry: VttEntry): string {
    return vttTimestampToString(entry.startTime) + " --> " + vttTimestampToString(entry.endTime) + "\n" + balanceVttText(entry.text);
}

export function balanceVttText(text: string): string {
    if(text.indexOf('|') > -1) {
        return text.replace(/\|/g, "\\N")
    }

    return text
}

export function captionEntriesToVttString(entries: CaptionEntry[]): string {
    return entries.map((entry) => captionEntryToVttString(entry)).join("\n\n");
}

export function captionEntryToVttString(entry: CaptionEntry): string {
    return vttEntryToString(captionEntryToVttEntry(entry))
}

export function captionEntryListToVtt(list: CaptionList): string {
    return "WEBVTT\n\n" + captionEntriesToVttString(list.entries);
}


function indexOfSmallest(a) {
 var lowest = 0;
 for (var i = 1; i < a.length; i++) {
  if (a[i] < a[lowest]) lowest = i;
 }
 return lowest;
}

function balanceWordArray(words: string[]): string {
    if(words.length == 1) return words[0]

    let lengthDiffs = []

    for(let i=0;i<words.length;i++) {
        lengthDiffs.push(Math.abs(words.slice(0,i).join(" ").length - words.slice(i).join(' ').length))
    }

    const splitIndex = indexOfSmallest(lengthDiffs)

    return words.slice(0, splitIndex).join(' ') + "\\N" + words.slice(splitIndex).join(' ')
}

export interface VttTimestamp {
    hours: number;
    minutes: number;
    seconds: number;
    milliseconds: number;
}

export interface VttEntry {
    text: string;
    startTime: VttTimestamp;
    endTime: VttTimestamp;
}

