import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Route, Link, useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-apollo'
import { Channel } from '../../types'
import Loading from '../loading'
import { func } from 'prop-types'
import { devDebug } from '@apollo/client/invariantErrorCodes'


//Query that returns info about the current channel
export const GET_CHANNELINFO_QUERY = gql`
query($id:ID!) {
    channel(id:$id)
    {title
      player{id,title}
    public
    noindex}
  
    players{items{title, id}}
  }
`

//Updates the channels properties based on the users input
export const SET_CHANNELINFO_MUTATION = gql`
mutation update($id:ID!, $title:String, $playerId:ID, $isPublic:Boolean, $noindex:Boolean)
  {updateChannel(id:$id, title:$title, playerId:$playerId, isPublic:$isPublic, noindex:$noindex){
    title
    player
    {id,title}
    public
    noindex
    
  }}

`

//Component for editing channels
const Edit = () => {

    //Gets the channelId from the url
    const { channelId } = useParams();

    //useState for a channelObject that later will be used to update the channels values
    const [channel, setChannelParams] = useState(undefined as any)

    //NOTE: This is copied from javascript/dashboard/video/edit.tsx
    //make generic method later
    /**
     * Method for changing and updating channel paramteres
     * @param fieldName The field that will be changed
     * @param value 
     * @returns 
     */
    const setField = (fieldName: string, value?: any) => {

        if (value === undefined) {
            return function (value: any) {
                setChannelParams(
                    {
                        ...channel,
                        [fieldName]: value
                    }
                )
            }
        }
        else {
            setChannelParams(
                {
                    ...channel,
                    [fieldName]: value
                }
            )
        }
    }

    //load data from the database with the gql query
    const { loading, data, error, refetch } = useQuery(GET_CHANNELINFO_QUERY, { variables: { "id": channelId }, fetchPolicy: 'no-cache' });

    //if the channel is undefined, and we have the data...
    if (!channel && data) {
        setChannelParams(data.channel)
    }

    console.log(channel);

    //mutation for saving the changes
    const [saveChanges, saveResult] = useMutation(SET_CHANNELINFO_MUTATION)

    /**
     * Calls the mutation and saves the changes in the database
     */
    const save = () => {
        const vars = {};
        vars['id'] = channelId;

        //checks if the properties exist before assigning them
        if (channel.title) {
            vars['title'] = channel.title;
        }

        if (channel.playerId != undefined) {
            vars['playerId'] = channel.playerId;
        }

        if (channel.public) {
            vars['isPublic'] = channel.public;
        }

        if (channel.noindex) {
            vars['noindex'] = channel.noindex;
        }



        const result = saveChanges({ variables: vars })



        result.then((done) => {

            console.log(data.channel)

        }).catch(v => console.error(v)).finally(() => {
            //when its done writing to the database, refetch the data
            refetch().then(({ data }) => {
                console.log('Refetch is done!', data);
                setChannelParams(data.channel);
            });
        });
    }



    //check if the data is loaded
    if (loading && !channel) return <Loading />
    if (error) {
        console.log(error)
        return <div>Error</div>
    }
    //only display the components if the channel object has the required fields    
    if (!channel) return <span></span>
    return (
        <div>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <h1>Edit channel</h1>
                <TextInput id='title' label='Title' defaultValue={channel.title} onChange={setField('title')}></TextInput>
                <SelectInput id="player" label="Player" defaultValue={channel.player?.id} options={data.players?.items} allowEmpty={true} emptyOption='[From account]' onChange={setField('playerId')} />
                <Checkbox id='public' label='Public' checked={channel.public} onChange={setField('public')}></Checkbox>
                <Checkbox id='noIndex' label='NoIndex' checked={channel.noindex} onChange={setField('noindex')}></Checkbox>
                <button onClick={save} className="mt-8 bg-brand-500 hover:bg-brand-300 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline">{saveResult && saveResult.loading ? "Saving...." : "Save"}</button>
            </div>
        </div>
    )
}


/**
 * Checkbox component for toggling a specific option.
 * @param {string} id - The ID of the checkbox.
 * @param {string} label - The label for the checkbox.
 * @param {boolean} checked - The initial checked state of the checkbox.
 * @param {Function} onChange - The function to be called when the checkbox state changes.
 * @returns {JSX.Element} A JSX element representing the Checkbox component.
 */
const Checkbox = ({ id, label, checked, onChange }) => {

    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <input
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        checked={checked}
                        onChange={(evt) => { onChange && onChange(evt.currentTarget.checked) }}
                    />
                </div>
            </div>
        </div>

    )
}



/**
 * Text input component for capturing user input.
 * NOTE: This is copied from javascript/dashboard/video/edit.tsx
 * @param {string} id - The ID of the text input.
 * @param {string} label - The label for the text input.
 * @param {string} defaultValue - The default value for the text input.
 * @param {Function} onChange - The function to be called when the text input value changes.
 * @returns {JSX.Element} A JSX element representing the TextInput component.
 */
const TextInput = ({ id, label, defaultValue, onChange }) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <input id={id} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={defaultValue} onChange={(evt) => { onChange && onChange(evt.currentTarget.value) }} />
                </div>
            </div>
        </div>
    )
}

/**
 * Select input component for choosing options from a dropdown list.
 * NOTE: This is copied from javascript/dashboard/video/edit.tsx
 * @param {string} id - The ID of the dropdown list.
 * @param {string} label - The label for the dropdown list.
 * @param {string} defaultValue - The default value for the dropdown list.
 * @param {Array} options - An array of objects representing the selectable options.
 * @param {boolean} allowEmpty - A boolean indicating whether an empty option is allowed.
 * @param {Function} onChange - The function to be called when the dropdown list value changes.
 * @param {string} emptyOption - The text for the empty option (default: '[none]').
 * @returns {JSX.Element} A JSX element representing the SelectInput component.
 */
const SelectInput = ({ id, label, defaultValue, options, allowEmpty, onChange, emptyOption = '[none]' }) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <select id={id} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={defaultValue} onChange={(evt) => { onChange && onChange(evt.currentTarget.value); }}>
                        {allowEmpty ? <option value={undefined} key="empty">{emptyOption}</option> : <></>}
                        {options.map((item) => {
                            return (
                                <option value={item.id} key={item.id}>{item.title}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}


export default Edit
