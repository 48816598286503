import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'
import client from '../../FlivaApolloClient'
import { Mixpanel } from '../../Common/Mixpanel'
import {AccountConnections, Account} from '../../types'
import Pagination from '../../Pagination'
import Modal from '../Modal'
import { useNavigate } from "react-router-dom";


interface AccountListProps {
}

interface AccountListResult {
  accounts: AccountConnections;
}

export const ACCOUNTLIST_QUERY = gql`
query AccountList($limit:Int, $page: Int, $search: String) {
  accounts(limit:$limit, page:$page, search:$search) {
    currentPage
    totalPages
    previousPage
    nextPage
    items {
      id
      title
      createdAt
      subscription {
        settings
        tier {
          id
          settings
          title
        }
      }
    }
  }
}
`

export const CREATE_ACCOUNT_MUTATION = gql`
mutation CreateAccount($title: String!, $tier: AccountTierValue)  {
  createAccount(title: $title, tier: $tier) {
    id
    title
    createdAt
    subscription {
      settings
      tier {
        id
        settings
        title
      }
    }

  }
}
`

export const UPDATE_ACCOUNT_MUTATION = gql`
mutation UpdateAccount($id: String!, $tier: AccountTierValue!)  {
  setAccountTier(id: $id, tier: $tier) {
    id
    title
    createdAt
    subscription {
      settings
      tier {
        id
        settings
        title
      }
    }
  }
}
`

const impersonate = (id) => {
    if ( localStorage ) {
      Mixpanel.track("Impersonating account", {id})
      localStorage.setItem('impersonation', id)
      client.resetStore()
      window.location.replace('/dashboard')
    }
}

export interface ListAccountsProps extends AccountListProps{
    accounts: AccountConnections;
    setLimit(limit: number): void;
    limit: number;
    setPage(page: number): void;
    search: string;
    setSearch(search: string): void;
}

type Tier = 'Silver' | 'Gold' | 'Platinum'

type EditAccount = Account & {
  tier: Tier
}

function AccountToEdit(account: Account) : EditAccount {
  return {
    ...account,
    tier: account.subscription ? account.subscription.tier.title as Tier : 'Silver'
  }
}

interface EditAccountProps {
  title: string;
  account?: Account;
  saveAccount: any;
  cancelAccount: any;
}

const EditAccount : React.FC<EditAccountProps> = ({title, account, saveAccount, cancelAccount}) => {
  const [state, setState] = useState(AccountToEdit(account))

  const setTitle = (title) => {
    setState({...state, title})
  }

  const setTier = (tier) => {
    setState({...state, tier})
  }

  return (
    <Modal title={title} save={() => { saveAccount(state)}} cancel={cancelAccount} containerClassNames="relative w-1/2 my-6 mx-auto max-w-full">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8"  >
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
            Name
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
              <input id="title" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.title} onChange={(evt) => setTitle(evt.currentTarget.value) }/>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="tier" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
            Tier
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
              <select id="tier" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => setTier(evt.target.value)} defaultValue={state.tier}>
                <option value="Silver">Silver</option>
                <option value="Gold">Gold</option>
                <option value="Platinum">Platinum</option>
             </select>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}


const ListAccounts : React.FC<ListAccountsProps> = ({accounts, limit, setLimit, setPage, search, setSearch}) => {
  const [createAccount, setCreateAccount] = useState<boolean>(false)
  const [editingAccount, setEditingAccount] = useState<Account | undefined>(undefined)
  const [createMutation, createData] = useMutation(CREATE_ACCOUNT_MUTATION);
  const [updateMutation, updateData] = useMutation(UPDATE_ACCOUNT_MUTATION);
  const updateSearchValue = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if(evt.key == "Enter") {
      setSearch(evt.currentTarget.value)
      setPage(1)
    }
  }

  const navigate = useNavigate();

  const saveAccount = (data) => {
    const mutation = createAccount ? createMutation : updateMutation


    const result = mutation({ variables: data })
    result.then((done) => {
      navigate(`${done.data.createAccount.id}/edit`);
    }).catch(v => console.error(v))

    result.then(data)
    setCreateAccount(false)
    setEditingAccount(undefined)
  }
  const cancelAccount = () => {
    setCreateAccount(false)
    setEditingAccount(undefined)
  }

  const editAccount = (account) => {
    setEditingAccount(account)
  }

  const deleteAccount = (something) => {
  }


  return (
    <div className="flex flex-wrap ">
      <div className="w-full  lg:mb-0 px-4 flex flex-col">
        <div className="flex justify-between px-6 -mb-px">
          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto justify-end">
              <a href="#" className="block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase text-black hover:text-brand-50block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase text-black hover:text-brand-500" onClick={ (evt) => setCreateAccount(true) }>New</a>
    <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
    </div>
          </div>

        </div>
    {createAccount ? <EditAccount title="Create Account" account={{title: ''} as Account} saveAccount={saveAccount} cancelAccount={cancelAccount} /> : <span />}
        <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
          {accounts.items.map((account) => {
            return (
              <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`account-${account.id}`}>
                <div className="w-1/5 px-4 flex items-center">
                  <span className="text-lg">
                    <Link to={`${account.id}`} >
                      {account.title}
                    </Link>
                  </span>
                </div>
                <div className="w-1/5 px-4">
                  <div className="text-right text-grey">
                    {timeDistanceInWords(account.createdAt)}
                  </div>
                </div>
                <div className="w-1/5 px-4">
                  <div className="text-right text-grey">
                    {account?.subscription?.tier?.title}
                  </div>
                </div>
                <div className="w-1/5 px-4">
                  <div className="text-right text-grey">
                    <button onClick={() => impersonate(account.id)}>Impersonate</button>
                  </div>
                </div>
              <div className="w-1/5 px-4">
              <div className="text-right text-grey">
              <span>
                <Link to={`${account.id}/edit`} className="ml-4">
                  <span>
                  <button className="cursor-pointer" onClick={() => editAccount(account)}><FontAwesomeIcon icon={faEdit} className="mr-2" /></button>
                </span>
              </Link>
              </span>
                <span>
             
                    <button className="cursor-pointer" onClick={() => deleteAccount(account)}><FontAwesomeIcon icon={faTrash} className="mr-2" /></button>
           
                </span>
              </div>
              </div>
              </div>
            )
          })}
        </div>
    <div>
    </div>
    <Pagination paginated={accounts} setPage={setPage} />
      </div>
    </div>
  )

}

const List : React.FC<AccountListProps> = (props) => {
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")

  const queryParams = {
    setLimit,
    limit,
    setPage,
    search,
    setSearch
  }

  const { loading, data, error } = useQuery<AccountListResult>(ACCOUNTLIST_QUERY, {variables: {limit, page, search}});

  if (loading) return <Loading />
    if (error) {
      console.log(error)
      return <div>Error</div>
    }
  return (
    <ListAccounts accounts={data.accounts} {...props} {...queryParams} />
  )
}

export default List
