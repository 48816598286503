import React, { useRef, useEffect } from 'react'
import { Query, useMutation} from 'react-apollo'
import gql from 'graphql-tag'
import { useNavigate, useParams} from 'react-router-dom'
import Modal from '../../../../Modal'
import { CREATE_SUPPORT_PAGE_QUESTION_MUTATION } from '../Query'
import { Mixpanel } from '../../../../../Common/Mixpanel'



const New = () => {
    const { supportPageId } = useParams();

    let navigate = useNavigate()
    const question = useRef<HTMLInputElement>();
    const [createQuestion, data] = useMutation(CREATE_SUPPORT_PAGE_QUESTION_MUTATION);

    const save = () => {
        const result = createQuestion({ variables: {id: supportPageId , question: question.current.value}})
        result.then((done) => {
            Mixpanel.track("Created Question", done.data.createSupportPageQuestion) 

        }).catch(v => console.error(v))
    }
    const cancel = () => {
        navigate(-1)
    }

    if(data && data.called && !data.loading && data.data) {
        navigate(`/admin/question/${data.data.createSupportPageQuestion.id}`)
    }

    return (
        <Modal title="New Question" save={save} cancel={cancel}>
                  <div className="my-4 text-gray-600 text-lg leading-relaxed">
                      <input aria-label="Email address" name="email" type="email" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Channel Title" ref={question}/>
                  </div>
        </Modal>
    )
}

export default New

