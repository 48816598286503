import { VideoUpload } from './Upload'
import React, {useState} from 'react'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash} from '@fortawesome/free-solid-svg-icons'
import { Mixpanel } from '../../Common/Mixpanel'
import { useNavigate } from 'react-router-dom'

export const VIDEOCREATE_MUTATION = gql`
mutation CreateVideo($title:String, $channelId:String, $playerId:String, $tags:[String!], $metadata:JSON, $uploadedVideo: UploadInput) {
  createVideo(options: {title:$title, channelId:$channelId, playerId:$playerId, tags:$tags, metadata:$metadata, uploadedVideo: $uploadedVideo}) {
    id
  }
}
`


export const VARIABLES_QUERY = gql`
query VideoEdit {
    channels(limit:20) {
        items {
            id
            title
        }
    }
    players(limit:20){
        items {
            id
            title
        }
    }
}
`
const TextInput = ({id, label, defaultValue, onChange}) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <input id={id} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={defaultValue} onChange={(evt) => { onChange && onChange(evt.currentTarget.value)}} />
                </div>
            </div>
        </div>
    )
}
const TagEntry = ({onChange, deletePrevious}) => {
    const [value, setValue] = useState("")

    const change = (evt) => {
        setValue(evt.currentTarget.value)
    }

    const keyDown = (evt ) => {
        if(evt.key == "Enter") {
            onChange(value)
            setValue("")
        }

        if(evt.key == "Backspace") {
            if(value=="") deletePrevious();
        }

        if(evt.key == "Escape") {
            setValue("")
        }
    }

    return (
        <input onChange={change} onKeyDown={keyDown} value={value} />
    )
}

const TagsInput = ({id, label, tags, onChange}) => {
    const addTag = (name) => {
        if(tags.includes(name)) return;
        onChange([...tags, {name}])
    }

    const removeTag = (index) => {
        const newList = [...tags]
        newList.splice(index, 1)
        onChange(newList)
    }

    const deletePrevious = () => {
        if(tags.length > 0) removeTag(tags.length - 1)
    }

    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <div className="flex">
                        {tags.map((tag, i) => {
                        return (
                            <div className="px-3 py-2 mx-1 relative bg-brand-500 hover:bg-brand-300 text-white rounded-full" key={tag.name}>
                                <span className="pr-4">{tag.name}</span>
                                <FontAwesomeIcon icon={faTrash} className="w-2 h-2 absolute top-0 right-0 mr-2 mt-1 hover:text-danger" onClick={() => removeTag(i)} />
                            </div>
                        )
                    })}
                    <TagEntry onChange={(tag) => addTag(tag)} deletePrevious={deletePrevious} />
                </div>
                </div>
            </div>
        </div>
    )
}
const SelectInput = ({id, label, defaultValue, options, allowEmpty, onChange, emptyOption = '[none]'}) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <select id={id} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"  defaultValue={defaultValue} onChange={(evt) => { onChange && onChange(evt.currentTarget.value); } }>
                        { allowEmpty ? <option value={undefined} key="empty">{emptyOption}</option> : <></> }
                        {options.map((item) => {
                            return (
                                <option value={item.id} key={item.id}>{item.title}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}

const New = () => {
    let navigate = useNavigate()
    const [state, setState] = useState({tags: []})
    const [uploadVars, setUploadVars] = useState(undefined)
    const onUpload = (id, filename) => {
        setUploadVars({
            id,
            filename
        })
        if(!state.title || state.title == "") {
            const withoutExtension = filename.split('.').slice(0, -1).join('.')
            const filenameSplit = withoutExtension.replaceAll(/[\-_]/g, ' ')
            var words = filenameSplit.toLowerCase().split(/ +/);
            for(var i = 0; i< words.length; i++){
                words[i] = words[i][0].toUpperCase() + words[i].slice(1);
            }
            setField('title', words.join(' '))
        }
    }

    const setField = (fieldName, value) => {
        if (value === undefined) {
            return function(value) {
                setState(
                    {
                        ...state,
                        [fieldName]: value
                    }
                )
            }
        } else {
            setState(
                {
                    ...state,
                    [fieldName]: value
                }
            )
        }
    }

    const [saveVideo, saveResult] = useMutation(VIDEOCREATE_MUTATION);

    const fetchMutationVars = (source, uploadVars) => {
        const out = {
            ...source
        }
        if(out['tags']) {
            out['tags'] = out['tags'].map((tag) => tag.name)
        }
        if(uploadVars) {
            out['uploadedVideo'] = uploadVars
        }
        return out
    }



    const save = () => {
        const vars = fetchMutationVars(state, uploadVars)
        const result = saveVideo({ variables: vars })
        result.then((done) => {
            Mixpanel.track("Saved video", vars) 
            console.log(done)
        }).catch(v => console.error(v))
    }


    const { loading, data } = useQuery(VARIABLES_QUERY);
    if(loading) return <span />

    if(saveResult && saveResult.data && saveResult.data.createVideo) {
        return navigate(`/dashboard/videos/${saveResult.data.createVideo.id}`)
    }
return (
                    <div className="w-full">
                        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                            <TextInput id="title" label="Title" defaultValue={state.title} onChange={setField('title')} />
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                    Upload video
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                        {uploadVars ? <span>{uploadVars.filename}</span> :

                                        <VideoUpload onUpload={onUpload} />
                                    }
                                    </div>
                                </div>
                            </div>
                            <SelectInput id="channel" label="Channel"  options={data.channels.items} allowEmpty={true} onChange={setField('channelId')} />
                            <SelectInput id="player" label="Player"  options={data.players.items} allowEmpty={true} onChange={setField('playerId')} />
                            <TagsInput id="tags" label="Tags" tags={state.tags}  onChange={setField('tags')} />
                          <button onClick={save} className="mt-8 bg-brand-500 hover:bg-brand-300 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline">{saveResult && saveResult.loading ? "Saving...." : "Save"}</button>
                        </div>
                    </div>

        )

}

export default New
