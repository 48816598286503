import gql from 'graphql-tag'
import {FacebookPageConnections, AccountConnections, Account, Team, User, FacebookPage} from '../../../../types'

export interface FacebookPagesResult {
  facebookPages: FacebookPageConnections;
}

export interface FacebookPageResult {
  accounts: AccountConnections,
  facebookPage: FacebookPage;
}

export interface FacebookPageUpdateResult {
  facebookPage: FacebookPage;
}

export interface LookupUsersResult {
  account: Account;
}

export interface LookupTeamsResult {
  account: Account;
}

export const LOOKUP_USERS = gql`
query Teams($accountId:ID, $search: String) {
  account(id:$accountId) {
    users(search: $search) {
      items {
        id
        name
        email
      }
    }
  }
}
`

export const LOOKUP_TEAMS = gql`
query Teams($accountId:ID, $search: String) {
  account(id:$accountId) {
    teams(search: $search) {
      id
      title
    }
  }
}
`


export const FACEBOOK_PAGE_UPDATE = gql`
mutation EditVideo($id:ID!, $accountId:String, $teamId:String, $userId:String) {
  updateFacebookPage(id: $id, accountId:$accountId, teamId:$teamId, userId:$userId) {
    id
    title
    category
    createdAt
    updatedAt
    account {
      id
      title
    }
    team {
      id
      title
    }
    user {
      id
      email
      name
    }
  }
}
`

export const FACEBOOK_PAGE_SHOW = gql`
query Page($id:ID!) {
  accounts(limit: 100) {
    items {
      id
      title
    }
  }
  facebookPage(id:$id) {
    id
    title
    category
    createdAt
    updatedAt
    account {
      id
      title
    }
    team {
      id
      title
    }
    user {
      id
      email
      name
    }
  }
}
`

export const FACEBOOK_PAGE_LIST = gql`
query Page($page:Int,$limit:Int,$sortBy:FacebookPageSortingField,$search:String,$unlinkedOnly:Boolean ) {
  facebookPages(page:$page, limit:$limit, sortBy: $sortBy, search: $search, unlinkedOnly: $unlinkedOnly) {
    currentPage
    totalPages
    previousPage
    nextPage
    items {
      id
      title
      category
      createdAt
      updatedAt
      account {
        id
        title
      }
      team {
        id
        title
      }
      user {
        id
        email
        name
      }
    }
  }
}
`

