import React, { useRef, useState} from 'react'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import Loading from '../loading'
import {Composition} from '../../types'
import { Link, useParams } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import { Mixpanel } from '../../Common/Mixpanel'
import { CompUpload } from '../Video/Upload'


export const COMPOSITION_QUERY = gql`
query Composition($id:Int!) {
  composition(id:$id) {
    id
    name
    slug
    url
    compName
    createdAt

  }
}
`

export const COMPOSITION_MUTATION = gql`
mutation UpdateComposition($id:Int!, $name:String, $slug:String, $compName:String, $url:String) {
  updateComposition(id:$id, name:$name, slug:$slug, compName:$compName, url:$url) {
    id
    name
    slug
    url
    compName
    createdAt

  }
}
`

type UploadVars = {
    url: string;
}

interface CompositionResult {
  composition: Composition;
}

const TextInput = ({id, label, defaultValue, onChange}) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <input id={id} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={defaultValue} onChange={(evt) => { onChange && onChange(evt.currentTarget.value)}} />
                </div>
            </div>
        </div>
    )
}

const Show = () => {
    console.log("Show")
    const params = useParams();
    const [uploadVars, setUploadVars] = useState(undefined as UploadVars)
    const onUpload = (url: string) => {
        console.log(url)
        setUploadVars({
            url
        })
    }

    let localData = useRef({});
    const vars = {id: parseInt(params.compositionId, 10)}
    const [saveComp, saveResult ] = useMutation(COMPOSITION_MUTATION);
    const {loading, error, data } = useQuery<CompositionResult>(COMPOSITION_QUERY, {variables: vars})
    if (loading) return <Loading />
    if (error) {
        console.log(error)
        return <div>Error</div>
    }

    const updateData = (field, value) => {
        localData.current[field] = value
        console.log(localData)
    }
    const updateCompName = (value) => {
        updateData('compName', value)
    }

    const updateName = (value) => {
        console.log("Updating name")
        updateData('name', value)
    }

    const updateSlug = (value) => {
        updateData('slug', value)
    }

    const updateUrl = (value) => {
        updateData('url', value)
    }


    const save = () => {
        console.log(localData.current)
        const vars = { 
            id: parseInt(params.compositionId, 10),
            compName: localData.current['compName'],
            name: localData.current['name'],
            slug: localData.current['slug'],
            url: uploadVars.url,

        }

        console.log(vars)

        Mixpanel.track("Saving comp", {id: params.compositionId, title: vars.name}) 
        const result = saveComp({ variables: vars })
        result.then((done) => {
            console.log(done)
            Mixpanel.track("Saved comp", {id: params.compositionId, title: vars.name}) 
        })
    }


    return (
        <div className="px-6 py-6 text-grey-700" key={`composition-${data.composition.id}`}>
            <TextInput id="name" label="Name" defaultValue={data.composition.name} onChange={(value) =>  updateName(value) } />
            <TextInput id="slug" label="Slug" defaultValue={data.composition.slug} onChange={(value) => updateSlug(value)} />
            <TextInput id="compName" label="Comp Name" defaultValue={data.composition.compName} onChange={(value) => updateCompName(value)} />
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                    Replace composition zip file
                                </label>
                                        {uploadVars ? <span>{uploadVars.url}</span> :

                                        <CompUpload onUpload={onUpload} id={params.compositionId} />
                                    }
                                </div>
                          <button onClick={save} className="mt-8 bg-brand-500 hover:bg-brand-300 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline">{saveResult && saveResult.loading ? "Saving...." : "Save"}</button>
        </div>
    )

}

export default Show
