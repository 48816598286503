import React, { useRef } from 'react'
import { Query, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useNavigate} from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Modal from '../Modal'
import { Mixpanel } from '../../Common/Mixpanel'

export const CREATE_PLAYER_MUTATION = gql`
mutation update($title:String!) {
  createPlayer(title:$title) {
    id
    title
  }
}
`


const New = () => {
    let navigate = useNavigate()
    const playerName = useRef();
    const [createPlayer, data] = useMutation(CREATE_PLAYER_MUTATION);
    const save = () => {
        console.log(playerName.current.value)

        const result = createPlayer({ variables: {title: playerName.current.value }})
        result.then((done) => {
            Mixpanel.track("Created player", done.data.createPlayer) 

        }).catch(v => console.error(v))
    }
    const cancel = () => {
        navigate(-1)
    }
    if(data && data.called && !data.loading && data.data) {
        return navigate(`/dashboard/players/${data.data.createPlayer.id}`)
    }
    return (
        <Modal title="New Player" save={save} cancel={cancel}>
                  <div className="my-4 text-gray-600 text-lg leading-relaxed">
                      <input aria-label="Email address" name="email" type="email" required="" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Player Title" ref={playerName}/>
                  </div>
        </Modal>
    )
}

export default New

