import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Routes, Route, Link, Outlet } from 'react-router-dom'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import List from './Workflow/List'
import Show from './Workflow/Show'
import Edit from './Workflow/Edit'
import Instance from './Workflow/Instance'
import { Mixpanel } from '../Common/Mixpanel'


const WorkflowDashboard = () => {
    return (
                <div>
                    <h1 className="text-3xl text-black pb-4">Workflows</h1>
                    <div className="flex flex-wrap">
                        <div className="flex-grow w-full mx-auto">
                            <Routes>
                            <Route path={`:workflowId/instance/:instanceId`} element={<Instance  />} />
                            <Route path={`:workflowId/edit`} element={<Edit  />} />
                            <Route path={`:workflowId`} exact element={<Show  />} />
                            <Route index exact element={<List  />} />
                            </Routes>
                        </div>
                    </div>
                </div>
    )
}

export default WorkflowDashboard
