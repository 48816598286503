import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import {ChannelConnections, Channel} from '../../types'
import Pagination from '../../Pagination'

export const CHANNELLIST_QUERY = gql`
query ChannelList($limit:Int, $page: Int, $search: String) {
    channels(limit:$limit, onlyPublic:false, page: $page, search: $search) {
        currentPage
        totalPages
        previousPage
        nextPage
        items {
            id
            title
            createdAt
        }
    }
}
`

interface ChannelRouterProps {
}



interface ChannelList {
    channels: ChannelConnections;
}

interface ChannelEntryProps {
    channel: Channel;
}

const ChannelEntry : React.FC<ChannelEntryProps> = ({channel}) => {
    return (
        <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`channel-${channel.id}`}>
          <div className="w-4/5 px-4 flex items-center">
            <span className="text-lg">
              <Link to={`${channel.id}`}>
                {channel.title}
              </Link>
            </span>
          </div>
          <div className="w-1/5 px-4">
            <div className="text-right text-grey">
                {timeDistanceInWords(channel.createdAt) }
                <Link to={`edit/${channel.id}`} className="ml-4"> 
                     Edit 
                </Link>
            
            </div>
          </div>
        </div>
    )
}

const List = () => {
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")

    const updateSearchValue = (evt : React.KeyboardEvent<HTMLInputElement>) => {
        if(evt.key == "Enter") {
            setSearch(evt.currentTarget.value)
            setPage(1)
        }
    }

    const { loading, data, error } = useQuery<ChannelList>(CHANNELLIST_QUERY, {variables: {limit, page, search}});

    if (loading) return <Loading />
        if (error) {
            console.log(error)
            return <div>Error</div>
        }
    return (
        <div className="flex flex-wrap">
            <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
                <div className="flex justify-between px-6 -mb-px">
                    <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                  <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto justify-end">
                            <Link to={`new/channel`} className="block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase text-black hover:text-brand-500">
                                New
                            </Link>
                      <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
                      </div>
                    </div>

                </div>
                <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    {data && data.channels.items.map((channel, i) => {
                        return <ChannelEntry channel={channel} key={i}/>

                    })}
                </div>
        <Pagination paginated={data.channels} setPage={setPage} />
            </div>
        </div>
    )

}
export default List
