import React, {useContext} from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import {  Routes, Route, Link, Outlet} from 'react-router-dom'
import Loading from '../loading'
import DesignWorkflow from './design/workflow'

export const DESIGN_QUERY = gql`
query Design {
    templates(limit:20) {
        items {
            id
            name
            createdAt
        }
    }
    players(limit:20) {
        items {
            id
            title
            createdAt
        }
    }
    workflows(limit:20) {
        items {
            id
            title
            createdAt
            instances {
                totalItems
            }
        }
    }
}
`


const WorkflowList = ({workflows}) => {
    return (
        <>
            <h2 className="text-xl text-black pb-2 mt-4">Workflows</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul>
                    {workflows.items.map((workflow, i) => {
                        const liClass = i > 0 ? 'border-t border-gray-200' : ''
                        return (
                            <li className={liClass} key={i}>
                                <Link to={`/design/workflows/${workflow.id}`} className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="min-w-0 flex-1 flex items-center">
                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <div className="text-sm leading-5 font-medium text-indigo-600 truncate">{workflow.title}</div>
                                                    <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                                        <span className="truncate">{workflow.instances.totalItems} videos</span>
                                                    </div>
                                                </div>
                                                <div className="hidden md:block">
                                                    <div>
                                                        <div className="text-sm leading-5 text-gray-900">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

const TemplateList = ({templates}) => {
    return (
        <>
            <h2 className="text-xl text-black pb-2 mt-4">Templates</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul>
                    {templates.items.map((template, i) => {
                        const liClass = i > 0 ? 'border-t border-gray-200' : ''
                        return (
                            <li className={liClass} key={i}>
                                <Link to={`/dashboard/templates/${template.id}`} className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="min-w-0 flex-1 flex items-center">
                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <div className="text-sm leading-5 font-medium text-indigo-600 truncate">{template.name}</div>
                                                    <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                                        <span className="truncate"></span>
                                                    </div>
                                                </div>
                                                <div className="hidden md:block">
                                                    <div>
                                                        <div className="text-sm leading-5 text-gray-900">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

const PlayerList = ({players}) => {
    return (
        <>
            <h2 className="text-xl text-black pb-2 mt-4">Players</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul>
                    {players.items.map((player, i) => {
                        const liClass = i > 0 ? 'border-t border-gray-200' : ''
                        return (
                            <li className={liClass} key={i}>
                                <Link to={`/dashboard/players/${player.id}`} className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="min-w-0 flex-1 flex items-center">
                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <div className="text-sm leading-5 font-medium text-indigo-600 truncate">{player.title}</div>
                                                    <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                                        <span className="truncate"></span>
                                                    </div>
                                                </div>
                                                <div className="hidden md:block">
                                                    <div>
                                                        <div className="text-sm leading-5 text-gray-900">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

const Index = () => {

    return (
            <Query query={DESIGN_QUERY}>
                {({ loading, error, data, subscribeToMore }) => {
                    if (loading) return <Loading />
                        if (error) {
                            console.log(error)
                            return <div>Error</div>
                        }
                    return (
                        <>
                            <h1 className="text-3xl text-black pb-4">Design</h1>
                            <WorkflowList workflows={data.workflows} />
                            <TemplateList templates={data.templates} />
                            <PlayerList players={data.players} />
                        </>

                    )
                }}
            </Query>
    )
}

const Dashboard = () => {
    console.log("Design")
    return (
        <>
            <Routes>
                <Route path="workflows/:workflowId" element={ <DesignWorkflow /> } />
                <Route index element={ <Index /> } />
            </Routes>
            <Outlet />
        </>
    )
}

export default Dashboard
