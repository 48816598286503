import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import List from './Channel/List'
import Show from './Channel/Show'
import Edit from './Channel/Edit'
import New from './Channel/New'
import { Mixpanel } from '../Common/Mixpanel'

interface ChannelDashboardRouterProps{
}


const ChannelDashboard = () => {
    return (
        <div>
            <h1 className="text-3xl text-black pb-4">Channels</h1>
            <div className="flex flex-wrap">
                <div className="flex-grow w-full mx-auto">
                    <Routes>
                    <Route path={`edit/:channelId`} element={<Edit/>} />
                    <Route path={`new/channel`} element={<New  />} />
                    <Route path={`:channelId`}  element={<Show  />} />
                    <Route index element={<List  />} />
                </Routes>
                <Outlet />
                </div>
            </div>
        </div>
    )
}

export default ChannelDashboard
