import React, { useState } from "react";
import { Routes, Route, Outlet, NavLink } from "react-router-dom";

const PostToolDashboard = () => {
  return (
    <div className="flex flex-wrap">
      <h1>Post Tool Dashboard</h1>
      <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
        <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"></div>
      </div>
    </div>
  );
};

export default PostToolDashboard;
