import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { Routes, Route, Outlet, NavLink, Link } from 'react-router-dom'
import Loading from '../../../loading'
import Pagination from '../../../../Pagination'
import { timeDistanceInWords } from '../../../../helpers/TimeInWords'

import { SUPPORT_PAGE_QUESTIONS_QUERY, SUPPORT_PAGE_SECTIONS_QUERY } from './Query'


const Support = () => {
  const [limit, setLimit] = useState(50)
  const [supportType, setSupportType] = useState("questions")
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [searchType, setSearchType] = useState(undefined)
  const [sortBy, setSortBy] = useState("Sorting" as string)
  const [sortDirection, setSortDirection] = useState(false)

  const [account, setAccount] = useState(undefined as string)
  const [supportPage, setSupportPage] = useState(undefined as string)
  
  const updateSort = (newSortBy) => {
    newSortBy == sortBy ? setSortDirection(!sortDirection) : setSortBy(newSortBy)
  }

  const setAccountAndReset = (account) => {
    setAccount(account)
    setSupportPage(undefined as string)
  }

  const updateSearchValue = (evt) => {
    if (evt.key == "Enter") {
      setSearch(evt.currentTarget.value)
      setPage(1)
    }
  }

  const vars =
  {
    limit
    , accountId: account && account !== "none" ? account : undefined
    , supportPageid: supportPage && supportPage !== "none" ? supportPage : undefined
    , search: search
    , searchType: searchType && searchType != 'all' ? searchType : undefined
    , sortBy: sortBy ? sortBy : undefined
    , sortDirection: sortDirection ? "DESC" : "ASC"
    , page: page ? page : 1
  }

  const { loading, error, data } = useQuery(supportType === "questions" ? SUPPORT_PAGE_QUESTIONS_QUERY : SUPPORT_PAGE_SECTIONS_QUERY, { variables: vars, pollInterval: 5000, fetchPolicy: 'no-cache' })

  if (loading) return <Loading />
  if (error) {
    console.log(error)
    return <div>Error</div>
  }
  return (
    <div>
      <Header data={data} type={supportType} setType={setSupportType} limit={limit} setLimit={setLimit} account={account} setAccount={setAccountAndReset} supportPage={supportPage} setSupportPage={setSupportPage} search={search} updateSearchValue={updateSearchValue} searchType={searchType} setSearchType={setSearchType} />
      <Labels updateSort={updateSort} supportType={supportType}></Labels>
      <SupportPageEntries supportType={supportType} data={data}></SupportPageEntries>
      {supportPage ? <Pagination paginated={data?.supportPage?.questions ? data?.supportPage?.questions : data?.supportPage?.sections} setPage={setPage} /> : <span></span>}
    </div>
  )
}

const Header = ({ data, type, setType, limit, setLimit, account, setAccount, supportPage, setSupportPage, search, updateSearchValue, searchType, setSearchType }) => {

  return (
    <div className="flex flex-row">
      <fieldset className="w-2 m-4">
        <legend className="text-[#999999] text-sm">
          Limit
        </legend>
        <input className="border border-solid border-gray-300 bg-white" defaultValue={limit} onChange={e => setLimit(parseInt(e.currentTarget.value, 10))} type="integer" />
      </fieldset>

      {data?.accounts ?
        <fieldset className="w-2 m-4">
          <legend className="text-[#999999] text-sm">
            Account
          </legend>
          <select className="form-select appearance-none border border-solid border-gray-300 bg-white" defaultValue={account} onChange={(e) => { setAccount(e.target.value) }}>
            {account !== undefined ? null : <option value="none">none</option>}
            {data.accounts.items.map(account => {
              return (
                <option value={account.id} key={account.id}>{account.title}</option>
              )
            })}
          </select>
        </fieldset>
        : <span />}

      {account ?
        <fieldset className="w-2 m-4">
          <legend className="text-[#999999] text-sm">
            Support page
          </legend>
          <select className="form-select appearance-none border border-solid border-gray-300 bg-white"  style={{ minWidth: "100px" }} defaultValue={supportPage} onChange={(e) => { setSupportPage(e.target.value) }}>
            {supportPage !== undefined ? null : <option value="none">none</option>}
            {data.accounts.items
              .find(ac => ac.id === account)
              .supportPages?.map(supportPage => (
                <option value={supportPage.id} key={supportPage.id}>{supportPage.title}</option>
              ))}
          </select>
        </fieldset>
        : <span />}

      {supportPage ?
        <fieldset className="w-2 m-4">
          <legend className=" text-[#999999] text-sm">
            Type
          </legend>
          <select className="form-select appearance-none border border-solid border-gray-300 bg-white" defaultValue={type} onChange={(e) => { setType(e.target.value) }}>
            <option value="questions">questions</option>
            <option value="sections">sections</option>
          </select>
        </fieldset>
        : <span />}

      {supportPage ?
        <div className="flex flex-row justify-end">
          <fieldset className="w-2 m-4">
            <legend className=" text-[#999999] text-sm">
              Search in:
            </legend>
            {type === "questions" ?
              <select className="form-select appearance-none border border-solid border-gray-300 bg-white" defaultValue={searchType} onChange={(e) => { setSearchType(e.target.value) }}>
                <option value="all">everything</option>
                <option value="question">questions</option>
                <option value="topic">topics</option>
                <option value="answer">answers</option>

              </select> : <select className="form-select appearance-none border border-solid border-gray-300 bg-white" defaultValue={searchType} onChange={(e) => { setSearchType(e.target.value) }}>
                <option value="all">everything</option>
                <option value="content">Content</option>
                <option value="section_type">section type</option>
              </select>}
          </fieldset>

          <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto">
           
            <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
            <Link to={type === "questions" ? `/admin/new/question/${supportPage}` : `/admin/new/section/${supportPage}`} className="block mt-4 ml-5 lg:inline-block lg:mt-0 mr-4 justify-center uppercase text-center text-black hover:text-brand-500">
              New
            </Link>
          </div>
        </div> : 
        <span></span>}
    </div>

  )
}

const Labels = ({updateSort, supportType}) => {
  return supportType === "questions" ? <QuestionLabels updateSort={updateSort}></QuestionLabels> : <SectionLabels updateSort={updateSort}></SectionLabels>
}

const QuestionLabels = ({updateSort}) => {
  return (
    <div className="flex-grow flex px-6 py-1 text-grey-700 items-center border-b -mx-4">
      <div className="w-1/6 px-4 flex items-center">
        <span className="text-lg" onClick={() => updateSort("Question")}>
          Question
        </span>
      </div>
      <div className="w-1/6 px-4">
        <div className="text-right text-grey">
          answer
        </div>
      </div>

      <div className="w-1/6 px-4">
        <div className="text-right text-grey" onClick={() => updateSort("Topic")}>
          topic
        </div>
      </div>
      <div className="w-1/6 px-4">
        <div className="text-right text-grey" onClick={() => updateSort("Sorting")}>
          sorting
        </div>
      </div>
      <div className="w-1/6 px-4">
        <div className="text-right text-grey" onClick={() => updateSort("UpdatedAt")}>
          Updated at
        </div>
      </div>
      <div className="w-1/6 px-4">
        <div className="text-right text-grey" onClick={() => updateSort("CreatedAt")}>
          Created at
        </div>
      </div>
      <div className="w-xs px-4">
        <div className="text-right text-grey">
          Delete
        </div>
      </div>
    </div>
  )
}



const SectionLabels = ({ updateSort }) => {
  return (
    <div className="flex-grow flex px-6 py-1 text-grey-700 items-center border-b -mx-4">
      <div className="w-1/5 px-4 flex items-center">
        <span className="text-lg" >
          Content
        </span>
      </div>
      <div className="w-1/5 px-4">
        <div className="text-right text-grey" onClick={() => updateSort("SectionType")}>
          Section type
        </div>
      </div>
      <div className="w-1/5 px-4">
        <div className="text-right text-grey" onClick={() => updateSort("Sorting")}>
          sorting
        </div>
      </div>
      <div className="w-1/5 px-4">
        <div className="text-right text-grey" onClick={() => updateSort("UpdatedAt")}>
          Updated at
        </div>
      </div>
      <div className="w-1/5 px-4">
        <div className="text-right text-grey" onClick={() => updateSort("CreatedAt")}>
          Created at
        </div>
      </div>
      <div className="w-xs px-4">
        <div className="text-right text-grey">
          Delete
        </div>
      </div>
    </div>
  )
}



const SupportPageEntries = ({supportType, data}) => {
  return (
    <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
      {supportType === "questions" ?
        data?.supportPage?.questions?.items?.map((question) => {
          return (
            <QuestionEntry question={question} key={question.id} />
          )
        }) :
        data?.supportPage?.sections?.items?.map((question) => {
          return (
            <SectionEntry section={question} key={question.id} />
          )
        })}
    </div>)
}

const QuestionEntry = ({question}) => {
  return (
    <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4">
      <div className="w-1/6 px-4 flex items-center">
        <span className="text-lg overflow-hidden overflow-hidden whitespace-nowrap overflow-ellipsis">
          <Link to={`/admin/question/${question.id}`}>
            {question?.question}
          </Link>
        </span>
      </div>
      <div className="w-1/6 px-4">
        <div className="text-right text-grey overflow-hidden overflow-hidden whitespace-nowrap overflow-ellipsis">
          {question?.answer}
        </div>
      </div>
      <div className="w-1/6 px-4">
        <div className="text-right text-grey">
          {question?.topic}
        </div>
      </div>
      <div className="w-1/6 px-4">
        <div className="text-right text-grey">
          {question?.sorting}
        </div>
      </div>
      <div className="w-1/6 px-4">
        <div className="text-right text-grey">
          {timeDistanceInWords(question?.updatedAt)}
        </div>
      </div>
      <div className="w-1/6 px-4">
        <div className="text-right text-grey">
          {timeDistanceInWords(question?.createdAt)}
        </div>
      </div>

      <div className="w-xs px-4">
        <Link to={`/admin/delete/question/${question.id}`}>
          Delete
        </Link>
      </div>
    </div>
  )
}

const SectionEntry = ({section}) => {
  return (
    <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4">
      <div className="w-1/5 px-4 flex items-center">
        <span className="text-lg overflow-hidden overflow-hidden whitespace-nowrap overflow-ellipsis">
          <Link to={`/admin/section/${section.id}`}>
            {section?.content}
          </Link>
        </span>
      </div>
      <div className="w-1/5 px-4">
        <div className="text-right text-grey overflow-hidden overflow-hidden whitespace-nowrap overflow-ellipsis">
          {section?.sectionType}
          <Link to={`/admin/section/${section.id}`}>
            {section?.sectionType}
          </Link>
        </div>
      </div>
      <div className="w-1/5 px-4">
        <div className="text-right text-grey">
          {section?.sorting}
        </div>
      </div>
      <div className="w-1/5 px-4">
        <div className="text-right text-grey">
          {timeDistanceInWords(section?.updatedAt)}
        </div>
      </div>
      <div className="w-1/5 px-4">
        <div className="text-right text-grey">
          {timeDistanceInWords(section?.createdAt)}
        </div>
      </div>
      <div className="w-xs px-4">
        <Link to={`/admin/delete/section/${section.id}`}>
          Delete
        </Link>
      </div>
    </div>
  )
}
export default Support
