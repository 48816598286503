import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory'

if(document.location.pathname.startsWith('/action')) {
    localStorage ? localStorage.setItem('actionToken', document.location.pathname.replace('/action/', '')) : null
}

const backdoor = document.location.pathname.startsWith('/action') ? document.location.pathname.replace('/action/', '') : localStorage ? localStorage.getItem('actionToken') : undefined
const token = localStorage ? localStorage.getItem('token') : undefined
const savedApiUrl = localStorage ? localStorage.getItem('apiUrl') : undefined
const apiUrl = savedApiUrl ? savedApiUrl : 'https://api.fliva.com/graphql'
const httpLink = createHttpLink({
    uri:  token ? apiUrl : '/graphql'
})




const authLink = setContext((_, { headers }) => {
    const impersonation = localStorage ? localStorage.getItem('impersonation') : ''
    if(!token) {
        if(impersonation) {
            return {
                headers: {
                    ...headers,
                    "X-Fliva-Impersonation-Account": impersonation,
                    "X-Fliva-Backdoor-Of-Doom": backdoor ? backdoor : "",
                }
            }
        } else {
            headers
        }
    }

    if(impersonation) {
        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : "",
                "X-Fliva-Backdoor-Of-Doom": backdoor ? backdoor : "",
                "X-Fliva-Impersonation-Account": impersonation
            }
        }
    } else {
        return {
            headers: {
                ...headers,
                "X-Fliva-Backdoor-Of-Doom": backdoor ? backdoor : "",
                Authorization: token ? `Bearer ${token}` : ""
            }
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
})

export default client;
