import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Routes, Route, Link, Outlet } from 'react-router-dom'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import List from './Player/List'
import Show from './Player/Show'
import Edit from './Player/Edit'
import New from './Player/New'
import { Mixpanel } from '../Common/Mixpanel'

const PlayerDashboard = () => {
    return (
                <div>
                    <h1 className="text-3xl text-black pb-4">Players</h1>
                    <div className="flex flex-wrap">
                        <div className="flex-grow w-full mx-auto">
                            <Routes>
                                <Route path={`:playerId/edit`} element={<Edit  /> }/>
                                <Route path={`new/player`} exact element={<New  />} />
                                <Route path={`:playerId`} exact element={<Show  />} />
                                <Route index element={<List  />} />
                            </Routes>
                            <Outlet />
                        </div>
                    </div>
                </div>
    )
}

export default PlayerDashboard
