import React from 'react'


const SupportDashboard = () => {
    return (
        <div>
            <h1 className="text-3xl text-black pb-4">Support</h1>
            <div className="flex flex-wrap">
                <div className="flex-grow w-full mx-auto">
                    <em>Not implemented yet</em>
                </div>
            </div>
        </div>
    )
}

export default SupportDashboard
