import React, { useState, useEffect } from 'react'

const WorkflowInternal = ({ internal, saveInternal }) => {

    console.log(internal)

    const [state, setState] = useState(internal || false)

    const save = () => {
        saveInternal(state)
    }

    useEffect(() => save())

    const setInternal = (newInternal) => {
        setState(newInternal)
    }

    return (
        <div className="flex flex-row ">
            <div className='w-1/4'>
                <label className='text-sm leading-5 font-medium text-gray-700' >
                    Internal
                </label>
            </div>
            <div className="">
                <input
                    id="isInternal"
                    name="isInternal"
                    type="checkbox"
                    checked={state}
                    onChange={(evt) => setInternal(evt.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
            </div>
        </div>

    )
}

export default WorkflowInternal