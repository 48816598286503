import React from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'

export const DOMAINLIST_QUERY = gql`
query DomainList {
    domains(limit:20) {
    items {
        id
        hostname
        createdAt
        }
    }
}
`


const List = () => {
    const { loading, error, data } = useQuery(DOMAINLIST_QUERY)
    if (loading) return <Loading />
        if (error) {
            console.log(error)
            return <div>Error</div>
        }
  return (
  <div className="flex flex-wrap -mx-4">
      <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
          <div className="flex justify-between px-6 -mb-px">
              <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                  <div className="text-lg">
                      <a href="#" className="block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase text-black hover:text-brand-500">New</a>
                  </div>
              </div>

          </div>
          <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
        {data && data.domains.items.map((domain, i) => {
            return (
              <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`domain-${domain.id}`}>
                  <div className="w-4/5 px-4 flex items-center">
                      <span className="text-lg">
                          <Link to={`${domain.id}`}>
                              {domain.hostname}
                          </Link>
                      </span>
                  </div>
                  <div className="w-1/5 px-4">
                      <div className="text-right text-grey">
                          {timeDistanceInWords(domain.createdAt)}
                      </div>
                  </div>
              </div>
            )
        })}
          </div>
      </div>
  </div>
  )
}


export default List
