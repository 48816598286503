import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useParams } from 'react-router-dom'
import Loading from '../../../../loading'
import { SUPPORT_PAGE_QUESTION_QUERY, UPDATE_SUPPORT_PAGE_QUESTION_MUTATION } from '../Query'
import { AssetUpload } from "../../../../Video/Upload";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import VideoJS from '../SimplePlayer'


const Edit = () => {

    const onUploadAsset = (url, fileType) => {
        //if i use the setField method here, only one property gets added. Probably something to do with the async nature of react. Correct me here Claus if there is a better alternative
        setQuestionParams(Question => ({ ...Question, mediaType: fileType }))
        setQuestionParams(Question => ({ ...Question, mediaUrl: url }))
        setUploadAsset(false);
    };

    //Gets the questionId from the url
    const { questionId } = useParams();

    const [Question, setQuestionParams] = useState(undefined as any)
    const [uploadAsset, setUploadAsset] = useState(false);

    //load data from the database with the gql query
    const { loading, data, error, refetch } = useQuery(SUPPORT_PAGE_QUESTION_QUERY, { variables: { "questionId": questionId }, fetchPolicy: 'no-cache' });

    //mutation for saving the changes
    const [saveChanges, saveResult] = useMutation(UPDATE_SUPPORT_PAGE_QUESTION_MUTATION)
    const activePreview = {
        preview: Question?.mediaUrl || data?.supportPageSection?.mediaUrl || undefined,
        type: Question?.mediaType || data?.supportPageSection?.mediaType || undefined
    }

    /**
     * Method for changing and updating paramteres
     * @param fieldName The field that will be changed
     * @param value 
     * @returns 
     */
    const setField = (fieldName: string, value?: any) => {

        if (value === undefined) {
            return function (value: any) {
                setQuestionParams(
                    {
                        ...Question,
                        [fieldName]: value
                    }
                )
            }
        }
        else {
            setQuestionParams(
                {
                    ...Question,
                    [fieldName]: value
                }
            )
        }
    }

    const save = () => {
        const vars = {};
        vars['id'] = questionId;

        //checks if the properties exist before assigning them
        if (Question?.topic != undefined) {
            vars['topic'] = Question.topic;
        }

        if (Question?.answer != undefined) {
            vars['answer'] = Question.answer;
        }

        if (Question?.question != undefined) {
            vars['question'] = Question.question;
        }

        if (Question?.sorting != undefined) {
            vars['sorting'] = Number(Question.sorting);
        }

        if (Question?.mediaUrl != undefined) {
            vars['mediaUrl'] = Question.mediaUrl;
        }

        if (Question?.mediaType != undefined) {
            vars['mediaType'] = Question.mediaType;
        }

        const result = saveChanges({ variables: vars })

        result.then((done) => {

            console.log(data.supportPageQuestion)

        }).catch(v => console.error(v)).finally(() => {
            //when its done writing to the database, refetch the data
            refetch().then(({ data }) => {
                console.log('Refetch is done!', data);
                setQuestionParams(data.supportPageQuestion);
            });
        });
    }

    //check if the data is loaded
    if (loading && !Question) return <Loading />
    if (error) {
        console.log(error)
        return <div>Error</div>
    }
    return (
        <div>
            <div>
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                    <h1>Edit Question</h1>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Topic
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="rounded-md shadow-sm">
                                <input className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={data?.supportPageQuestion?.topic} onChange={(e) => { setField("topic", e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className=" text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 ">
                            Question
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="rounded-md shadow-sm">
                                <textarea rows={10} className="form-textarea w-full justify-center transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={data?.supportPageQuestion?.question} onChange={(e) => { setField("question", e.target.value) }} ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className=" text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 ">
                            Answer
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="rounded-md shadow-sm">
                                <textarea rows={10} className="form-textarea w-full justify-center transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={data?.supportPageQuestion?.answer} onChange={(e) => { setField("answer", e.target.value) }}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className=" text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 ">
                            Sorting
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="rounded-md shadow-sm">
                                <input type="number" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={data?.supportPageQuestion?.sorting} onChange={(e) => { setField("sorting", e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className=" text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 ">
                            Asset
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="rounded-md shadow-sm">
                            {activePreview.preview || data?.supportPageQuestion?.mediaUrl ? <AssetPreview url={activePreview.preview ? activePreview.preview : data?.supportPageQuestion?.mediaUrl} fileType={activePreview.type ? activePreview.type : data?.supportPageQuestion?.mediaType}></AssetPreview> : <span></span> }
                                {uploadAsset ? (
                                    <>
                                        <AssetUpload onUpload={onUploadAsset} />
                                        <span
                                            onClick={() => setUploadAsset(!uploadAsset)}
                                            className="p-4 m-4 text-sm"
                                        >
                                            cancel
                                        </span>
                                    </>
                                ) : (
                                    <button onClick={() => setUploadAsset(!uploadAsset)}>
                                        <a key="download-original" target="_blank" className="  text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-64 m-2">
                                            <FontAwesomeIcon icon={faUpload} className="mr-2" />Upload new
                                        </a>
                                    </button>
                                )}
                                {activePreview.preview || data?.supportPageQuestion?.mediaUrl ? (
                                    <a key="download-original" target="_blank" href={Question?.mediaUrl ? Question?.mediaUrl : data?.supportPageQuestion?.mediaUrl} className="  text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-64 m-2">
                                        <FontAwesomeIcon icon={faUpload} className="mr-2" />Download {Question?.mediaType ? Question?.mediaType?.split('/')[1] : data?.supportPageQuestion?.mediaType?.split('/')[1]}
                                    </a>
                                ) : (
                                    <span>No Asset</span>
                                )}
                                <span className='block py-5'>Asset Url: {Question?.mediaUrl ? Question?.mediaUrl : data?.supportPageQuestion?.mediaUrl}</span>
                            </div>
                        </div>
                    </div>
                    <button className="mt-8 bg-brand-500 hover:bg-brand-300 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={save}> {saveResult && saveResult.loading ? "Saving...." : "Save"}</button>
                </div>
            </div>
        </div>
    )
}


const AssetPreview = ({ url, fileType }) => {
    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;
    };

    if (fileType?.includes("image")) {

        return (
            <div className='py-10'>
                <img src={url} className="w-128 h-128 p-2 mr-2" />
            </div>)
    }
    else fileType?.includes("video")
    {

        const videoJsOptions = {
            autoplay: false,
            controls: true,
            responsive: true,
            fluid: true,
            sources: [{
                src: url,
                type: fileType
            }]
        };

        return (
            <div className='py-10'>
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
            </div>
        )
    }
}


export default Edit
