
function CreateControlBarTitle(videojs) {

    var Component = videojs.getComponent('Component');

    var ControlBarTitle = videojs.extend(Component, {
        constructor: function(player, options) {
            Component.apply(this, arguments);
            if (options.text) {
                this.updateTextContent(options.text);
            }
        },

        createEl: function() {
            return videojs.createEl('div', {
                className: 'vjs-control-bar-title'
            });
        },

        updateTextContent: function(text) {
            if (typeof text !== 'string') {
                text = 'Unknown title';
            }
            videojs.emptyEl(this.el());
            videojs.appendContent(this.el(), text);
        }
    });

    videojs.registerComponent('ControlBarTitle', ControlBarTitle);
}

export default CreateControlBarTitle;
