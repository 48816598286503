import { defineMessages } from 'react-intl'

export default defineMessages({
  captionsLoadFailed: {
    id: 'components.dashboard.CaptionFlow.captionsLoadFailed',
    defaultMessage: 'Ups! Noget gik galt – kontakt venligst vores support på +45 7872 5672.',
  },
  insertSubtitle: {
    id: 'components.dashboard.CaptionFlow.insertSubtitle',
    defaultMessage: 'Indsæt undertekst',
  },
  loading: {
    id: 'components.dashboard.CaptionFlow.loading',
    defaultMessage: 'Henter video og undertekster',
  },
  saveButtonText: {
    id: 'components.dashboard.CaptionFlow.saveButtonText',
    defaultMessage: 'Gem',
  },
  produceButtonText: {
    id: 'components.dashboard.CaptionFlow.produceButtonText',
    defaultMessage: 'Producér video',
  },
  title: {
    id: 'components.dashboard.CaptionFlow.title',
    defaultMessage: 'Titel',
  },
  cancel: {
    id: 'components.dashboard.cancel',
    defaultMessage: 'Fortryd',
  },
  confirm: {
    id: 'components.dashboard.confirm',
    defaultMessage: 'Bekræft',
  },
  subtitleBackgroundToggleLabel: {
    id: 'components.dashboard.CaptionFlow.subtitleBackgroundToggleLabel',
    defaultMessage: 'Baggrundskasse',
  },
  emptySubtitlesLabel: {
    id: 'components.dashboard.CaptionFlow.emptySubtitlesLabel',
    defaultMessage: 'Tøm alle undertekster',
  },
  shortcutsPlayPause: {
    id: 'components.dashboard.CaptionFlow.shortcutsPlayPause',
    defaultMessage: 'Afspil / pause',
  },
  shorcutsDelete: {
    id: 'components.dashboard.CaptionFlow.shorcutsDelete',
    defaultMessage: 'Slet aktiv undertekst',
  },
  shorcutsSplit: {
    id: 'components.dashboard.CaptionFlow.shorcutsSplit',
    defaultMessage: 'Split aktiv undertekst',
  },
  shorcutsMaximize: {
    id: 'components.dashboard.CaptionFlow.shorcutsMaximize',
    defaultMessage: 'Maximér undertekst længde',
  },
  splitSubtitleInTwo: {
    id: 'components.dashboard.CaptionFlow.splitSubtitleInTwo',
    defaultMessage: 'Split undertekst i to',
  },
  addSubtitleAfterThis: {
    id: 'components.dashboard.CaptionFlow.addSubtitleAfterThis',
    defaultMessage: 'Tilføj undertekst efter denne',
  },
  changeTimestamps: {
    id: 'components.dashboard.CaptionFlow.changeTimestamps',
    defaultMessage: 'Ændre tidskoder',
  },
  deleteSubtitle: {
    id: 'components.dashboard.CaptionFlow.deleteSubtitle',
    defaultMessage: 'Slet undertekst',
  },
  confirmDeleteAllSubtitles: {
    id: 'components.dashboard.CaptionFlow.confirmDeleteAllSubtitles',
    defaultMessage: 'Er du sikker på du ønsker at slette alle undertekster?',
  },
  goodJob: {
    id: 'components.dashboard.CaptionFlow.goodJob',
    defaultMessage: 'Godt arbejde',
  },
  sendToProduction: {
    id: 'components.dashboard.CaptionFlow.sendToProduction',
    defaultMessage: 'Send til produktion',
  },
  sendCaptionToProduction: {
    id: 'components.dashboard.CaptionFlow.sendCaptionToProduction',
    defaultMessage: 'Send undertekster{br}til produktion'
  },
  saveChanges: {
    id: 'components.dashboard.CaptionFlow.saveChanges',
    defaultMessage: 'Gem dine ændringer'
  },
  sendToProductionDescription: {
    id: 'components.dashboard.CaptionFlow.sendToProductionDescription',
    defaultMessage: 'Er du færdig med underteksterne, og klar til at sende dem til produktion?',
  },
  waitWhileWeSaveYourChanges: {
    id: 'components.dashboard.CaptionFlow.waitWhileWeSaveYourChanges',
    defaultMessage: 'Vent venligst mens vi gemmer dine ændringer',
  },
  weAreProducingYourVideo: {
    id: 'components.dashboard.CaptionFlow.weAreProducingYourVideo',
    defaultMessage: 'Vi producerer nu din video.'
  }
})
