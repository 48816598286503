import React, {useContext} from 'react'
import { DashboardTimeContext, Period} from '../stores/TimePeriod'
import DateTimePicker from 'react-datetime-picker'

import { Mixpanel } from '../Common/Mixpanel'

const TimeButtons : React.FC = () => {
    const {timePeriod, dispatch} = useContext(DashboardTimeContext);
    const clicked = (entry : Period, evt) => {
        Mixpanel.track("Set timeperiod", entry)
        dispatch({type: "SET_TIMEPERIOD", payload: entry})
    }
    const updateTime = (evt) => {
        Mixpanel.track("Set start time", {date: evt})
        dispatch({type: "SET_START_TIME", payload: evt})
    }
    if(!timePeriod.possiblePeriods) return <span></span>
        return (
            <div className="flex text-xs lg:text-sm">
                <div className="flex flex-col">
                    <em className="text-xs p-0 m-0 ">Start Time</em>
                    <DateTimePicker  value={timePeriod.startTime} onChange={(evt) => updateTime(evt) } locale="da-DK" className="p-0" />
                </div>
                {timePeriod.possiblePeriods.map(row => {
                    const active = (timePeriod.graphPeriod.key == row.key)
                    const baseClasses = 'appearance-none py-4 text-blue-600 border-b cursor-pointer '
                    const classes = baseClasses + (active ? 'border-blue-600 mr-1 lg:mr-3' : 'border-transparent hover:border-grey-600 mr-1 lg:mr-3')
                    return (<a className={classes} key={row.key} onClick={(e) => clicked(row, e)}>{row.key}</a>)
                })}
            </div>)
}

export default TimeButtons
