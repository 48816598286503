import React, { useState } from "react";
import { Routes, Route, Outlet, NavLink } from "react-router-dom";
import PostToolDashboard from "./Tools/PostToolDashboard";
import NotYetCreated from "./Tools/NotYetCreated";

const navigation = [
  { name: "Accounts", href: "/admin/tools/accounts" },
  { name: "Teams", href: "/admin/tools/teams" },
  { name: "Users", href: "/admin/tools/users" },
  { name: "Notifications", href: "/admin/tools/notifications" },
  { name: "Posts", href: "/admin/tools/posts" },
  { name: "Estate Family", href: "/admin/tools/ef" },
];

const ToolDashboard = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
        <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"></div>
      </div>
    </div>
  );
};

const Tools = () => {
  return (
    <div>
      <div className="flex space-x-4">
        {navigation.map((item) => (
          <NavLink
            key={item.name}
            className={({ isActive }) =>
              isActive
                ? "rounded-md px-3 py-2 text-sm font-medium bg-gray-900 text-brand-500"
                : "rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-300 hover:text-brand-500"
            }
            to={item.href}
          >
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="flex flex-wrap">
        <div className="flex-grow w-full mx-auto">
          <Routes>
            <Route index element={<ToolDashboard />} />
            <Route path="accounts" element={<NotYetCreated />} />
            <Route path="teams" element={<NotYetCreated />} />
            <Route path="users" element={<NotYetCreated />} />
            <Route path="notifications" element={<NotYetCreated />} />
            <Route path="posts" element={<PostToolDashboard />} />
            <Route path="ef" element={<NotYetCreated />} />
          </Routes>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Tools;
