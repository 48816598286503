import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useParams } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'

export const RENDERINGSHOW_QUERY = gql`
query RenderingShow($id:ID!) {
    rendering(id:$id) {
        id
        name
        createdAt
        state
        remoteId
        template {
            id
            name
        }
        events {
            totalItems
            items {
                id
                type
                createdAt
                payload
            }
        }
    }
}
`

const Show = () => {
    let params = useParams()
    const vars = {id: params.renderingId}
    return (
        <Query fetchPolicy="no-cache" query={RENDERINGSHOW_QUERY} variables={vars}>
            {({ loading, error, data, subscribeToMore, refetch }) => {
                if (loading) return <Loading />
                    if (error) {
                        console.log(error)
                        return <div>Error</div>
                    }
                if(data.rendering.state == 'rendering') setTimeout(refetch(), 10000)
                return (
                    <div className="flex flex-col">
                        <h2 className="text-xl">{data.rendering.name}</h2>
                        <h3 className="text-sm">{data.rendering.state} ({data.rendering.remoteId ? data.rendering.remoteId : ''})</h3>
                        <h3 className="mt-4">Latest Events</h3>
                        <div className="flex flex-col w-1/3">
                            {data.rendering.events.items.map((event) => {
                                return (
                                    <div className="p-4">
                                        <span className="text-sm">
                                            {event.type}
                                        </span>
                                        <span className="text-xs pl-4">
                                            ({timeDistanceInWords(event.createdAt)})
                                        </span>
                                        <span className="text-xs text-gray-900">
                                            <pre className="bg-gray-300">{event.payload}</pre>
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}}
                    </Query>

            )
}


export default Show
