import React, { useState } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import Pagination from '../../Pagination'

export const VIDEOLIST_QUERY = gql`
query VideoList($limit:Int, $search:String, $page:Int) {
    videos(limit: $limit, search:$search, sortBy:CreatedAt, sortDirection:DESC, page:$page) {
        currentPage
        totalPages
        totalItems
        firstPage
        lastPage
        previousPage
        nextPage

        items {
            id
            title
            createdAt
            publicPoster
            tags {
                id
                name
            }
            channel {
              id
              title
            }
        }
    }
}
`

const List = () => {
    const [search, setSearch] = useState("")
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    const updateSearchValue = (evt) => {
        if(evt.keyCode == 13) {
            setSearch(evt.target.value)
            setPage(1)
        }
    }

        return (
            <Query query={VIDEOLIST_QUERY} variables={{limit: limit, search: search, page: page}}>

                {({ loading, error, data, subscribeToMore }) => {
                if (loading) return <Loading />
                if (error) {
                    console.log(error)
                    return <div>Error</div>
                }
                    return (
              <div className="flex flex-wrap -mx-4">
                  <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
                      <div className="flex justify-between px-1 -mb-px">
                          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto justify-end">
                              <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
                          </div>

                      </div>
                      <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden mt-4">
                    {data && data.videos.items.map((video, i) => {
                        return (
                          <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`${video.id}`}>
                                <div className="w-1/5 px-4">
                                  <Link to={`${video.id}`} video={video}>
                                    <img className="text-right text-grey" src={video.publicPoster} />
                                  </Link>
                                </div>
                              <div className="w-3/5 px-4 flex flex-col content-start ">
                                  <span className="text-xl">
                                      <Link to={`${video.id}`} video={video}>
                                          {video.title}
                                      </Link>
                                  </span>
                                  <span className="text-sm" title="channel">
                                    <Link to={`/dashboard/channels/${video.channel.id}`}>
                                      {video.channel.title}
                                    </Link>
                                  </span>
                                  <span className="flex flex-row text-sm">{video.tags.map((tag) => {
                                  return (
                                    <span className="mr-4" title="tag" key={tag.id}>
                                      <Link to={`/dashboard/tags/${tag.id}`}>
                                        {tag.name}
                                      </Link>
                                    </span>
                                  )
                                  })}
                                  </span>
                              </div>
                              <div className="w-1/5 px-4">
                                  <div className="text-right text-grey">
                                      {timeDistanceInWords(video.createdAt)}
                                  </div>
                              </div>
                          </div>
                        )
                    })}
                      </div>
    <Pagination paginated={data.videos} setPage={setPage} />
                  </div>

              </div>
                    )
                }}
            </Query>

      )
}

export default List
