import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { Query, useMutation } from "react-apollo";
import Loading from "../../../loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../Modal";

const findWithAttr = (array, attr, value) => {
  const l = array.length;
  for (var i = 0; i < l; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};

export const CATEGORYLIST_QUERY = gql`
  query CategoryList {
    currentAccount {
      id
      workflowCategories {
        id
        title
        createdAt
      }
    }
  }
`;
const WorkflowCategory = ({ category, saveCategory }) => {
  const [state, setState] = useState(category || {});

  const save = () => {
    saveCategory(state);
  };

  useEffect(() => save());

  return (
    <Query query={CATEGORYLIST_QUERY}>
      {({ loading, error, data, subscribeToMore }) => {
        if (loading) return <Loading />;
        if (error) {
          console.log(error);
          return <div>Error</div>;
        }

        const setCategory = (id) => {
          const index = findWithAttr(
            data.currentAccount.workflowCategories,
            "id",
            id,
          );
          const newState = data.currentAccount.workflowCategories[index];
          setState(newState);
        };

        return (
          <div className="flex flex-row">
            <div className="w-1/4">
              <span className="text-sm leading-5 font-medium text-gray-700">
                Category
              </span>
            </div>
            <div>
              <select
                id="type"
                className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                onChange={(evt) => setCategory(evt.target.value)}
                defaultValue={state.id ? state.id : ""}
              >
                {!state.type ? (
                  <option value={false} key={false}>
                    [select category]
                  </option>
                ) : (
                  ""
                )}
                {data.currentAccount.workflowCategories.map((category, i) => {
                  return (
                    <option value={category.id} key={category.id}>
                      {category.title}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default WorkflowCategory;
