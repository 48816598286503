import React, { useState } from "react";
import { Routes, Route, Outlet, NavLink, Navigate } from "react-router-dom";

import AdminInstanceList from "./Admin/InstanceList";
import AdminInstance from "./Admin/Instance";
import AdminFacebook from "./Admin/Facebook";
import AdminTools from "./Admin/Tools";
import AdminSupport from "./Admin/support/Support";

import NewQuestion from "./Admin/support/Question/New";
import EditQuestion from "./Admin/support/Question/Edit";
import DeleteQuestion from "./Admin/support/Question/Delete";

import NewSection from "./Admin/support/Section/New";
import EditSection from "./Admin/support/Section/Edit";
import DeleteSection from "./Admin/support/Section/Delete";

const navigation = [
  { name: "Instance List", href: "/admin/list" },
  { name: "Tools", href: "/admin/tools" },
  { name: "Support", href: "/admin/support" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dashboard = () => {
  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full border-b">
          <div className="flex space-x-4">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                className={({ isActive }) =>
                  isActive
                    ? "rounded-md px-3 py-2 text-sm font-medium bg-gray-900 text-brand-500"
                    : "rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-300 hover:text-brand-500"
                }
                to={item.href}
              >
                {item.name}
              </NavLink>
            ))}
          </div>
        </div>
        <div className="flex-grow w-full mx-auto">
          <Routes>
            <Route path="instance/:instanceId" element={<AdminInstance />} />
            <Route path="facebook/*" element={<AdminFacebook />} />
            <Route path="list" element={<AdminInstanceList />} />
            <Route path="tools/*" element={<AdminTools />} />
            <Route path="support/*" element={<AdminSupport />} />

            <Route
              path="new/question/:supportPageId"
              element={<NewQuestion />}
            />
            <Route path="question/:questionId" element={<EditQuestion />} />
            <Route
              path="delete/question/:questionId"
              element={<DeleteQuestion />}
            />

            <Route path="new/section/:supportPageId" element={<NewSection />} />
            <Route path="section/:sectionId" element={<EditSection />} />
            <Route
              path="delete/section/:sectionId"
              element={<DeleteSection />}
            />

            <Route path="*" element={<Navigate to="/admin/list" replace />} />
          </Routes>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
