import React, { useState } from 'react'
import { Query, useMutation, useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useNavigate } from 'react-router-dom'
import { timeDistanceInWords } from '../../helpers/TimeInWords'
import Loading from '../loading'
import { Mixpanel } from '../../Common/Mixpanel'
import Pagination from '../../Pagination'


export const WORKFLOWLIST_QUERY = gql`
query WorkflowList($limit:Int, $page: Int, $search: String) {
    currentUser {
        id
        isAdmin
    }
    workflows(limit:$limit, page:$page, search:$search, includeInternal: true) {
        currentPage
        totalPages
        previousPage
        nextPage
        items {
        id
        title
        iconUrl
        createdAt
            instances {
                totalItems
            }
            category {
                title
                iconUrl
            }
          touchpoint { 
            title
        	}
        }
}

}
`

export const WORKFLOW_CREATE_MUTATION = gql`
mutation createWorkflow($title:String!) {
    createWorkflow(title:$title) {
        id
        title
    }
}
`

const List = () => {
    let navigate = useNavigate()

    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")

    const updateSearchValue = (evt) => {
        if (evt.key == "Enter") {
            setSearch(evt.currentTarget.value)
            setPage(1)
        }
    }

    const [createNewWorkflow, { mutation_data }] = useMutation(WORKFLOW_CREATE_MUTATION);
    const createWorkflow = () => {
        const title = prompt("Workflow title");
        if (title != null) {
            const vars = {
                title
            }

            Mixpanel.track("Creating workflow", { title: title })

            const result = createNewWorkflow({ variables: vars })
            result.then((done) => {
                Mixpanel.track("Saved workflow", { title: title })
                console.log(done)
            }).catch(v => console.error(v))

        } else {
            console.log("cancel")
        }
    }

    if (mutation_data) {
        const url = `/design/workflows/${mutation_data.createWorkflow.id}`
        return navigate(url)
    }

    const { loading, data, error } = useQuery(WORKFLOWLIST_QUERY, {variables: {limit, page, search}});

                if (loading) return <Loading />
                if (error) {
                    console.log(error)
                    return <div>Error</div>
                }
                    return (
              <div className="flex flex-wrap p-4 -mx-4">
                  <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
                      <div className="flex justify-between mb-2 -mb-px">
                          <div className="w-full block flex-grow justify-end lg:flex lg:items-center lg:w-auto">
                              <div className="text-lg">
                                  <a href="#" onClick={() => createWorkflow() } className="block mt-4 lg:inline-block lg:mt-0 px-4 py-2 bg-brand-500 font-bold text-sm rounded text-white hover:bg-brand-400">Create new workflow</a>
                              </div>
                              <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
                          </div>
                      </div>
                      <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    {data && data.workflows.items.map((workflow, i) => {
                        return (
                          <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`template-${workflow.id}`}>
                              <div className="w-4/5 px-4 flex items-center">
                                <span>
                                    {workflow.iconUrl ?  <img src={workflow.iconUrl} className="w-8 h-8 p-2 mr-2" /> : <span /> }
                                </span>
                                  <span className="text-lg">
                                      <Link to={`${workflow.id}`} workflow={workflow}>
                                          {workflow.title}
                                      </Link>
                                  </span>
                              </div>
                
                              <div className="w-1/12 grow bg">
                                    <span className='text-left '> {workflow.touchpoint?.title ? workflow.touchpoint.title : ""} </span>
                                </div>
                                
                                {data.currentUser.isAdmin ?
                                    <div className="text-right text-grey flex-none mr-4">
                                        <span className='flex-none'>{timeDistanceInWords(workflow.createdAt)}</span>
                                        <Link to={`/design/workflows/${workflow.id}`} workflow={workflow} className="ml-4 flex-none">
                                            Edit
                                        </Link>
                                    </div>
                                    : <span />}
                          </div>

                          
                          
                        )
                    })}
                      </div>
                      <Pagination paginated={data.workflows} setPage={setPage} />
                  </div>
                  
              </div>
                    )
   
}


export default List
