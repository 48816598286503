import React, { useRef, useEffect } from "react";
import { render } from "react-dom";
import AceEditor from "react-ace";
import { v4 as uuidv4 } from 'uuid';

import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

// This is the default theme, so it is always imported
import "ace-builds/src-noconflict/theme-monokai";


export default ({ mode, value, update, theme = 'monokai', width = '100%', height = '400px', liquidScope = null }) => {
    const name = useRef(uuidv4())
    const ref = useRef(null);
    const dropkiq = useRef(null);

    console.log("Liquid scope: ")
    console.log(liquidScope)


    useEffect(() => {
        if(ref.current && liquidScope && !dropkiq.current) {
            window.editor = ref.current
            var licenseKey = "894f2a10-3b3f-013b-0a85-4a36325215e0";
            console.log("Editor: ", ref.current.editor)
            console.log("Running setup")
            dropkiq.current = new DropkiqUIFromScope(ref.current.editor, liquidScope, licenseKey).dropkiqUI();
            const keyboardHandler = {
                handleKeyboard: function(editor, index, name, keyCode){
                    console.log("Handle keyboard")
                    if(dropkiq.current.menuIsOpen() && [27, 38, 40, 9, 13].includes(keyCode)){
                        return {command:"null", passEvent:false};
                    }
                }
            }
            ref.current.editor.keyBinding.addKeyboardHandler(keyboardHandler);
        }
    }, [])


    return (
        <AceEditor
            ref={ref}
            mode={mode}
            theme={theme}
            width={width}
            height={height}
            onChange={update}
            value={value}
            name={name.current}
        />
    )

}

