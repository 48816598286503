import React from 'react'
export const UserContext = React.createContext({})
import { MD5 } from 'crypto-js'
import gql from 'graphql-tag'
import { Query, useQuery } from 'react-apollo'
import { Mixpanel } from '../Common/Mixpanel'

export const USER_QUERY = gql`
query User {
    currentUser {
        id
        email
        isAdmin
        name
        username
        settings

        impersonatedAccount {
            id
            title
        }
        account {
            siteAdmin
            title
        }
    }
}
`


export const initialUser = {
    admin: false,
    account: {
        title: null,
        admin: false
    },
    email: null,
    name: null,
}

let currentUserId = undefined

export const UserProvider = props => {
  const { loading, data, error } = useQuery(USER_QUERY);
  const user = loading || error || !data.currentUser ? null : data.currentUser;
    if(user && data) {
        localStorage.removeItem('graphPeriod')
    }
    if(user) {
        user.documentBaseTitle = 'Fliva';
        if(user.impersonatedAccount) user.documentBaseTitle = "Fliva | (" + user.impersonatedAccount.title + ")"
        window.document.title = user.documentBaseTitle;

        if(currentUserId != user.id) {
            Mixpanel.identify(data.id)
            Mixpanel.track("Logged in", user)
            Mixpanel.people.set({
                "USER_ID": user.id,
                "$email": user.email,
            })
            currentUserId = user.id
        }
        var md5Hash = MD5(user.email).toString();
        user.gravatar = 'https://s.gravatar.com/avatar/' + md5Hash
        typeof user.settings == 'string' ? user.settings = JSON.parse(user.settings) : undefined
    }
  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
export default UserContext;
