import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import UserList from "./AccountSettings/UserList";
import TeamList from "./AccountSettings/TeamList";
import Team from "./AccountSettings/Team";

import User from "./AccountSettings/User";
import Tool from "./AccountSettings/Tool";

const AccountSettings = () => {
  return (
    <div>
      <h1 className="text-3xl text-black pb-4">Account</h1>
      <div className="flex flex-wrap">
        <div className="flex-grow w-full mx-auto">
          <Routes>
            <Route path={`tool`} element={<Tool />} />
            <Route path={`teams/:teamId`} element={<Team />} />
            <Route path={`teams`} element={<TeamList />} />
            <Route path={`:userId`} element={<User />} />
            <Route index element={<UserList />} />
          </Routes>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
