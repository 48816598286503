import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import List from './Account/List'
import Show from './Account/Show'
import Edit from './Account/Edit'
import { Mixpanel } from '../Common/Mixpanel'


const AccountDashboard = () => {
    return (
                <div>
                    <h1 className="text-3xl text-black pb-4">Accounts</h1>
                    <div className="flex flex-wrap">
                        <div className="flex-grow w-full mx-auto">
                            <Routes>
                            <Route path={`:accountId/edit`} element={<Edit />} />
                            <Route path={`:accountId`} element={<Show  />} />
                            <Route index element={<List />} />
                        </Routes>
                        <Outlet />
                        </div>
                    </div>
                </div>
    )
}

export default AccountDashboard
