import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import List from './Comps/List'
import Show from './Comps/Show'
import Edit from './Comps/Edit'
import New from './Comps/New'
import { Mixpanel } from '../Common/Mixpanel'

const CompsDashboard = () => {
    console.log("comps dashboard")
    return (
        <div>
            <h1 className="text-3xl text-black pb-4">Compositions</h1>
            <div className="flex flex-wrap">
                <div className="flex-grow w-full mx-auto">
                    <Routes>
                        <Route path="new/composition" element={ <New />} />
                        <Route path=":compositionId/edit" element={<Edit  />} />
                        <Route path=":compositionId" element={<Show  />} />
                        <Route index element={ <List  />} />
                    </Routes>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default CompsDashboard
