import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import List from './Tag/List'
import Show from './Tag/Show'
import Edit from './Tag/Edit'
import { Mixpanel } from '../Common/Mixpanel'

const TagDashboard = () => {
    return (
        <div>
            <h1 className="text-3xl text-black pb-4">Tags</h1>
            <div className="flex flex-wrap">
                <div className="flex-grow w-full mx-auto">
                    <Routes>
                    <Route path={`:tagId/edit`} element={<Edit  />} />
                    <Route path={`:tagId`} element={<Show  />} />
                    <Route index element={<List  />} />
                </Routes>
                <Outlet />
                </div>
            </div>
        </div>
    )
}

export default TagDashboard
