import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Routes, Route, Link, Outlet } from 'react-router-dom'
import Collections from './Collections';
import Collection from './Collection';

const Empty = () => {
    return <h2>This should never happen</h2>
}


const TextTemplateDashboard = () => {
    return (
                <div>
                    <h1 className="text-3xl text-black pb-4">Texts</h1>
                    <div className="flex flex-wrap">
                        <div className="flex-grow w-full mx-auto">
                            <Routes>
                            <Route path={`collections/:collectionId`} element={<Collection  />} />
                            <Route path={`collections`} element={<Collections  />} />
                            <Route index exact element={<Empty  />} />
                            </Routes>
                        </div>
                    </div>
                </div>
    )
}

export default TextTemplateDashboard
