import React from 'react'
import {  Routes, Route, Outlet } from 'react-router-dom'
import List from './Webhook/List'
import Show from './Webhook/Show'
import Edit from './Webhook/Edit'
import { Mixpanel } from '../Common/Mixpanel'

const WebhookDashboard = () => {
    return (
                <div>
                    <h1 className="text-3xl text-black pb-4">Webhooks</h1>
                    <div className="flex flex-wrap">
                        <div className="flex-grow w-full mx-auto">
                            <Routes>
                            <Route path={`:webhookId/edit`} element={<Edit  />} />
                            <Route path={`:webhookId`} element={<Show  />} />
                            <Route index element={<List />} />
                        </Routes>
                        <Outlet />
                        </div>
                    </div>
                </div>
    )
}

export default WebhookDashboard
