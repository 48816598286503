import React from 'react'
import { MutationResult} from 'react-apollo'
import { useMutation } from 'react-apollo'
import { Version } from '../types'
import { Menu } from '@headlessui/react'
import { timeDistanceInWords } from '../helpers/TimeInWords'
import { DocumentNode } from 'graphql'
import { OperationDefinitionNode, VariableDefinitionNode, VariableNode } from 'graphql'

/**
 * @param versions array of versions that will be displayed
 * @param ROLL_BACK_MUTATION mutation for rolling back the item
 * @param itemID Id for the item that might be reverted
 */
export interface VersionsProps {
    versions: Version[]
    ROLL_BACK_MUTATION: DocumentNode
    itemID: string
}

/**
 * Given a GraphQL mutation document, extracts and returns an array of variable names.
 * @param documentNode The GraphQL document node representing a mutation.
 * @returns An array of variable names used in the mutation.
 * @throws Error if no mutation is found in the document.
 */
function getMutationVariableNames(documentNode: DocumentNode): string[] {

    // Find the operation definition within the document that represents a mutation
    const operationDefinition = documentNode.definitions.find(
        (definition) =>
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'mutation'
    ) as OperationDefinitionNode | undefined;

    // If no mutation is found, throw an error
    if (!operationDefinition) {
        throw new Error('No mutation found in the document');
    }

    // Get the variable definitions from the operation definition
    const variableDefinitions = operationDefinition.variableDefinitions || [];

    // Extract variable names from the variable definitions
    const variableNames = variableDefinitions.map(
        (variableDefinition: VariableDefinitionNode) =>
            (variableDefinition.variable as VariableNode).name.value
    );

    return variableNames;
}

/**
 * @param versions array of versions that will be displayed
 * @param ROLL_BACK_MUTATION mutation for rolling back the item
 * @param itemID Id for the item that might be reverted
 */
const Versions: React.FC<VersionsProps> = ({ versions, ROLL_BACK_MUTATION, itemID }) => {

    //Gets the variable names from the mutation
    const variableNames: string[] = getMutationVariableNames(ROLL_BACK_MUTATION);

    const [rollBack, revertResult] = useMutation(ROLL_BACK_MUTATION)

    /**
     * @param versionId The ID for the version the object will be reverted to
     */
    const save = (versionId) => {

        const vars = {};
        console.log("Reverting version...")
        //sets the values for the variables.
        //The rollback mutations are nearly identical, except the types and variable names. 
        //0 is always the ID of the item that will be reverted
        //1 is always the ID of the version. The variable name here can change though. T
        //Fx it can be called "templateId" or "WorkflowID"
        vars[variableNames[0]] = itemID;
        vars[variableNames[1]] = versionId

        const result = rollBack({ variables: vars })
        result.then((done) => {
            
            
        }).catch(v => {console.error(v)
                        alert(v)}).finally(() => {
        }).finally(() =>
            {
                console.log("Reverted sucessfully!")
                // alert("version reverted")
            })
            
        
    }

    //Uses a menu component to act as a dropdown/accordian. 
    //Right now the menu has only one entry, with all the versions inside of it. 
    //It can later be refractored so every version is its own item in the menu if necessary
    return (
        <div>
            <Menu>
                <div className='flex-grow flex items-left justify-left'>
                    <Menu.Button className="  bg-brand-500 w-1/6 text-white hover:bg-brand-800 hover:text-brand-200 font-bold py-2 px-4 mr-8 rounded">Versions</Menu.Button>
                </div>
                <Menu.Items className="bg-white border border-slate-300 rounded-t-md">
                    <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b">
                        <p className="w-4/5 px-4 flex items-center">Date changed:</p>
                        <p className="w-4/5 px-4 flex items-center">Version ID:</p>
                        <p className="w-3/5 px-4">Changed by:</p>
                    </div>
                    <DisplayVersions versions={versions} saveFunction={save} revertResult={revertResult}></DisplayVersions>
                </Menu.Items>
            </Menu>
        </div>
    )
}

/**
 * @param versions Array of versions that will be displayed
 * @param saveFunction function that will be called, when the revert button is pressed.
 * @param revertResult Result from the roll back query that is injected into this component. This is used to display a reverting in progress message on the revert button
 */
interface DisplayVersionProps {
    versions: Version[]
    saveFunction: (versionId) => void
    revertResult: MutationResult<any>
}

/**
 * @param versions Array of versions that will be displayed
 * @param saveFunction function that will be called, when the revert button is pressed.
 * @param revertResult Result from the roll back query that is injected into this component. This is used to display a reverting in progress message on the revert button
 */
const DisplayVersions: React.FC<DisplayVersionProps> = ({ versions, saveFunction, revertResult }) => {
    return (
        <div>
            {
                versions.map((version, index) => {
                   
                    //Checks if the model is empty.
                    // Papertrail keeps a model of the object before it started versioning. 
                    //If the object is created after we started versioning, then this model will be empty. 
                    //If a user tries to revert to a empty model, it will give a error.  
                    if(version.objectModelNil)
                    {
                        return null;
                    }

                    return (
                        <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b" key={version.id}>

                            <div className="w-3/5 px-4">
                                <div className="text-left text-grey" title={version.createdAt}>
                                    {timeDistanceInWords(version.createdAt)}
                                </div>
                            </div>
                            <div className="w-4/5 px-4 flex items-center">
                                <span className="text-lg">
                                    {version.id}
                                </span>
                            </div>
                            <div className="grid grid-cols-1 w-4/5">
                                <div className="text-lg">
                                    <span className='font-bold'>
                                        Email: 
                                    </span>
                                    <span>
                                        {version.whodunnit ? version.whodunnit.email : null}
                                    </span>
                                </div>
                                <div className="text-lg">
                                    <span>
                                        <span className='font-bold'>
                                            Id: 
                                        </span>
                                        {version.whodunnit ? version.whodunnit.id : null}
                                    </span>
                                </div>
                            </div>
                            <button className="bg-brand-500 text-white hover:bg-brand-800 hover:text-brand-200 font-bold py-2 px-4 mr-8 rounded" onClick={() => saveFunction(version.id)}>
                                {revertResult && revertResult.loading ? "Reverting...." : "Revert"}
                            </button>
                        </div>
                    )
                }
            )
            }

        </div>
    )
}


export default Versions
