import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import {  Link } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import {RenderingConnections} from '../../types'
import Pagination from '../../Pagination'

export const RENDERINGLIST_QUERY = gql`
query RenderingList($limit:Int, $page: Int) {
    renderings(limit:$limit, page: $page) {
        currentPage
        totalPages
        previousPage
        nextPage
        items {
            id
            name
            createdAt
            state
        }
    }
}
`

interface RenderingRouterProps {
}


interface RenderingListResult {
    renderings: RenderingConnections;
}

const List = () => {
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)

    const { loading, data, error } = useQuery<RenderingListResult>(RENDERINGLIST_QUERY, {variables: {limit, page}});
    if (loading) return <Loading />
        if (error) {
            console.log(error)
            return <div>Error</div>
        }
    return (
        <div className="flex flex-wrap -mx-4">
            <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
                <div className="flex justify-between px-6 -mb-px">
                    <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                        <div className="text-lg">
                            <a href="#" className="block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase text-black hover:text-brand-500">New</a>
                        </div>
                    </div>

                </div>
                <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    {data && data.renderings.items.map((rendering, i) => {
                        return (
                            <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`rendering-${rendering.id}`}>
                                <div className="w-4/5 px-4 flex items-center">
                                    <span className="text-lg">
                                        <Link to={`${rendering.id}`}>
                                            {rendering.name}
                                        </Link>
                                    </span>
                                    <span className="text-sm pl-4">({rendering.state})</span>
                                </div>
                                <div className="w-1/5 px-4">
                                    <div className="text-right text-grey">
                                        {timeDistanceInWords(rendering.createdAt)}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Pagination paginated={data.renderings} setPage={setPage} />
            </div>
        </div>
    )
}

export default List
