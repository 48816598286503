import React from 'react'
import { useQuery } from 'react-apollo'
import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'
import Loading from '../loading'

export const DOMAINSHOW_QUERY = gql`
query DomainShow($id:ID!) {
    domain(id:$id) {
        id
        hostname
        createdAt
    }
}
`

const Show = () => {
    let params = useParams()
    const vars = {id: params.domainId}
    const { loading, error, data } = useQuery(DOMAINSHOW_QUERY, {variables: vars})
    if (loading) return <Loading />
    if (error) {
        console.log(error)
        return <div>Error</div>
    }
    return (
        <div>
            <span>{data.domain.hostname}</span>
        </div>
    )
}


export default Show
