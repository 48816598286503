import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { UserConsumer } from '../../../stores/User'
import { Routes, Route, Link, Outlet, useParams} from 'react-router-dom'
import Editor from '../../../player-builder/PlayerEditor'
import {FacebookPageConnections, Account, Team, User, FacebookPage} from '../../../types'
import {timeDistanceInWords} from '../../../helpers/TimeInWords'
import Loading from '../../loading'
import "ace-builds/src-noconflict/mode-json";
import { FACEBOOK_PAGE_LIST, FACEBOOK_PAGE_SHOW, LOOKUP_USERS, LOOKUP_TEAMS, FACEBOOK_PAGE_UPDATE, FacebookPagesResult, FacebookPageResult, LookupUsersResult, LookupTeamsResult, FacebookPageUpdateResult } from './facebook/Query'
import Pagination from '../../../Pagination'
import { Mixpanel } from '../../../Common/Mixpanel'
import { Combobox } from '@headlessui/react'

const TextShow = ({id, label, value}) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <input disabled={true} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" value={value} />
                </div>
            </div>
        </div>
    )
}

const AccountInput = ({id, label, defaultValue, options, allowEmpty, onChange, emptyOption = '[none]', optionTitleField = (field) => field.title}) => {
  const [search, setSearch] = useState("")
  const showOptions = search.length > 0 ? options.filter((account) => account.title.toLowerCase().includes(search.toLowerCase())) : options
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <Combobox defaultValue={defaultValue} onChange={onChange} nullable>
                    <Combobox.Input displayValue={(show : any) => show?.title ?? ''}
                      value={search} onChange={(event) => setSearch(event.target.value)} />
                    <Combobox.Options>
                      {showOptions.map((account) => (
                        <Combobox.Option key={account.id} value={account}
                          className="ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-gray-800 py-2 px-3"
                        >
                          {account.title}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </Combobox>
                </div>
            </div>
        </div>
    )
}

const TeamInput = ({id, label, defaultValue, allowEmpty, onChange, emptyOption = '[none]', account}) => {
  const [search, setSearch] = useState("")
  const {loading, error, data, refetch } = useQuery<LookupTeamsResult>(LOOKUP_TEAMS, {variables: {accountId: account.id, search}})
  if (error) {
    console.log(error)
    return <div>Error</div>
  }
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <Combobox defaultValue={defaultValue} onChange={onChange} nullable>
                    <Combobox.Input displayValue={(show : any) => show?.title ?? ''} value={search} onChange={(event) => setSearch(event.target.value)} />
                    <Combobox.Options>
                      {!loading && data.account.teams.map((team) => (
                        <Combobox.Option key={team.id} value={team}
                          className="ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-gray-800 py-2 px-3"
                        >
                          {team.title}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </Combobox>
                </div>
            </div>
        </div>
    )
}

const UserInput = ({id, label, defaultValue, allowEmpty, onChange, emptyOption = '[none]', account}) => {
  const [search, setSearch] = useState("")
  const {loading, error, data, refetch } = useQuery<LookupUsersResult>(LOOKUP_USERS, {variables: {accountId: account?.id, search}})
  if (error) {
    console.log(error)
    return <div>Error</div>
  }
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <Combobox defaultValue={defaultValue} onChange={onChange} nullable>
                    <Combobox.Input displayValue={(show : any) => (show?.name || show?.email) ?? '' } value={search} onChange={(event) => setSearch(event.target.value)} />
                    <Combobox.Options>
                      {!loading && data.account.users.items.map((user) => (
                        <Combobox.Option key={user.id} value={user}
                          className="ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-gray-800 py-2 px-3"
                        >
                          {user.name || user.email}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </Combobox>
                </div>
            </div>
        </div>
    )
}


const FacebookPageShow = () => {
  const params = useParams()
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const vars = {id:params.pageId}

  const {loading, error, data, refetch } = useQuery<FacebookPageResult>(FACEBOOK_PAGE_SHOW, {variables: vars})
  const [savePage, savePageResult] = useMutation(FACEBOOK_PAGE_UPDATE);

  const save = () => {
    const vars = {
      id: params.pageId,
      accountId: selectedAccount?.id,
      teamId: selectedTeam?.id,
      userId: selectedUser?.id
    }
    const result = savePage({ variables: vars })
    result.then((done) => {
      Mixpanel.track("Saved facebook page", vars) 
      console.log(done)
    }).catch(v => console.error(v))
  }
  if (loading) return <Loading />
  if (error) {
    console.log(error)
    return <div>Error</div>
  }

        return (
                    <div className="w-full">
                        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                          <TextShow id="title" label="Title" value={data.facebookPage.title} />
                          <TextShow id="category" label="Category" value={data.facebookPage.category} />
                          <AccountInput id="account" label="Account" defaultValue={selectedAccount || data.facebookPage.account} options={data.accounts.items} onChange={(value) => setSelectedAccount(value)} allowEmpty={true} />
                          { data.facebookPage.account || selectedAccount ?
                          <>
                          <TeamInput id="team" label="Team" defaultValue={selectedTeam || data.facebookPage.team} onChange={(value) => setSelectedTeam(value)} allowEmpty={true} account={selectedAccount || data.facebookPage.account} />
                          <UserInput id="user" label="User" defaultValue={selectedUser || data.facebookPage.user} onChange={(value) => setSelectedUser(value)}  allowEmpty={true} account={selectedAccount || data.facebookPage.account} />
                            </>

                          : <span />
                        }
                        <div className="flex">
                        <a onClick={save} className="mt-8 bg-brand-500 hover:bg-brand-300 text-white font-bold w-1/8 py-2 px-4 rounded focus:outline-none focus:shadow-outline">{savePageResult && savePageResult.loading ? "Saving...." : "Save"}</a>
                        <Link to="/admin/facebook" className="mt-8 bg-red-500 hover:bg-red-300 text-white font-bold w-1/8 py-2 px-4 rounded focus:outline-none focus:shadow-outline">Cancel</Link>
                      </div>
                        </div>
                    </div>

        )
}


const FacebookDashboard = () => {
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState("CreatedAt")
  const [sortDirection, setSortDirection] = useState("DESC")
  const [unlinkedOnly, setUnlinkedOnly] = useState(false)


    const updateSearchValue = (evt : React.KeyboardEvent<HTMLInputElement>) => {
        if(evt.key == "Enter") {
            setSearch(evt.currentTarget.value)
            setPage(1)
        }
    }

  const vars = {limit, page, search, unlinkedOnly}
  const {loading, error, data, refetch } = useQuery<FacebookPagesResult>(FACEBOOK_PAGE_LIST, {variables: vars})
  if (loading) return <Loading />
  if (error) {
    console.log(error)
    return <div>Error</div>
  }


  return (
        <div className="flex flex-wrap">
            <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
                <div className="flex justify-between px-6 -mb-px">
                    <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                  <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto justify-end">
                    <fieldset className="shadow rounded border-0 p-3">
                    <legend>
                      Unlinked only
                    </legend>
                    <input name="unlinked" checked={unlinkedOnly} className="w-full h-full shadow rounded border-0 p-3" title="Only Unlinked" onClick={(evt) => setUnlinkedOnly((prev) => !prev)} type="checkbox" />
                </fieldset>
                      <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
                      </div>
                    </div>

                </div>
                <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    {data && data.facebookPages.items.map((page, i) => {
                        return <FacebookListEntry entry={page} key={i}/>
                    })}
                </div>
                <Pagination paginated={data.facebookPages} setPage={setPage} />
            </div>
        </div>
      )
}

const FacebookListEntry= ({entry}) => {
    const linked = entry.user || entry.team || entry.account;

    return (
        <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`page-${entry.id}`}>
          <div className="w-1/5 px-4 flex items-center">
            <span className="text-lg">
              <Link to={`page/${entry.id}`}>
                {entry.title} ({entry.category})
              </Link>
            </span>
          </div>
          <div className="w-1/5 px-4">
            { entry.account ? 
            <span>{entry.account.title}</span>
              : <span>(no account)</span>
        }
          </div>
          <div className="w-1/5 px-4">
            { entry.team ? 
            <span>{entry.team.title}</span>
              : <span>(no team)</span>
        }
          </div>
          <div className="w-1/5 px-4">
            { entry.user ? 
            <span>{entry.user.name || entry.user.email}</span>
              : <span>(no user)</span>
        }
          </div>
          <div className="w-1/5 px-4">
          <div className="text-right text-grey">
          {timeDistanceInWords(entry.createdAt)}
          </div>
          </div>
        </div>
    )
}

const Facebook = () => {
  return (
        <UserConsumer>
          { user => {
            return user && user.isAdmin && user.account.siteAdmin ? 
        <div >
            <h1 className="text-3xl text-black pb-4">Facebook Pages</h1>
            <div className="flex flex-wrap">
                <div className="flex-grow w-full mx-auto">
                  <Routes>
                    <Route path="page/:pageId" element={ <FacebookPageShow />} />
                    <Route index element={<FacebookDashboard  />} />
                  </Routes>
                <Outlet />
                </div>
            </div>
        </div>
          : <div><h1>Not allowed</h1></div>
          }}
    </UserConsumer>
  )
}



export default Facebook
