import React, { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { UserConsumer } from '../stores/User'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faVideo, faTags, faPhotoVideo, faScroll, faPencilRuler, faNetworkWired, faLink, faGlobe, faUserCircle, faPencil } from '@fortawesome/free-solid-svg-icons'
import { Transition } from '@tailwindui/react'

export function withRouter( Child ) {
    return ( props ) => {
        const location = useLocation();
        const navigate = useNavigate();
        return <Child { ...props } navigate={ navigate } location={ location } />;
    }
}

const Navigation = ({showMenu, setShowMenu}) => {


    const linkStyle = 'group flex items-center px-2 py-2 text-sm leading-5 font-medium text-brand-300 rounded-md hover:text-white hover:bg-brand-700 focus:outline-none focus:text-white focus:bg-brand-700 transition ease-in-out duration-150'
    const activeLinkStyle = 'group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white rounded-md bg-brand-900 focus:outline-none focus:bg-brand-700 transition ease-in-out duration-150'
    const iconStyle = 'mr-3 h-6 w-6 text-brand-400 group-focus:text-white group-hover:text-white transition ease-in-out duration-150'

    const mobileLinkStyle = 'group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-brand-300 hover:text-white hover:bg-brand-700 focus:outline-none focus:text-white focus:bg-brand-700 transition ease-in-out duration-150'
    const mobileActiveLinkStyle = 'group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-white bg-brand-900 focus:outline-none focus:bg-brand-700 transition ease-in-out duration-150'
    const mobileIconStyle = 'mr-4 h-6 w-6 text-brand-400 group-hover:text-white group-focus:text-white transition ease-in-out duration-150'

    const globalMenuClass = showMenu ? 'md:hidden' : 'hidden'

    return (
        <UserConsumer>
            { user => {
                if(!user) return <span />
                    return (
                        <>
                            <div className={globalMenuClass}>
                                <div className="fixed inset-0 flex z-40">
                                    <div className="fixed inset-0">
                                        <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
                                    </div>
                                    <Transition
                                        className="relative flex-1 flex flex-col max-w-xs w-full bg-brand-500"
                                        show={showMenu}
                                        enter="transition ease-in-out duration-300 transform"
                                        enterFrom="-translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transition ease-in-out duration-300 transform"
                                        leaveFrom="translate-x-0"
                                        leaveTo="-translate-x-full"
                                    >
                                        <div className="absolute top-0 right-0 -mr-14 p-1">
                                            <button className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" aria-label="Close sidebar" onClick={() => setShowMenu(false)}>
                                                <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                            <div className="flex-shrink-0 flex items-center px-4">
                                                <NavLink to="/dashboard">
                                                    <img className="h-8 w-auto" alt="Fliva" src="/images/fliva-logo-white.svg" title="Fliva" />
                                                </NavLink>
                                            </div>
                                            <nav className="mt-5 px-2 space-y-1">
                                                {user.settings.channels ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/channels"><FontAwesomeIcon icon={faLayerGroup} className={mobileIconStyle} />Channels</NavLink> : <span />}
                                                {user.settings.videos ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/videos"><FontAwesomeIcon icon={faVideo} className={mobileIconStyle} /> Videos </NavLink> : <span /> }
                                                {user.settings.tags ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/tags"><FontAwesomeIcon icon={faTags} className={mobileIconStyle} />Tags</NavLink> : <span /> }
                                                {user.settings.players ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/players"><FontAwesomeIcon icon={faPhotoVideo} className={mobileIconStyle} />Players</NavLink> : <span /> }
                                                {user.isAdmin ? (
                                                    <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/comps"><FontAwesomeIcon icon={faUserCircle} className={mobileIconStyle} />Comps</NavLink>) : <span />}
                                                {user.settings.templates ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/templates"><FontAwesomeIcon icon={faScroll} className={mobileIconStyle} /> Templates </NavLink> : <span /> }
                                                {user.settings.renderings ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/renderings"><FontAwesomeIcon icon={faPencilRuler} className={mobileIconStyle} /> Renderings </NavLink> : <span /> }
                                                {user.settings.workflows ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/workflows"><FontAwesomeIcon icon={faNetworkWired} className={mobileIconStyle} /> Workflows </NavLink> : <span /> }
                        {user.settings.webhooks ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/webhooks"><FontAwesomeIcon icon={faLink} className={mobileIconStyle} /> Webhooks </NavLink> : <span /> }
                                                {user.settings.domains ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/domains"><FontAwesomeIcon icon={faGlobe} className={mobileIconStyle} />Domains</NavLink> : <span /> }
                                                {user.settings.textTemplates ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/text/template/collections"><FontAwesomeIcon icon={faPencil} className={mobileIconStyle} />Texts</NavLink> : <span /> }
                                                {(user.settings.accounts && !user.impersonatedAccount) ? (
                                                    <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/accounts"><FontAwesomeIcon icon={faUserCircle} className={mobileIconStyle} />Accounts</NavLink>) : <span />}

                                            </nav>
                                        </div>
                                        <div className="flex-shrink-0 flex border-t border-brand-700 p-4">
                                        </div>
                                        <div className="flex-shrink-0 w-14">
                                            {/* <!-- Force sidebar to shrink to fit close icon --> */}
                                        </div>
                                    </Transition>
                                </div>
                            </div>

                            <div className="hidden md:flex md:flex-shrink-0">
                                <div className="flex flex-col w-64">
                                    {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
                                    <div className="flex flex-col h-0 flex-1 bg-brand-500">
                                        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                                            <div className="flex items-center flex-shrink-0 px-4">
                                                <NavLink to="/dashboard">
                                                    <img className="h-8 w-auto" alt="Fliva" src="/images/fliva-logo-white.svg" title="Fliva" />
                                                </NavLink>
                                            </div>
                                            <nav className="mt-5 flex-1 px-2 bg-brand-500 space-y-1">
                                                {user.settings.channels ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/channels"><FontAwesomeIcon icon={faLayerGroup} className={iconStyle} />Channels</NavLink> : <span />}
                                                {user.settings.videos ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/videos"><FontAwesomeIcon icon={faVideo} className={iconStyle} /> Videos </NavLink> : <span /> }
                                                {user.settings.tags ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/tags"><FontAwesomeIcon icon={faTags} className={iconStyle} />Tags</NavLink> : <span /> }
                                                <hr className="mx-4 my-4" />
                                                {user.settings.players ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/players"><FontAwesomeIcon icon={faPhotoVideo} className={iconStyle} />Players</NavLink> : <span /> }
                                                <hr className="mx-4 my-4" />
                                                {user.isAdmin ? (
                                                    <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/comps"><FontAwesomeIcon icon={faUserCircle} className={iconStyle} />Comps</NavLink>) : <span />}
                                                {user.settings.templates ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/templates"><FontAwesomeIcon icon={faScroll} className={iconStyle} /> Templates </NavLink> : <span /> }
                                                {user.settings.renderings ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/renderings"><FontAwesomeIcon icon={faPencilRuler} className={iconStyle} /> Renderings </NavLink> : <span /> }
                        {user.settings.workflows ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/workflows"><FontAwesomeIcon icon={faNetworkWired} className={iconStyle} /> Workflows </NavLink> : <span /> }
                                                {user.settings.webhooks ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/webhooks"><FontAwesomeIcon icon={faLink} className={iconStyle} /> Webhooks </NavLink> : <span /> }
                                                <hr className="mx-4 my-4" />
                                                {user.settings.domains ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/domains"><FontAwesomeIcon icon={faGlobe} className={iconStyle} />Domains</NavLink> : <span /> }
                                                {user.settings.textTemplates ? <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/text/template/collections"><FontAwesomeIcon icon={faPencil} className={iconStyle} />Texts</NavLink> : <span /> }
                                                {(user.settings.accounts && !user.impersonatedAccount) ? (
                                                    <NavLink className={({ isActive }) => (isActive ? mobileActiveLinkStyle : mobileLinkStyle)} to="/dashboard/accounts"><FontAwesomeIcon icon={faUserCircle} className={iconStyle} />Accounts</NavLink>) : <span />}

                                            </nav>
                                        </div>
                                        <div className="flex-shrink-0 flex border-t border-brand-700 p-4">
                                            <a href="#" className="flex-shrink-0 w-full group block">
                                                <div className="flex items-center">
                                                    <div>
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="text-sm leading-5 font-medium text-white">
                                                        </p>
                                                        <p className="text-xs leading-4 font-medium text-brand-300 group-hover:text-brand-100 transition ease-in-out duration-150">
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}}
        </UserConsumer>
    )
}

export default withRouter(Navigation)
