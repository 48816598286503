import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import {  Link } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import Pagination from '../../Pagination'

export const PLAYERLIST_QUERY = gql`
query PlayerList($limit:Int, $page: Int, $search: String) {
    players(limit:$limit, page: $page, search: $search) {
        currentPage
        totalPages
        previousPage
        nextPage
        items {
            id
            title
            createdAt
        }
    }
}
`



const List = () => {
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")

    const updateSearchValue = (evt : React.KeyboardEvent<HTMLInputElement>) => {
        if(evt.key == "Enter") {
            setSearch(evt.currentTarget.value)
            setPage(1)
        }
    }

    const { loading, data, error } = useQuery(PLAYERLIST_QUERY, {variables: {limit, page, search}});
    if (loading) return <Loading />
        if (error) {
            console.log(error)
            return <div>Error</div>
        }
    return (
        <div className="flex flex-wrap -mx-4">
            <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
                <div className="flex justify-between px-6 -mb-px">
                    <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                     <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto justify-end">
                            <Link to={`new/player`} className="block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase text-black hover:text-brand-500">
                                New
                            </Link>
        <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
        </div>
                    </div>

                </div>
                <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    {data && data.players.items.map((player, i) => {
                        return (
                            <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`player-${player.id}`}>
                                <div className="w-4/5 px-4 flex items-center">
                                    <span className="text-lg">
                                        <Link to={`${player.id}`} >
                                            {player.title}
                                        </Link>
                                    </span>
                                </div>
                                <div className="w-1/5 px-4">
                                    <div className="text-right text-grey">
                                        {timeDistanceInWords(player.createdAt)}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            <Pagination paginated={data.players} setPage={setPage} />
            </div>
        </div>
    )
}

export default List
