import React, { useState } from 'react'
import { Query, useMutation, useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useNavigate} from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Pagination from '../../Pagination'
import Loading from '../loading'
import { Mixpanel } from '../../Common/Mixpanel'

export const TEMPLATELIST_QUERY = gql`
query TemplateList($limit:Int, $page: Int, $search: String) {
    templates(limit:$limit, page:$page, search:$search) {
        currentPage
        totalPages
        previousPage
        nextPage
        items {
        id
        name
        createdAt
        }
    }
}
`
export const TEMPLATE_CREATE_MUTATION = gql`
mutation createTemplate($name:String!) {
    createTemplate(name:$name, engine: "liquid") {
        id
        name
    }
}
`


const List = () => {
    let navigate = useNavigate()
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")

    const updateSearchValue = (evt) => {
        if(evt.key == "Enter") {
            setSearch(evt.currentTarget.value)
            setPage(1)
        }
    }

    const [createNewTemplate, { mutation_data }] = useMutation(TEMPLATE_CREATE_MUTATION);
        const createTemplate = () => {
            const name = prompt("Template name");
            if(name != null) {
                const vars = {
                    name
                }

                Mixpanel.track("Creating template", {name: name}) 

                const result = createNewTemplate({variables: vars})
                result.then((done) => {
                    Mixpanel.track("Saved template", {name: name})
                    console.log(done)
                }).catch(v => console.error(v))

            } else {
                console.log("cancel")
            }
        }

        if(mutation_data) {
            const url = `/dashboard/templates/${mutation_data.createTemplate.id}`
            return navigate(url)
        }

    const { loading, data, error } = useQuery(TEMPLATELIST_QUERY, {variables: {limit, page, search}});

                if (loading) return <Loading />
                if (error) {
                    console.log(error)
                    return <div>Error</div>
                }
                    return (
              <div className="flex flex-wrap p-4 -mx-4">
                  <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
                      <div className="flex justify-between mb-2 -mb-px">
                          <div className="w-full block flex-grow justify-end lg:flex lg:items-center lg:w-auto">
                              <div className="text-lg">
                                  <a href="#" onClick={() => createTemplate() } className="block mt-4 lg:inline-block lg:mt-0 px-4 py-2 bg-brand-500 font-bold text-sm rounded text-white hover:bg-brand-400">Create new template</a>
                              </div>
                              <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
                          </div>
                      </div>
                      <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    {data && data.templates.items.map((template, i) => {
                        return (
                          <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`template-${template.id}`}>
                              <div className="w-4/5 px-4 flex items-center">
                                  <span className="text-lg">
                                      <Link to={`${template.id}`} template={template}>
                                          {template.name}
                                      </Link>
                                  </span>
                              </div>
                              <div className="w-1/5 px-4">
                                  <div className="text-right text-grey">
                                      {timeDistanceInWords(template.createdAt)}
                                  </div>
                              </div>
                          </div>
                        )
                    })}
                      </div>
                      <Pagination paginated={data.templates} setPage={setPage} />
                  </div>
              </div>
                    )
}

export default List
