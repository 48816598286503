import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import {TagConnections} from '../../types'
import Pagination from '../../Pagination'

interface TagRouterProps {
}


interface TagListResult {
  tags: TagConnections;
}

export interface ListTagsProps {
    tags: TagConnections;
}


export const TAGLIST_QUERY = gql`
query TagList($limit:Int, $search:String, $page:Int) {
    tags(limit:$limit, search:$search, page:$page, sortDirection:ASC, sortBy:Name) {
        currentPage
        totalPages
        previousPage
        nextPage
        items {
        id
        title
        createdAt
        }
    }
}
`

const TagList : React.FC<ListTagsProps> = ({tags}) => {
    return (
              <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                {tags.items.map((tag, i) => {
                return (
                  <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`tag-${tag.id}`}>
                      <div className="w-4/5 px-4 flex items-center">
                          <span className="text-lg">
                              <Link to={`${tag.id}`}>
                                  {tag.title}
                              </Link>
                          </span>
                      </div>
                      <div className="w-1/5 px-4">
                          <div className="text-right text-grey">
                              {timeDistanceInWords(tag.createdAt)}
                          </div>
                      </div>
                  </div>
                )
            })}
              </div>
    )
}

const List  = (props) => {
    const [search, setSearch] = useState("")
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)

    const updateSearchValue = (evt : React.KeyboardEvent<HTMLInputElement>) => {
        if(evt.key == "Enter") {
            setSearch(evt.currentTarget.value)
            setPage(1)
        }
    }

    const {loading, error, data } = useQuery<TagListResult>(TAGLIST_QUERY, {variables: {limit: limit, search: search, page: page}})
    if (loading) return <Loading />
    if (error) {
        console.log(error)
        return <div>Error</div>
    }
    return (
      <div className="flex flex-wrap -mx-4">
          <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
              <div className="flex justify-between px-6 -mb-px">
                  <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                  <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto justify-end">
                          <a href="#" className="block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase text-black hover:text-brand-500">New</a>
                      <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
                      </div>
                  </div>
              </div>
              <TagList tags={data.tags} {...props} />
              <Pagination paginated={data.tags} setPage={setPage} />
          </div>
      </div>
    )
}

export default List
