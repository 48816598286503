import React from 'react'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Routes, Route, Link, Outlet } from 'react-router-dom'
import Loading from '../loading'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import { Mixpanel } from '../../Common/Mixpanel'


export const COLLECTION_QUERY = gql`
query CollectionList {
    currentAccount{
        id
        textTemplateCollections {
            id
            title
            createdAt
        }
    }
}
`

export const CREATE_COLLECTION_MUTATION = gql`
mutation CreateCollection($title:String!) {
  createTextTemplateCollection(title:$title) {
    id
    title
    createdAt
  }
}
`

const Collections = () => {
  const {loading, error, data, refetch } = useQuery(COLLECTION_QUERY)

  const [createCollectionEntry, { data: createdData }] = useMutation(CREATE_COLLECTION_MUTATION);

  const createCollection = () => {
    const title = window.prompt('Title of collection', 'New text collection');
    if(!title) return;

    const result = createCollectionEntry({variables: {title: title}})

    console.log(result)
    result.then((done) => {
      Mixpanel.track("Saved text template collection", {title: title})
      console.log(done)
    }).catch(v => console.error(v))
  }


  if(createdData) refetch();

  if (error) {
    console.log(error)
    return <div>Error</div>
  }
  if (loading) return <Loading />

                    return (
              <div className="flex flex-wrap -mx-4">
                  <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
                      <div className="flex justify-between px-6 -mb-px">
                          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                              <div className="text-lg">
                                  <a href="#" onClick={() => createCollection() } className="block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase text-black hover:text-brand-500">New</a>
                              </div>
                          </div>

                      </div>
                      <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    {data && data.currentAccount.textTemplateCollections.map((collection, i) => {
                        return (
                          <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`collection-${collection.id}`}>
                              <div className="w-4/5 px-4 flex items-center">
                                  <Link to={`${collection.id}`} >
                                      <span className="text-lg">
                                          {collection.title}
                                      </span>
                                  </Link>
                              </div>
                          </div>
                        )
                    })}
                      </div>
                  </div>
              </div>
                    )
}


export default Collections
