import React from 'react'
import TimeButtons from './TimeButtons'
import { LineChart, PieChart, ColumnChart } from 'react-chartkick'

export default class ViewGraphs extends React.Component {

    render() {
        if(!this.props.list) {
            <span></span>
        }



        const data = this.props.list.data.reduce((acc, cur) => {
            acc[cur.key] = cur.value
            return acc
        }, {});


        console.log(window.Chartkick)

        return (
            <ColumnChart data={data} />
        )
    }
}
