import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { Routes, Route, Link, Outlet, useParams} from 'react-router-dom'
import {WorkflowInstanceConnections, WorkflowConnections, AccountConnections, WorkflowInstance} from '../../../types'
import {timeDistanceInWords} from '../../../helpers/TimeInWords'
import Loading from '../../loading'
import { InstanceListResult, INSTANCE_LIST } from './InstanceList/Query'

const Header = ({data, limit,  setLimit, account, setAccount, workflow, setWorkflow, status, setStatus}) => {

  return (
      <div className="flex flex-row">
        <fieldset className="w-2 m-4">
         <legend className="ml-2 text-[#999999] text-sm">
            Limit
          </legend>
        <input className="border border-solid border-gray-300 px-2 py-1 rounded" defaultValue={limit} onBlur={e => setLimit(parseInt(e.currentTarget.value, 10))} type="integer" />
      </fieldset>
    {data.accounts ?
        <fieldset className="w-2 m-4">
         <legend className="ml-2 text-[#999999] text-sm">
            Account
          </legend>
          <select className="form-select appearance-none border border-solid border-gray-300 bg-white" defaultValue={account} onChange={(e) => {setAccount(e.target.value)}}>
            <option value="all">all</option>
    {data.accounts.items.map(account => {
      return (
        <option value={account.id} key={account.id}>{account.title}</option>
      )
    })}
  </select>

</fieldset>
: <span />}
    {data.adminWorkflows ?
        <fieldset className="w-2 m-4">
         <legend className="ml-2 text-[#999999] text-sm">
            Workflow
          </legend>
          <select defaultValue={workflow} onChange={(e) => {setWorkflow(e.target.value)}}>
            <option value="all">all</option>
    {data.adminWorkflows.items.map(workflow => {
      return (
        <option value={workflow.id} key={workflow.id}>{workflow.title} {workflow.category ? `(${workflow.category.title})` : ""}</option>
      )
    })}
  </select>

</fieldset>
: <span />}
        <fieldset className="w-2 m-4">
         <legend className="ml-2 text-[#999999] text-sm">
            Status
          </legend>
          <select defaultValue={status} onChange={(e) => {setStatus(e.target.value)}}>
            <option value="all">all</option>
            <option value="UNKNOWN">UNKNOWN</option>
            <option value="STARTED">STARTED</option>
            <option value="FAILED_DATA">FAILED_DATA</option>
            <option value="RENDERING">RENDERING</option>
            <option value="RENDERING_FAILED">RENDERING_FAILED</option>
            <option value="SUBTITLING">SUBTITLING</option>
            <option value="CAPTIONFLOW">CAPTIONFLOW</option>
            <option value="READY">READY</option>
            <option value="PENDING_APPROVAL">PENDING_APPROVAL</option>
            <option value="APPROVED">APPROVED</option>
            <option value="REJECTED">REJECTED</option>
            <option value="PENDING_NOTIFICATION">PENDING_NOTIFICATION</option>
            <option value="NOTIFICATION_FAILED">NOTIFICATION_FAILED</option>
            <option value="DONE">DONE</option>
          </select>

</fieldset>
      </div>
    )
}

const InstanceLink = ({instance}) => {
  return (
    <Link to={`/admin/instance/${instance.id}`}>
      {instance?.videoWorkflow?.title} {instance?.videoWorkflow?.category?.title ? `(${instance?.videoWorkflow?.category?.title})` : ""}
    </Link>
  )
}

const InstanceEntry = ({instance}) => {
  return (
          <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4">
            <div className="w-1/5 px-4 flex items-center">
              <span className="text-lg">
                <InstanceLink instance={instance} />
              </span>
            </div>
            <div className="w-1/5 px-4">
              <div className="text-right text-grey">
                {instance?.videoWorkflow?.account?.title}
              </div>
            </div>
            <div className="w-1/5 px-4">
              <div className="text-right text-grey">
                {instance?.status}
              </div>
            </div>
            <div className="w-1/5 px-4">
              <div className="text-right text-grey">
                {instance?.rendering?.state}
              </div>
            </div>
            <div className="w-1/5 px-4">
              <div className="text-right text-grey">
                {timeDistanceInWords(instance.createdAt)}
              </div>
            </div>
          </div>
        )
}

const InstanceList = () => {
  const [limit, setLimit] = useState(50)
  const [account, setAccount] = useState(undefined as string)
  const [workflow, setWorkflow] = useState(undefined as string)
  const [status, setStatus] = useState(undefined as string)

  const vars = 
    { limit
    , accountId: account && account !== "all" ? account : undefined
    , workflowId: workflow && workflow != "all" ? workflow : undefined
    , statuses: status && status != "all" ? [status] : undefined
    }


  const {loading, error, data } = useQuery<InstanceListResult>(INSTANCE_LIST, {variables: vars, pollInterval: 5000, fetchPolicy: 'network-only'})


  if (loading) return <Loading />
  if (error) {
    console.log(error)
    return <div>Error</div>
  }

  return (
    <div>
      <Header data={data} limit={limit} setLimit={setLimit} status={status} workflow={workflow} account={account} setAccount={setAccount} setWorkflow={setWorkflow} setStatus={setStatus} />
    <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
      {data.adminWorkflowInstances.items.map((instance, i) => {
        return (
          <InstanceEntry instance={instance} key={instance.id} />
        )
      })}
    </div>
  </div>
  )
}


export default InstanceList
