import React, {useContext} from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import Loading from './loading'
import { UserConsumer } from '../stores/User'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { faDraftingCompass, faIndustry, faProjectDiagram, faChartBar} from '@fortawesome/free-solid-svg-icons'


const SectionDashboard = () => {
    return (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow group hover:bg-brand-500">
                <Link to="/design">
                    <div className="flex-1 flex flex-col p-8 group-hover:text-white">
                        <FontAwesomeIcon icon={faDraftingCompass} className="mx-auto" size="6x" />
                        <h3 className="mt-6 text-gray-900 group-hover:text-white text-sm leading-5 font-medium">Design</h3>
                        <div className="mt-1 flex-grow flex flex-col justify-between">
                            <span className="text-gray-500 group-hover:text-white text-sm leading-5">Design templates, players, landingpages and workflows</span>
                        </div>
                    </div>
                </Link>
            </li>

            <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow group hover:bg-brand-500">
                <Link to="/produce">
                    <div className="flex-1 flex flex-col p-8 group-hover:text-white">
                        <FontAwesomeIcon icon={faIndustry} className="mx-auto" size="6x" />
                        <h3 className="mt-6 text-gray-900 group-hover:text-white text-sm leading-5 font-medium">Produce</h3>
                        <div className="mt-1 flex-grow flex flex-col justify-between">
                            <span className="text-gray-500 group-hover:text-white text-sm leading-5">Produce videos</span>
                        </div>
                    </div>
                </Link>
            </li>

            <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow group hover:bg-brand-500">
                <Link to="/distribute">
                    <div className="flex-1 flex flex-col p-8 group-hover:text-white">
                        <FontAwesomeIcon icon={faProjectDiagram} className="mx-auto" size="6x" />
                        <h3 className="mt-6 text-gray-900 group-hover:text-white text-sm leading-5 font-medium">Distribute</h3>
                        <div className="mt-1 flex-grow flex flex-col justify-between">
                            <span className="text-gray-500 group-hover:text-white text-sm leading-5">Distribute videos</span>
                        </div>
                    </div>
                </Link>
            </li>

            <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow group hover:bg-brand-500">
                <Link to="/measure">
                    <div className="flex-1 flex flex-col p-8 group-hover:text-white">
                        <FontAwesomeIcon icon={faChartBar} className="mx-auto" size="6x" />
                        <h3 className="mt-6 text-gray-900 group-hover:text-white text-sm leading-5 font-medium">Measure</h3>
                        <div className="mt-1 flex-grow flex flex-col justify-between">
                            <span className="text-gray-500 group-hover:text-white text-sm leading-5">Measure, analyze and learn through stats</span>
                        </div>
                    </div>
                </Link>
            </li>


            </ul>
                        )
}

export default SectionDashboard
