export function timeDistanceInWords(time?: string) : string {
  if(!time) return ''
  const seconds = differenceInSeconds(now(), parseDate(time));
  return distanceInWords(seconds, true)
}

function parseDate(time: string) : Date {
  return new Date(time)
}

function now() : Date {
  return new Date()
}

function differenceInSeconds(now: Date, other: Date) : number {
  const seconds = (now.getTime() - other.getTime()) / 1000
  return seconds
}

export function distanceInWords (seconds: number, addTense: boolean = false) : string {
  let minutes = Math.floor(seconds / 60);
  let tense = seconds < 0 ? " from now" : " ago";
  if(!addTense) tense = ''
  minutes = Math.abs(minutes);
  if (minutes == 0) { return 'less than a minute'+tense; }
  if (minutes == 1) { return 'a minute'+tense; }
  if (minutes < 45) { return minutes + ' minutes'+tense; }
  if (minutes < 90) { return 'about an hour'+tense; }
  if (minutes < 1440) { return 'about ' + Math.floor(minutes / 60) + ' hours'+tense; }
  if (minutes < 2880) { return 'a day'+tense; }
  if (minutes < 43200) { return Math.floor(minutes / 1440) + ' days'+tense; }
  if (minutes < 86400) { return 'about a month'+tense; }
  if (minutes < 525960) { return Math.floor(minutes / 43200) + ' months'+tense; }
  if (minutes < 1051199) { return 'about a year'+tense; }

  return 'over ' + Math.floor(minutes / 525960) + ' years';
}

