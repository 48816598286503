import React, { useState, useRef } from 'react'
import { AccountConnections, Account } from '../../types'
import gql from 'graphql-tag'
import { useQuery, useMutation } from 'react-apollo'
import { Route, Link, useParams } from 'react-router-dom'
import Loading from '../loading'
import Editor from '../../player-builder/PlayerEditor'

//#region Queries
export const GET_ACCOUNT_INFO_QUERY = gql`
query AccountInfo($id:ID!){
    account(id:$id)
        {
        id,
        viewSettings,
        displayTitle,
        locale,
        title,
        playerId
        subscription
            {
            settings
            tier
                {
                id,
                title
                }
            }
        players {
            title, 
            id
            }
        }
    
  }
`

export const SET_ACCOUNT_INFO_MUTATION = gql`
mutation UpdateAccount2($id:ID!, $displayTitle:String, $playerId: ID, $locale:String, $viewSettings: JSON, $tier:AccountTierValue)
{updateAccount(id:$id, displayTitle:$displayTitle,playerId:$playerId,locale:$locale, viewSettings:$viewSettings, tier:$tier) 
  {viewSettings,
        displayTitle,
        locale,
        title,
        playerId
        subscription
            {
            settings
            tier
                {
                id,
                title
                }
            }
        }}
`

type Tier = 'Silver' | 'Gold' | 'Platinum'

//Object that is an account, and has extra properties
type EditAccount = Account & {
    tier: Tier
    viewSettings: string
    locale: string
    displayTitle: string
    playerId: string
}


//#endregion

const Edit = () => {
    
    let localData = useRef<EditAccount>(null);

    /**
     * Updates the current localdata
     * @param field 
     * @param value 
     */
    const updateData = (field: string, value?: any) => {
        localData.current[field] = value
    }

    //Gets the AccountId from the url
    const { accountId } = useParams();

    //mutation for saving the changes
    const [saveChanges, saveResult] = useMutation(SET_ACCOUNT_INFO_MUTATION)


    //load data from the database with the gql query
    const { loading, data, error, refetch } = useQuery(GET_ACCOUNT_INFO_QUERY, { variables: { "id": accountId } });


    //if the channel is undefined, and we have the data...
    if (! localData.current && data) {
        localData.current = data.account;
        localData.current['prevJSON'] = data.account.viewSettings;

    }


    /**
   * Calls the mutation and saves the changes in the database
   */
    const save = () => {
       
        //check if the json is formatted correctly before trying to mutate the data in the database
        try{
            JSON.parse(localData.current.viewSettings)
        }
        catch
        {
            //Check if the user has emptied the editor, and presumably wants to reset the viewSettings
            if(localData.current.viewSettings === "")
            {
                //Then set it to an empty object
                localData.current.viewSettings = '{}'
            }
            else
            {
                //Wrong format
                alert("viewSettings not saved due to possible incorrect format")
                localData.current.viewSettings = localData.current['prevJSON']
            }
        }

       const result = saveChanges({ variables: localData.current })


        result.then((done) => {

            console.log(data.channel)

        }).catch(v => console.error(v)).finally(() => {
            //when its done writing to the database, refetch the data
            refetch().then(({ data }) => {
                console.log('Refetch is done!', data);

                localData.current = data.account;
                localData.current['prevJSON'] = data.account.viewSettings;
            });
        });
    }

    //check if the data is loaded
    if (loading && ! localData.current) return <Loading />
    if (error) {
        console.log(error)
        return <div>Error</div>
    }
    //only display the components if the localdata object has the required fields    
    if (! localData.current) return <span></span>

    return (

        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <h1>Edit Account</h1>
            <TextInput id='displayTitle' label='DisplayTitle' defaultValue={localData.current.displayTitle? localData.current.displayTitle: '[same as title]'} onChange={(evt) => {updateData('displayTitle', evt.currentTarget.value)}} ></TextInput>
            <SelectInput id="tier" label="Tier" defaultValue={localData.current.subscription?.tier.title} options={["Gold", "Silver", "Platinum"]} allowEmpty={false} onChange={(evt) => {updateData('tier', evt.currentTarget.value)}} />
            <SelectInput id="locale" label="Locale" defaultValue={localData.current.locale} options={["English", "Danish"]} allowEmpty={true} emptyOption='[default]' onChange={(evt) => {updateData('locale', evt.currentTarget.value)}} />
            <SelectInput id="playerId" label="Player" defaultValue={localData.current.playerId} options={data.account.players} allowEmpty={true} onChange={(evt) => {updateData('playerId', evt.currentTarget.value)}} />
            <DisplayJSON label="ViewSettings" json={localData.current.viewSettings} onChange={(value) => {updateData('viewSettings', value)}}></DisplayJSON>
            <button onClick={save} className="mt-8 bg-brand-500 hover:bg-brand-300 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline">{saveResult && saveResult.loading ? "Saving...." : "Save"}</button>
        </div>

    )
}
        


/**
 * Editor component for displaying and edititing JSON objects
 * @param {string} label - The label for the editor
 * @param {Function} onChange - The function to be called when the text editors value changes.
 * @param {string} json - JSON object that will be displayed and edited in the editor
 * @returns {JSX.Element} A JSX element representing the DisplayJSON component.
 */
const DisplayJSON = ({label, onChange, json}) => {
   
    let jsonData = json;

    json === null?  jsonData = "{}": jsonData = json;

    return (
        <div className="sm:grid sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <h2 className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</h2>
            <Editor mode="json" update={onChange} value={JSON.stringify(JSON.parse(jsonData), null, '\t')} />
        </div>
    )

}


/**
 * Text input component for capturing user input.
 * NOTE: This is copied from javascript/dashboard/video/edit.tsx
 * @param {string} id - The ID of the text input.
 * @param {string} label - The label for the text input.
 * @param {string} defaultValue - The default value for the text input.
 * @param {Function} onChange - The function to be called when the text input value changes.
 * @returns {JSX.Element} A JSX element representing the TextInput component.
 */
const TextInput = ({id, label, defaultValue, onChange }) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <input id={id} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={defaultValue} onChange={onChange} />
                </div>
            </div>
        </div>
    )
}

/**
* Select input component for choosing options from a dropdown list.
* NOTE: This is copied from javascript/dashboard/video/edit.tsx
* @param {string} id - The ID of the dropdown list.
* @param {string} label - The label for the dropdown list.
* @param {string} defaultValue - The default value for the dropdown list.
* @param {Array} options - An array of objects representing the selectable options.
* @param {boolean} allowEmpty - A boolean indicating whether an empty option is allowed.
* @param {Function} onChange - The function to be called when the dropdown list value changes.
* @param {string} emptyOption - The text for the empty option (default: '[none]').
* @returns {JSX.Element} A JSX element representing the SelectInput component.
*/
const SelectInput = ({ id, label, defaultValue, options, allowEmpty, onChange, emptyOption = '[none]' }) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <select id={id} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={defaultValue} onChange={onChange}>
                        {allowEmpty ? <option value={undefined} key="empty">{emptyOption}</option> : <></>}

                        {options.map((item, index) => {

                            if (item.id) {
                                return <option value={item.id} key={item.id}>{item.title}</option>
                            }
                            else {
                                return <option value={item} key={index}>{item}</option>
                            }
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}



export default Edit
