import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'

const Edit = () => {
    return (
        <span>Edit</span>
    )
}


export default Edit
