import React from 'react'

const Edit = () => {
    return (
        <span>Edit</span>
    )
}


export default Edit
