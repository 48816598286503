import React, {useContext} from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { useNavigate, useLocation } from 'react-router-dom'
import Loading from '../loading'
import DesignWorkflow from './design/workflow'

export const ACTION_QUERY = gql`
query Action {
    tokenPayload
    currentUser {
        settings
    }
}
`


const Action = () => {
    let navigate = useNavigate();
    let location = useLocation();

    const { loading, data, error } = useQuery(ACTION_QUERY);

    if(data) {
        console.log(data)
        const payload = JSON.parse(data.tokenPayload)
        if(payload.ui_language) {
            window?.localStorage?.setItem('App.locale', payload.ui_language)
        }
        if(payload.url) {
            const payloadUrl =  payload.url.replace('action/caption', 'dashboard/captionflow')
            const newUrl = payloadUrl + (payload.use_type == 'external'  ? '?realtor' : '')

            return navigate(newUrl)
        } else if(payload.message) {
            return <h1>{payload.message}</h1>
        } else{
            return <h1>ACTION NOT ALLOWED</h1>
        }
    } else{
        return <span />
    }

}

export default Action
