import React, {useContext} from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import Loading from './loading'
import { UserConsumer } from '../stores/User'
import StatsDashboard from './StatsDashboard'
import SectionDashboard from './SectionDashboard'
import AdminDashboard from './sections/Admin'
import { useNavigate } from 'react-router-dom'





const Dashboards = {
    stats: StatsDashboard,
    section: SectionDashboard,
    admin: AdminDashboard,
    default: StatsDashboard,
}


const MainDashboard = () => {
    let navigate = useNavigate()
    return (
        <UserConsumer>
            { user => {
                let Component;
                if(user) {
                    if(user.settings.main_section) {
                        return navigate(user.settings.main_section)
                    }
                    if(user.settings.dashboard && user.settings.dashboard.component && Dashboards[user.settings.dashboard.component]) {
                        Component = Dashboards[user.settings.dashboard.component]

                    } else {
                        Component = Dashboards.default
                    }
                } else {
                    Component = Dashboards.default
                }
                return (<Component />)
            }}
        </UserConsumer>
    )
}

export default MainDashboard
