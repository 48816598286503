import React, { useState, useContext, useEffect } from "react";
import { Query, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Route, Link, useParams, useNavigate } from "react-router-dom";
import Versions from "../../Versions";
import Loading from "../../loading";
import WorkflowFields from "./workflow/fields";
import WorkflowSteps from "./workflow/steps";
import WorkflowTemplate from "./workflow/template";
import WorkflowCategory from "./workflow/category";
import WorkflowTeam from "./workflow/team";
import { AssetUpload } from "../../Video/Upload";
import { Mixpanel } from "../../../Common/Mixpanel";
import WorkflowInternal from "./workflow/internal";
import TouchpointCombobox from "./workflow/touchpoint";

export const WORKFLOW_DESIGN_QUERY = gql`
  query Workflow($id: ID) {
    workflow(id: $id) {
      id
      title
      iconUrl
      internal
      sorting
      team {
        id
      }
      category {
        id
      }
      previewUrl
      screenshotUrl
      fieldSettings
      touchpoint {
        id
        title
      }
      template {
        id
        name
        settings
      }
      appSteps
      steps {
        id
        title
        settings
        worker {
          id
          stepName
          title
          workerName
        }
      }
      versions {
        id
        event
        createdAt
        whodunnit {
          id
          email
        }
      }
    }
  }
`;

export const CLONE_WORKFLOW_MUTATION = gql`
  mutation cloneWorkflow($id: String!, $title: String!) {
    workflow: cloneWorkflow(sourceId: $id, title: $title) {
      id
      title
      iconUrl
      internal
      sorting
      team {
        id
      }
      category {
        id
      }
      previewUrl
      fieldSettings
      template {
        id
        name
        settings
      }
      steps {
        id
        title
        settings
        worker {
          id
          stepName
          title
          workerName
        }
      }
    }
  }
`;

export const UPDATE_WORKFLOW_MUTATION = gql`
  mutation updateWorkflow(
    $id: String!
    $title: String
    $steps: [WorkflowStepInput!]
    $appSteps: JSON
    $fieldSettings: JSON
    $templateId: String
    $iconUrl: String
    $previewUrl: String
    $screenshotUrl: String
    $categoryId: String
    $teamId: String
    $autopilot_touchpoint_id: String
    $internal: Boolean
  ) {
    workflow: updateWorkflow(
      id: $id
      title: $title
      steps: $steps
      fieldSettings: $fieldSettings
      appSteps: $appSteps
      templateId: $templateId
      iconUrl: $iconUrl
      previewUrl: $previewUrl
      screenshotUrl: $screenshotUrl
      categoryId: $categoryId
      teamId: $teamId
      autopilotTouchpointId: $autopilot_touchpoint_id
      internal: $internal
    ) {
      id
      title
      iconUrl
      internal
      sorting
      team {
        id
      }
      category {
        id
      }
      previewUrl
      screenshotUrl
      fieldSettings
      touchpoint {
        id
        title
      }
      template {
        id
        name
        settings
      }
      appSteps
      steps {
        id
        title
        settings
        worker {
          id
          stepName
          title
          workerName
        }
      }
      versions {
        id
        event
        createdAt
        whodunnit {
          id
          email
        }
      }
    }
  }
`;
const ROLL_BACK_WORKFLOW_MUTATION = gql`
  mutation rollBackWorkflow($workflowId: ID!, $versionId: ID!) {
    rollBackWorkflow(workflowId: $workflowId, versionId: $versionId) {
      title
    }
  }
`;

const WorkflowDesigner = ({
  saveSteps,
  saveFields,
  saveStepsObject,
  saveTemplate,
  saveTitle,
  setIcon,
  setScreenshot,
  setPreview,
  saveWorkflow,
  workflow,
  title,
  template,
  icon,
  preview,
  screenshot,
  team,
  saveTeam,
  internal,
  saveInternal,
  category,
  saveCategory,
  touchPoint,
  saveTouchpoint,
  onCloneClicked,
  workflowVersions,
}) => {
  const activeIcon = icon || workflow.iconUrl;
  const activePreview = preview || workflow.previewUrl;
  const activeScreenshot = screenshot || workflow.screenshotUrl;

  const [uploadIcon, setUploadIcon] = useState(false);
  const [uploadPreview, setUploadPreview] = useState(false);
  const [uploadScreenshot, setUploadScreenshot] = useState(false);

  const onUploadIcon = (url) => {
    setIcon(url);
    setUploadIcon(false);
  };
  const onUploadPreview = (url) => {
    setPreview(url);
    setUploadPreview(false);
  };

  const onUploadScreenshot = (url) => {
    setScreenshot(url);
    setUploadScreenshot(false);
  };


  return (
    <>
      <h1 className="text-3xl text-black pb-4">Design Workflow</h1>
      <input
        className="text-2xl text-black pb-2 mt-4"
        defaultValue={title || workflow.title}
        onChange={(evt) => {
          saveTitle(evt.currentTarget.value);
        }}
      />
      <button
        onClick={() => onCloneClicked(workflow)}
        className="bg-brand-500 hover:bg-blue-700 text-white font-bold w-1/6 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Clone
      </button>
      <div>
        {uploadIcon ? (
          <>
            <AssetUpload onUpload={onUploadIcon} />{" "}
            <span
              onClick={() => setUploadIcon(!uploadIcon)}
              className="p-4 m-4 text-sm"
            >
              cancel
            </span>
          </>
        ) : (
          <div onClick={() => setUploadIcon(!uploadIcon)}>
            {activeIcon ? (
              <img src={activeIcon} className="w-16 h-16 p-2 mr-2" />
            ) : (
              <span>No icon</span>
            )}
          </div>
        )}
      </div>
      <div>
        {uploadPreview ? (
          <>
            <AssetUpload onUpload={onUploadPreview} />{" "}
            <span
              onClick={() => setUploadPreview(!uploadPreview)}
              className="p-4 m-4 text-sm"
            >
              cancel
            </span>
          </>
        ) : (
          <div onClick={() => setUploadPreview(!uploadPreview)}>
            {activePreview ? (
              <span>
                Preview uploaded{" "}
                <a href={activePreview} target="_blank">
                  see here
                </a>
              </span>
            ) : (
              <span>No preview</span>
            )}
          </div>
        )}
      </div>
      <div>
        {uploadScreenshot ? (
          <>
            <AssetUpload onUpload={onUploadScreenshot} />{" "}
            <span
              onClick={() => setUploadScreenshot(!uploadScreenshot)}
              className="p-4 m-4 text-sm"
            >
              cancel
            </span>
          </>
        ) : (
          <div onClick={() => setUploadScreenshot(!uploadScreenshot)}>
            {activeScreenshot ? (
              <span>
                Screenshot uploaded{" "}
                <a href={activeScreenshot} target="_blank">
                  see here
                </a>
              </span>
            ) : (
              <span>No screenshot</span>
            )}
          </div>
        )}
      </div>
      <WorkflowCategory category={category} saveCategory={saveCategory} />
      <WorkflowTeam team={team} saveTeam={saveTeam} />
      <WorkflowInternal internal={internal} saveInternal={saveInternal} />
      <WorkflowTemplate
        template={workflow.template}
        saveTemplate={saveTemplate}
      />
      <TouchpointCombobox
        touchpoint={touchPoint}
        saveTouchpoint={saveTouchpoint}
      ></TouchpointCombobox>
      <Versions
        versions={workflowVersions}
        ROLL_BACK_MUTATION={ROLL_BACK_WORKFLOW_MUTATION}
        itemID={workflow.id}
      ></Versions>
      <WorkflowSteps
        steps={workflow.steps}
        saveSteps={saveSteps}
        stepsObject={workflow.appSteps}
        saveStepsObject={saveStepsObject}
      />
      <WorkflowFields
        fieldSettings={workflow.fieldSettings}
        saveFields={saveFields}
      />
      <hr className="my-4" />
      <button
        onClick={() => saveWorkflow(workflow)}
        className="bg-brand-500 hover:bg-blue-700 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Save
      </button>
    </>
  );
};

const DesignWorkflow = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [fields, setFields] = useState(false);
  const [steps, setSteps] = useState(false);
  const [stepsObject, setStepsObject] = useState(false);
  const [title, setTitle] = useState(false);
  const [icon, setIcon] = useState(undefined);
  const [preview, setPreview] = useState(undefined);
  const [screenshot, setScreenshot] = useState(undefined);
  const [template, setTemplate] = useState(false);
  const [team, setTeam] = useState(false);
  const [internal, setInternal] = useState(undefined);
  const [category, setCategory] = useState(false);
  const [touchPoint, setTouchpoint] = useState(false);

  const saveFields = (newFields) => {
    setFields(newFields);
  };

  const saveSteps = (newSteps) => {
    setSteps(newSteps);
  };

  const saveStepsObject = (newStepsObject) => {
    setStepsObject(newStepsObject);
  };

  const saveTemplate = (newTemplate) => {
    setTemplate(newTemplate);
  };

  const saveTitle = (newTitle) => {
    setTitle(newTitle);
  };

  const saveTeam = (newTeam) => {
    setTeam(newTeam);
  };

  const saveInternal = (newInternal) => {
    setInternal(newInternal);
  };

  const saveCategory = (newCategory) => {
    setCategory(newCategory);
  };

  const saveTouchpoint = (newTouchpoint) => {
    setTouchpoint(newTouchpoint);
  };

  const [updateWorkflow, { data }] = useMutation(UPDATE_WORKFLOW_MUTATION);

  const [cloneWorkflow, { clonedData }] = useMutation(CLONE_WORKFLOW_MUTATION);

  const mapSteps = (steps) => {
    return steps.map((step) => {
      const rVal = {
        workerId: step.worker.id,
      };

      if (step.title) rVal["title"] = step.title;
      if (step.settings) rVal["settings"] = step.settings;
      if (step.id) rVal["id"] = step.id;

      return rVal;
    });
  };

  const cloneClicked = (existingWorkflow) => {
    const title = prompt("Workflow title");
    if (title != null) {
      const vars = {
        id: existingWorkflow.id,
        title,
      };

      const result = cloneWorkflow({ variables: vars });
      result
        .then((done) => {
          Mixpanel.track("Cloned workflow", { title: title });
          console.log(done);
          return navigate(`/design/workflows/${done.data.workflow.id}`);
        })
        .catch((v) => console.error(v));
    }
  };

  const saveWorkflow = (existingWorkflow) => {
    const newFields = JSON.stringify(fields) || existingWorkflow.fieldSettings;
    const newSteps = steps || existingWorkflow.steps;
    const newStepsObject = stepsObject || existingWorkflow.appSteps;
    const newTitle = title || existingWorkflow.title;
    const newIcon = icon || existingWorkflow.iconUrl;
    const newPreview = preview || existingWorkflow.previewUrl;
    const newScreenshot = screenshot || existingWorkflow.screenshotUrl;
    const newTemplate = template ? template.id : false;
    const newTeam = team ? team.id : false;
    const newCategory = category ? category.id : false;
    const newTouchpoint = touchPoint ? touchPoint.id : false;
    const id = existingWorkflow.id;

    const vars = {
      id: id,
      title: newTitle,
      steps: mapSteps(newSteps),
      fieldSettings: newFields,
      iconUrl: newIcon,
      previewUrl: newPreview,
      appSteps: newStepsObject,
      screenshotUrl: newScreenshot,
      internal: internal,
    };

    if (newTemplate) vars["templateId"] = newTemplate;
    if (newTeam) vars["teamId"] = newTeam;
    if (newCategory) vars["categoryId"] = newCategory;
    if (newTouchpoint) vars["autopilot_touchpoint_id"] = newTouchpoint;

    Mixpanel.track("Saving workflow", { id: id, title: newTitle });

    const result = updateWorkflow({ variables: vars });

    result
      .then((done) => {
        Mixpanel.track("Saved workflow", { id: id, title: newTitle });
      })
      .catch((v) => console.error(v));
  };

  if (clonedData) {
    return navigate(`/design/workflows/${clonedData.cloneWorkflow.id}`);
  }

  if (data) {
    return (
      <WorkflowDesigner
        saveSteps={saveSteps}
        saveStepsObject={saveStepsObject}
        saveFields={saveFields}
        saveTemplate={saveTemplate}
        saveWorkflow={saveWorkflow}
        workflow={data.workflow}
        title={title}
        template={data.workflow.template}
        workflowVersions={data.workflow.versions}
        touchPoint={data.workflow.touchpoint}
        saveTitle={saveTitle}
        setIcon={setIcon}
        setPreview={setPreview}
        setScreenshot={setScreenshot}
        screenshot={screenshot}
        icon={icon}
        preview={preview}
        team={data.workflow.team}
        saveTeam={saveTeam}
        internal={data.workflow.internal}
        saveInternal={saveInternal}
        category={data.workflow.category}
        saveCategory={saveCategory}
        saveTouchpoint={saveTouchpoint}
        onCloneClicked={cloneClicked}
      />
    );
  }

  return (
    <Query query={WORKFLOW_DESIGN_QUERY} variables={{ id: params.workflowId }}>
      {({ loading, error, data, subscribeToMore }) => {
        if (loading) return <Loading />;
        if (error) {
          console.log(error);
          return <div>Error</div>;
        }
        return (
          <WorkflowDesigner
            saveSteps={saveSteps}
            saveStepsObject={saveStepsObject}
            saveFields={saveFields}
            saveTemplate={saveTemplate}
            saveWorkflow={saveWorkflow}
            workflow={data.workflow}
            title={title}
            workflowVersions={data.workflow.versions}
            template={data.workflow.template}
            touchPoint={data.workflow.touchpoint}
            saveTitle={saveTitle}
            setIcon={setIcon}
            setPreview={setPreview}
            setScreenshot={setScreenshot}
            screenshot={screenshot}
            icon={icon}
            preview={preview}
            team={data.workflow.team}
            saveTeam={saveTeam}
            internal={data.workflow.internal}
            saveInternal={saveInternal}
            category={data.workflow.category}
            saveCategory={saveCategory}
            saveTouchpoint={saveTouchpoint}
            onCloneClicked={cloneClicked}
          />
        );
      }}
    </Query>
  );
};

export default DesignWorkflow;
