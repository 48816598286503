import React, { useState } from 'react'
import { Query, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useParams} from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import InstanceForm from './InstanceForm'
import Versions from '../Versions'
import { Mixpanel } from '../../Common/Mixpanel'

export const WORKFLOWSHOW_QUERY = gql`
query WorkflowShow($id:ID!) {
    workflow(id:$id) {
        id
        title
        createdAt
        fieldSettings
        instances(limit:20) {
            items {
                id
                createdAt
                data
                status
                user {
                    name
                    username
                    email
                }
                viewable {
                    id
                    title
                    publicPoster
                }
                rendering {
                    id
                    createdAt
                    state
                    template {
                        id
                        name
                    }
                  
                }
            }
        }
        
    }
}
`


export const CREATE_WORKFLOW_INSTANCE = gql`
mutation createInstance($id:String!, $data:JSON!) {
  addWorkflowInstance(workflowId:$id, data:$data) {
    id
  }
}
`

const ShowUser = ({name, username, email}) => {
    if(name) {
        return <span>{name}</span>
    }
    if(username) {
        return <span>{username}</span>
    }
    if(email) {
        return <span>{email}</span>
    }
    return <span />
}

const Show = () => {
    let params = useParams()
  const vars = {id: params.workflowId}
  const [latestMutation, setLatestMutation] = useState(undefined)
  const [createInstance, { mutationData }] = useMutation(CREATE_WORKFLOW_INSTANCE);

    const extract_last_error = (data) => {
        if(data) {
            try{
                const parsedData = JSON.parse(data)
                const parsedError = parsedData && parsedData.last_error ? parsedData.last_error : undefined
                return parsedError
            } catch(err) {
                console.error(err)
            }
        }

        return undefined
    }
    return (
        <Query fetchPolicy="no-cache" query={WORKFLOWSHOW_QUERY} variables={vars}>
            {({ loading, error, data,  refetch  }) => {
                if (loading) return <Loading />
                    if (error) {
                        console.log(error)
                        return <div>Error</div>
                    }
              if(mutationData && mutationData.called && !mutationData.loading && mutationData.data && mutationData.data.addWorkflowInstance.id != latestMuation) {
                console.log(mutationData.addWorkflowInstance.data)
                setLatestMutation(mutationData.data.id)
                refetch()
              }
                console.log(data)
                return (
                    <div>
                         
                        <span className="text-xl">{data.workflow.title}</span>
                        {data.workflow.fieldSettings ? <InstanceForm fieldSettings={data.workflow.fieldSettings} workflowId={params.workflowId} createInstance={createInstance} /> : <></>}
                        <div className="p-4">
                            {data.workflow.instances.items.map((instance) => {
                                const last_error = extract_last_error(instance.data)
                                return (
                                <div className="mt-4 border-b flex" key={instance.id}>
                                    <span className="w-1/6 text-xl">
                                      <Link to={`/dashboard/workflows/${params.workflowId}/instance/${instance.id}`} >
                                        {instance.status} : {timeDistanceInWords(instance.createdAt)}
                                      </Link>
                                    </span>
                                    <span className="w-1/6 text-xl">
                                        <ShowUser {...instance.user} />
                                    </span>
                                    {instance.rendering ?
                                    (
                                    <div className="w-1/3">
                                      <Link to={`/dashboard/renderings/${instance.rendering.id}`} >
                                        <h1 className="text-xl">Rendering</h1>
                                        <h2 className="text-lg">Template: {instance.rendering.template.name}</h2>
                                        <span className="text-xs">State: {instance.rendering.state}</span>
                                        </Link>
                                    </div>
                                    ) : last_error ? <div className="w-1/3 text-booboo text-xs">Last error: {last_error}</div> : <span />}


                                    {instance.viewable ?
                                    (
                                    <div className="w-1/3">
                                        <h1 className="text-xl">{instance.viewable.title}</h1>
                                  <Link to={`/dashboard/videos/${instance.viewable.id}`} >
                                    <img className="text-right text-grey w-1/2 py-4" src={instance.viewable.publicPoster} />
                                  </Link>
                                    </div>
                                    ) : <span />}

                                </div>
                                )
                            })}                           
                        </div>
                    </div>
                )}}
                    </Query>

            )
}

export default Show
