import React, { useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Route, Link, useParams } from "react-router-dom";
import { timeDistanceInWords } from "../../helpers/TimeInWords";
import Loading from "../loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faEdit,
  faTrash,
  faV,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal";
import Pagination from "../../Pagination";
import { User, Team, Role } from "../../types";

interface TeamData {
  team: Team;
  roles: Role[];
}

export const TEAM_QUERY = gql`
  query Team($id: ID!) {
    team(id: $id) {
      id
      title
      displayTitle
      createdAt
      extraData
      socialEnabled
      users {
        id
        email
        name
        createdAt
        lastSignInAt
        isAdmin
        imageUrl
        instanceCount
        appVersion
        deviceName
        osVersion
        level {
          title
        }
      }
      teamRoles {
        id
        createdAt
        options
        user {
          id
          email
          name
          createdAt
        }
        role {
          id
        }
      }
    }
    roles {
      id
      title
      abbreviation
      createdAt
      options
    }
  }
`;

export const SET_TEAM_ROLE_MUTATION = gql`
  mutation SetTeamRole($teamId: ID!, $roleId: ID!, $userId: ID!) {
    setTeamRole(teamId: $teamId, roleId: $roleId, userId: $userId) {
      id
    }
  }
`;

export const REMOVE_TEAM_ROLE_MUTATION = gql`
  mutation RemoveTeamRole($id: ID!) {
    removeTeamRole(teamRoleId: $id) {
      id
    }
  }
`;

export const ONBOARD_TEAM_MUTATION = gql`
  mutation OnboardTeam($id: ID!) {
    onboardTeam(id: $id) {
      id
      title
      displayTitle
      createdAt
      extraData
      socialEnabled
    }
  }
`;

export const SYNC_TEAM_MUTATION = gql`
  mutation SyncTeam($id: ID!) {
    syncTeam(id: $id) {
      id
      title
      displayTitle
      createdAt
      extraData
      socialEnabled
    }
  }
`;

const TeamDetails = ({ team }) => {
  const extraData = JSON.parse(team.extraData) || {};

  let password = "";
  let email = "";

  if (
    extraData["estate_family_details"] &&
    extraData["estate_family_details"]["data"]
  ) {
    password = extraData["estate_family_details"]["data"]["generatedPassword"];
    email = extraData["email"];
  }

  return (
    <div>
      <div className="flex items-center">
        <div className="text-lg font-bold">Social:&nbsp;</div>
        <div className="text-lg font-light">
          {team.socialEnabled ? "Enabled" : "Disabled"}
        </div>
      </div>
      {password != "" ? (
        <div className="flex flex-col">
          <div className="text-lg font-bold flex">Email:</div>
          <div className="text-lg font-light flex">{email}</div>
          <div className="text-lg font-bold flex">Password:</div>
          <div className="text-lg font-light flex"> {password} </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const Team = () => {
  const params = useParams();

  const [onboardMutation, onboardData] = useMutation(ONBOARD_TEAM_MUTATION);
  const [syncMutation, syncData] = useMutation(SYNC_TEAM_MUTATION);
  const [setTeamRole] = useMutation(SET_TEAM_ROLE_MUTATION);
  const [removeTeamRole] = useMutation(REMOVE_TEAM_ROLE_MUTATION);

  const { loading, error, data, refetch } = useQuery<TeamData>(TEAM_QUERY, {
    variables: { id: params.teamId },
  });

  const saveTeamRole = (roleId, userId) => {
    const result = setTeamRole({
      variables: { teamId: params.teamId, roleId, userId },
    });
    result
      .then((done) => {
        refetch();
      })
      .catch((v) => console.error(v));
  };

  const removeRoleFromUser = (id) => {
    const result = removeTeamRole({ variables: { id } });

    result
      .then((done) => {
        refetch();
      })
      .catch((v) => console.error(v));
  };

  const onboardTeam = (id) => {
    const result = onboardMutation({ variables: { id } });
    result
      .then((done) => {
        refetch();
      })
      .catch((v) => console.error(v));
  };

  const syncTeam = (id) => {
    const result = syncMutation({ variables: { id } });
    result
      .then((done) => {
        refetch();
      })
      .catch((v) => console.error(v));
  };

  if (loading) return <Loading />;

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  console.log(data);

  const team = data.team;

  return (
    <div>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h1>Edit team</h1>
        <TextInput
          id="title"
          label="Title"
          defaultValue={team.title}
          onChange={() => {
            console.log("changed");
          }}
        ></TextInput>
        <TextInput
          id="displayTitle"
          label="Display Title"
          defaultValue={team.displayTitle}
          onChange={() => {
            console.log("changed");
          }}
        ></TextInput>
        <WrapField id="social" label="Social">
          {team.socialEnabled ? (
            <button onClick={() => syncTeam(team.id)}>Sync</button>
          ) : (
            <button onClick={() => onboardTeam(team.id)}>Onboard</button>
          )}
        </WrapField>
        <WrapField id="details" label="Social details">
          <TeamDetails team={team}></TeamDetails>
        </WrapField>

        <div>
          {team.users ? (
            team.users.map((user, i) => {
              return (
                <div
                  className="flex-grow flex px-4 py-2 text-grey-700 items-center border-b -mx-2"
                  key={`user-${user.id}`}
                >
                  <div className="w-1/12 px-2 flex items-center">
                    <span className="text-lg">
                      {user.imageUrl ? (
                        <Link to={`${user.id}`}>
                          <img src={user.imageUrl} className="w-32 p-2 mr-2" />
                        </Link>
                      ) : (
                        <span />
                      )}
                    </span>
                  </div>
                  <div className="w-1/6 px-2 flex items-center">
                    <span className="text-lg">
                      <Link to={`${user.id}`}>{user.name}</Link>
                    </span>
                  </div>
                  <div className="w-1/6 px-2 flex items-center">
                    <span className="text-lg">
                      <Link to={`${user.id}`}>{user.email}</Link>
                    </span>
                  </div>
                  <div className="w-1/12 px-2 items-center">
                    <div className="text-center text-grey">
                      {user?.level?.title}
                    </div>
                  </div>
                  <div className="w-2/12 px-2 items-center">
                    <div className="text-center text-grey">
                      {user?.instanceCount || 0} produced
                    </div>
                  </div>
                  <div className="w-1/6 px-2">
                    <div className="text-right text-grey">
                      {user.isAdmin ? <div>Admin: yes</div> : <span />}
                      Device: {user.deviceName}
                      <br />
                      Os Version: {user.osVersion}
                      <br />
                      App Version: {user.appVersion}
                      <br />
                    </div>
                  </div>
                  <div className="w-2/12 px-2">
                    <div className="text-right text-grey">
                      <>
                        {data.roles.map((role, i) => {
                          let isCurrentUserActive = false;
                          let activeId = undefined;
                          team.teamRoles.forEach((teamRole, n) => {
                            if (
                              teamRole.role.id === role.id &&
                              teamRole.user.id === user.id
                            ) {
                              isCurrentUserActive = true;
                              activeId = teamRole.id;
                            }
                          });

                          return isCurrentUserActive ? (
                            <button
                              key={`role-${i}`}
                              className="mt-1 bg-brand-500 hover:bg-brand-300 font-bold w-full py-1 px-2 rounded focus:outline-none focus:shadow-outline text-green-500"
                              onClick={() => {
                                removeRoleFromUser(activeId);
                              }}
                            >
                              Disable {role.title} role
                            </button>
                          ) : (
                            <button
                              key={`role-${i}`}
                              className="mt-1 bg-brand-500 hover:bg-brand-300 font-bold w-full py-1 px-2 rounded focus:outline-none focus:shadow-outline text-red-500"
                              onClick={() => {
                                saveTeamRole(role.id, user.id);
                              }}
                            >
                              Enable {role.title} role
                            </button>
                          );
                        })}
                      </>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No users</div>
          )}
        </div>

        {/* <button
          onClick={save}
          className="mt-8 bg-brand-500 hover:bg-brand-300 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {saveResult && saveResult.loading ? "Saving...." : "Save"}
        </button> */}
      </div>
    </div>
  );
};

const WrapField = ({ id, label, children }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
          {children}
        </div>
      </div>
    </div>
  );
};

/**
 * Checkbox component for toggling a specific option.
 * @param {string} id - The ID of the checkbox.
 * @param {string} label - The label for the checkbox.
 * @param {boolean} checked - The initial checked state of the checkbox.
 * @param {Function} onChange - The function to be called when the checkbox state changes.
 * @returns {JSX.Element} A JSX element representing the Checkbox component.
 */
const Checkbox = ({ id, label, checked, onChange }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            checked={checked}
            onChange={(evt) => {
              onChange && onChange(evt.currentTarget.checked);
            }}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Text input component for capturing user input.
 * NOTE: This is copied from javascript/dashboard/video/edit.tsx
 * @param {string} id - The ID of the text input.
 * @param {string} label - The label for the text input.
 * @param {string} defaultValue - The default value for the text input.
 * @param {Function} onChange - The function to be called when the text input value changes.
 * @returns {JSX.Element} A JSX element representing the TextInput component.
 */
const TextInput = ({ id, label, defaultValue, onChange }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
          <input
            id={id}
            className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            defaultValue={defaultValue}
            onChange={(evt) => {
              onChange && onChange(evt.currentTarget.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Team;
