import React, {useCallback, useEffect, useState} from 'react'
import { Query, useMutation } from 'react-apollo'
import {roundedPercentString} from '../../helpers/PercentHelper'
import gql from 'graphql-tag'
import {useDropzone} from 'react-dropzone'
import Loading from '../loading'
import axios from 'axios'

export const UPLOAD_ASSET = gql`
    mutation token {
        createUploadToken(uploadType:Asset){
            id
            fields
            url
            uploadedUrl
        }
    }
`

export const UPLOAD_COMP = gql`
    mutation token {
        createUploadToken(uploadType:Composition){
            id
            fields
            url
            uploadedUrl
        }
    }
`

export const UPLOAD_NEW_ENTITY = gql`
    mutation token {
        createUploadToken(uploadType:Video){
            id
            fields
            url
        }
    }
`

export const UPLOAD_EXISTING_ENTITY = gql`
    mutation token($id:String) {
        createUploadToken(uploadType:Video, id: $id){
            id
            fields
            url
        }
    }
`

export const Dropzone = ({url, fields, onUpload, onProgress}) => {
  const [progress, setProgress] = useState({bytes: 0, total: 0, percent: 0, filename: "",fileType: "" , updated: false})
    const updateProgress = (newProgress, filename, fileType) => {
        if(newProgress.loaded == newProgress.total) {
            setProgress({loaded: 0, total: 0, percent: 0, filename: "",fileType: "", updated: false})
        } else {
            setProgress({loaded: newProgress.loaded, total: newProgress.total, percent: newProgress.loaded / newProgress.total * 100, filename: filename, fileType: fileType, updated: true})
        }
    }
  const onDrop = useCallback(acceptedFiles => {
      // Do something with the files
      const dataFields = JSON.parse(fields)
      const file = acceptedFiles[0]
      const formData = new FormData();
      // formData.append("Content-Type", file.type);
      Object.entries(dataFields).forEach(([k, v]) => {
          formData.append(k, v);
      });
      formData.append("file", file);
      axios.request({
          method: "post", 
          url: url, 
          data: formData, 
          onUploadProgress: (p) => {
              updateProgress(p, file.name, file.type)
              if(onProgress) onProgress(p)
          }
      }).then (response => {
          onUpload(file.name, file.type, response)
      }).catch(error => {
          console.log(error)
      })
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} className="w-full h-full">
     { progress.updated ?
         <>
             <span className="text-xs">{progress.filename}: {roundedPercentString(progress.percent)} %</span>
         <progress value={progress.percent} max="100" className="w-full"> {progress.filename}: {roundedPercentString(progress.percent)} % </progress>
         </>
     :
     <>
         <input {...getInputProps()} />
         {
             isDragActive ?
                 <p className="p-0">Drop files</p> :
                 <p className="p-0">Click or drag here</p>
         }
     </>
        }
    </div>
  )
}

export const VideoUpload = ({onUpload, onProgress, id}) => {
    const mutation = id ? UPLOAD_EXISTING_ENTITY : UPLOAD_NEW_ENTITY
    const [getUploadToken, { data }] = useMutation(mutation);
    useEffect(() => {
        const variables = id ? {id: id} : {}
        getUploadToken({variables})
    }, [])
    return data ? <Dropzone url={data.createUploadToken.url} fields={data.createUploadToken.fields} onUpload={(fileName) => onUpload(data.createUploadToken.id, fileName)} onProgress={(p) => { if(onProgress) onProgress(p) }} /> : <span />
}

export const AssetUpload = ({onUpload, onProgress}) => {
    const mutation = UPLOAD_ASSET
    const [getUploadToken, { data }] = useMutation(mutation);
    useEffect(() => {
        const variables = {}
        getUploadToken({variables})
    }, [])
    return data ? <Dropzone url={data.createUploadToken.url} fields={data.createUploadToken.fields} onUpload={(fileName, fileType) => onUpload(data.createUploadToken.uploadedUrl + fileName, fileType)} onProgress={(p) => { if(onProgress) onProgress(p) }}/> : <span />
}

export const CompUpload = ({onUpload, onProgress}) => {
    const mutation = UPLOAD_COMP
    const [getUploadToken, { data }] = useMutation(mutation);
    useEffect(() => {
        const variables = {}
        getUploadToken({variables})
    }, [])
    return data ? <Dropzone url={data.createUploadToken.url} fields={data.createUploadToken.fields} onUpload={(fileName) => onUpload(data.createUploadToken.uploadedUrl + fileName)} onProgress={(p) => { if(onProgress) onProgress(p) }}/> : <span />
}

export default VideoUpload
