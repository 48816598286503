import React from 'react'
import gql from 'graphql-tag'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import { useQuery, useMutation } from 'react-apollo'
import Loading from './loading'
import {User, UserToken, Application} from '../types'
import { Mixpanel } from '../Common/Mixpanel'

const PROFILE_QUERY = gql`
query account {
    currentUser {
        lastSignInAt
        id
        email
        username
        isAdmin
        accessTokens {
            application {
                name
            }
            token
        }
    }
}
`

export const REQUEST_NEW_PASSWORD_MUTATION = gql`
mutation update($email:String!) {
  requestNewPassword(email:$email)
}
`


type ApplicationWithTokens = Application & {
    tokens: UserToken[];
}

function userTokensToApplications(tokens : UserToken[]) : ApplicationWithTokens[] {
    let list = [] as ApplicationWithTokens[];
    let names = [] as String[];

    tokens.forEach((token) => {
        const app = token.application
        const nameIndex = names.indexOf(app.name)

        if(nameIndex > -1) {
            list[nameIndex]['tokens'].push(token)
        } else {
            const newApp = {
                ...app,
                tokens: [token]
            }
            list.push(newApp)
            names.push(app.name)
        }
    })

    return list;
}

interface UserProfileData {
  currentUser: User;
}


interface ShowAccessTokensProps {
    tokens: UserToken[];
}

const ShowAccessToken : React.FC<UserToken> = ({token}) => {
    return <pre>{token}</pre>

}

const ShowOauthApplication : React.FC<ApplicationWithTokens> = ({name, tokens}) => {
    return (
        <>
        <h2>{name}</h2>
        {tokens.map((at, i) => {
            return (
                <div key={i} className="border-b b-1 p-2 mb-2">
                <ShowAccessToken {...at} />
                <button>Revoke token</button>
                </div>
            )
        })}
        </>
    )
}

const ShowAccessTokens : React.FC<ShowAccessTokensProps> = ({tokens}) => {
    const apps = userTokensToApplications(tokens)
    return (
        <div className="p-4 border b-1 mt-4">
        <h2>Access Tokens</h2>
        {apps.map((application, i) => {
            return (
                <div key={i} className="border-b b-2 p-2 mb-2">
                    <ShowOauthApplication {...application} />
                </div>
            )
        })}
        </div>
    )
}

const ShowProfile : React.FC<User> = ({id, email, lastSignInAt, accessTokens}) => {
    const [requestNewPassword, data] = useMutation(REQUEST_NEW_PASSWORD_MUTATION);
    const requestPasswordClicked = () => {
        const result = requestNewPassword({ variables: {email}})
        result.then((done) => {
            Mixpanel.track("New password requested", {id, email}) 
            window.alert("You will receive an email, with password reset instructions, shortly")
        }).catch(v => console.error(v))
    }
  return (
      <div>
          <h1>{email}</h1>
          <h2>Last Sign In: {timeDistanceInWords(lastSignInAt)}</h2>
          <ShowAccessTokens tokens={accessTokens} />
          <div className="p-4 border b-1 mt-4">
              <button onClick={(evt) => requestPasswordClicked() }>Change password</button>
          </div>
      </div>
  )
}

const ProfileDashboard = () => {
    const { loading, error, data } = useQuery<UserProfileData>(PROFILE_QUERY);

    if (loading) return <Loading />

        if (error) {
            console.log(error)
            return <div>Error</div>
        }
    return (
        <div>
            <h1 className="text-3xl text-black pb-4">Profile</h1>
            <div className="flex flex-wrap">
                <div className="flex-grow w-full mx-auto">
                    <ShowProfile {...data.currentUser} />
                </div>
            </div>
        </div>
    )
}

export default ProfileDashboard
