import React from 'react'
import { useQuery } from 'react-apollo'
import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'
import Loading from '../loading'

export const WEBHOOKSHOW_QUERY = gql`
query WebhookShow($id:ID!) {
    webhook(id:$id)  {
        id
        url
        constraints {
          path
          comparator
          value
        }
    }
}
`

const Show = () => {
    let params = useParams()
    const vars = {id: params.webhookId}
    const { loading, error, data} = useQuery(WEBHOOKSHOW_QUERY, {variables: vars})
    if (loading) return <Loading />
        if (error) {
            console.log(error)
            return <div>Error</div>
        }
    return (
        <div>
            <span>{data.webhook.url}</span>
        </div>
    )
}

export default Show
