import React  from 'react';

const colorString = (color) => {
  return `rgba(${color.r * 255}, ${color.g * 255}, ${color.b * 255}, ${color.a})`;
}


export const SubtitleEntry = ({videoWidth, videoHeight, isEditingInOutPoints, scaleRatio, resolution, activeSubtitleIndex, activeSubtitle, showSubtitleBackground, settings}) => {
  console.log(videoWidth, videoHeight, isEditingInOutPoints, scaleRatio, resolution, activeSubtitleIndex, activeSubtitle, settings)

  const containerStyle = isEditingInOutPoints 
    ? {'transform': 'scale('+scaleRatio+')', 'width': resolution.width, 'height': resolution.height-200} 
    : {'transform': 'scale('+scaleRatio+')', 'width': resolution.width, 'height': resolution.height}

  const textStyle = {
    color: colorString(settings.fontColor),
    fontSize: settings.fontSize + "px",
    fontWeight: settings.font.weight || 'normal',
    whiteSpace: "pre-wrap",
  }

  if(settings?.font?.fontFamily) {
    textStyle.fontFamily = settings.font.fontFamily
  }

  if(settings.textPosition.maxWidth) {
    textStyle['width'] = settings.textPosition.maxWidth + "px"
    textStyle['wordBreak'] = "break-word"
  }

  const backgroundColor = colorString(settings.backgroundColor)

  if(settings.showBoxSetting == true) {
    if(showSubtitleBackground) {
      textStyle['background'] = backgroundColor
    } else {
      textStyle['background'] = 'transparent'
    }
  } else {
    textStyle['background'] = backgroundColor
  }

  if(settings.textPosition.valign == "TOP") {
    textStyle['top'] = settings.textPosition.y + "px";
  } else if(settings.textPosition.valign == "MIDDLE") {
    textStyle['top'] = resolution.height / 2 + "px";
  } else if(settings.textPosition.valign == "BOTTOM") {
    textStyle['bottom'] = settings.textPosition.y + "px";
  } else {
    textStyle['bottom'] = "30px";
  }

  if(settings.textPosition.align == "CENTER") {
    textStyle['textAlign'] = "center";
  } else {
    if(settings.textPosition.align == "LEFT"){
      textStyle['left'] = settings.textPosition.x + "px";
      textStyle['textAlign'] = "left";
    }
    if(settings.textPosition.align == "RIGHT"){
      textStyle['right'] = settings.textPosition.x + "px";
      textStyle['textAlign'] = "right";
    }
  }

  const classes = settings.legacy ? "absolute leading-[52px] px-4 py-2 drop-shadow-[0_2px_3px_rgba(0,0,0,0.7)]" : "absolute drop-shadow-[3px_3px_2px_rgba(0,0,0,0.9)]"

  return (
    <div className="subtitle absolute flex items-end justify-center origin-top-left left-0 top-0" style={containerStyle}>
      {activeSubtitleIndex > -1 &&
      <>
      <label style={textStyle} leading className={classes}  id="subtitlePreview">{activeSubtitle}</label>
      </>
      }
    </div>
  )
}


export default SubtitleEntry;
