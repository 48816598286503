import gql from 'graphql-tag'
import {WorkflowInstance} from '../../../../types'

export const RESTART_INSTANCE = gql`
mutation Restart($id:String!, $data: JSON) {
  restartWorkflow(workflowInstanceId:$id, data: $data) {
    id
  }
}
`

export const INSTANCE_SHOW = gql`
query Instance($id:ID) {
  workflowInstance(id:$id) {
    id
    status
    createdAt
    originalData
    data
    videoWorkflow {
      id
      account {
        id
        title
      }
      title
      category {
        id
        title
      }
    }
    rendering {
      id
      renderrRendering {
        id
        status
        state
        priority
      }
      status
      state
      events(limit:10, sortBy:CreatedAt, sortDirection:DESC) {
        items {
          id
          createdAt
          type
          payload
        }
      }
    }
    viewable {
      id
      embedUrl
      title
      publicPoster
      captionKey
      captions {
        id
        text
        actionUrl
        createdAt
        updatedAt
      }
    }
    preDeliveryActions {
      id
      title
      startedAt
      createdAt
      failedAt
      doneAt
      status
    }
    preRenderingActions {
      id
      title
      createdAt
      startedAt
      failedAt
      doneAt
      status
    }
    user {
      name
      email
      imageUrl
    }
  }
}
`


export interface InstanceShowResult {
  workflowInstance: WorkflowInstance;

}

