import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Query, useMutation } from 'react-apollo'
import Loading from '../../../loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../../Modal'

const findWithAttr = (array, attr, value) => {
    const l = array.length
    for(var i = 0; i < l; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}


export const TEAMLIST_QUERY = gql`
query TeamList {
    teams(limit:500) {
        items {
        id
        title
        createdAt
        }
    }
}
`
const WorkflowTeam = ({team, saveTeam}) => {
    const [state, setState] = useState(team || {})

    const save = () => {
        saveTeam(state)
    }

    useEffect(() => save())

    return (
        <Query query={TEAMLIST_QUERY}>

            {({ loading, error, data, subscribeToMore }) => {
                if (loading) return <Loading />
                    if (error) {
                        console.log(error)
                        return <div>Error</div>
                    }
                const setTeam  = (id) => {
                    const index = findWithAttr(data.teams.items, "id", id)
                    const newState = data.teams.items[index]
                    setState(newState)
                }

                return (
                    <div className="flex flex-row">
                        <div className="w-1/4">
                            <span className="text-sm leading-5 font-medium text-gray-700">Team</span>
                        </div>
                        <div>
                    <select id="type" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => setTeam(evt.target.value)} defaultValue={state.id ? state.id : ''}>
                        {!state.type ? <option value={false} key={false}>[select team]</option> : ''}
                        {data.teams.items.map((team, i) => {
                            return (
                                <option value={team.id} key={team.id}>{team.title}</option>
                            )
                        })}
                    </select>
                        </div>
                    </div>
                )
            }}
        </Query>
    )
}


export default WorkflowTeam
