const key = 'graphPeriod'
const store = localStorage

import moment from 'moment';

import React from 'react'
export const DashboardTimeContext = React.createContext<Partial <ReducerType>>({})

export type TimePeriodValue  = '1H' | '4H' | '12H' | '1D' | '3D' | '1W' | '2W' | '4W' | '1M' | '3M' | '6M' | '1Y' | 'ALL'
export type TimePeriodName = 'ONE_HOUR' | 'FOUR_HOURS' | 'TWELVE_HOURS' | 'ONE_DAY' | 'THREE_DAYS' | 'ONE_WEEK' | 'TWO_WEEKS' | 'FOUR_WEEKS' | 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'ALL'

export type TimePeriodResolution = 'MINUTE' | 'HOUR' | 'DAYSINWEEK' | 'DAYSINMONTH' | 'MONTH'

export const SET_LIMIT = 'SET_LIMIT'
export const SET_START_TIME = 'SET_START_TIME'
export const SET_END_TIME = 'SET_END_TIME'
export const SET_TIMEPERIOD = 'SET_TIMEPERIOD'


const periods = [
                {key: '1H', period: 'ONE_HOUR', resolution: 'MINUTE'},
                {key: '4H', period: 'FOUR_HOURS', resolution: 'HOUR'},
                {key: '12H', period: 'TWELVE_HOURS', resolution: 'HOUR'},
                {key: '1D', period: 'ONE_DAY', resolution: 'HOUR'},
                {key: '3D', period: 'THREE_DAYS', resolution: 'DAYSINWEEK'},
                {key: '1W', period: 'ONE_WEEK', resolution: 'DAYSINWEEK'},
                {key: '2W', period: 'TWO_WEEKS', resolution: 'DAYSINMONTH'},
                {key: '4W', period: 'FOUR_WEEKS', resolution: 'DAYSINMONTH'},
                {key: '1M', period: 'ONE_MONTH', resolution: 'DAYSINMONTH'},
                {key: '3M', period: 'THREE_MONTHS', resolution: 'MONTH'},
                {key: '6M', period: 'SIX_MONTHS', resolution: 'MONTH'},
                {key: '1Y', period: 'ONE_YEAR', resolution: 'MONTH'},
                {key: 'ALL', period: 'ALL', resolution: 'MONTH'}
            ]

export type Period =  {
    key: TimePeriodValue;
    period: TimePeriodName;
    resolution: TimePeriodResolution;
}

export type TimePeriod = {
  listLimit: number;
  graphPeriod: Period;
  possiblePeriods: Period[];
  startTime: Date;
  endTime: Date;
}

export type ActionType =
    | { type: 'SET_LIMIT'; payload: number }
    | { type: 'SET_START_TIME'; payload: Date }
    | { type: 'SET_END_TIME'; payload: Date }
    | { type: 'SET_TIMEPERIOD'; payload: Period };

export type ReducerType = {
    timePeriod: TimePeriod;
    dispatch(action: ActionType): void;
}

export const initialTimePeriod = {
  listLimit: 10,
  graphPeriod: getDefault(),
  possiblePeriods: periods,
  startTime: getDefaultStartTime(),
  endTime: getDefaultEndTime()
}

function getDefault() {
        const val = store.getItem(key)
        if(val) {
            try {
                return JSON.parse(val);
            }
            catch(err) {
                console.log(err);
            }
        }
        return {key: '1D', period: 'ONE_DAY', resolution: 'HOUR'}
}

function getDefaultStartTime(period?: Period) : Date {
    period = period || getDefault()
    const key = period.key;
    const startTime = moment()
    let prefix = 'P'
    switch(key) {
        case '1H': {
            startTime.startOf('hour')
            prefix = 'PT'
            break;
        }
        case '4H': {
            startTime.startOf('hour')
            prefix = 'PT'
            break;
        }
        case '12H': {
            startTime.startOf('hour')
            prefix = 'PT'
            break;
        }
        case '1D': {
            startTime.startOf('day')
            break;
        }
        case '3D': {
            startTime.startOf('day')
            break;
        }
        case '1W': {
            startTime.startOf('isoWeek')
            break;
        }
        case '2W': {
            startTime.startOf('isoWeek')
            break;
        }
        case '4W': {
            startTime.startOf('isoWeek')
            break;
        }
        case '1M': {
            startTime.startOf('month')
            break;
        }
        case '3M': {
            startTime.startOf('month')
            break;
        }
        case '6M': {
            startTime.startOf('month')
            break;
        }
        case '1Y': {
            startTime.startOf('year')
            break;
        }
        case 'ALL': {
            startTime.startOf('year')
            break;
        }
    }
    return startTime.subtract(moment.duration(prefix+key)).toDate()
}

function getDefaultEndTime(period?: Period, startTime?: Date) : Date {
    period = period || getDefault()
    startTime = startTime || getDefaultStartTime(period)
    const key = period.key;
    const endTime = moment(startTime)
    let prefix = 'P'
    switch(key) {
        case '1H': {
            prefix = 'PT'
            break;
        }
        case '4H': {
            prefix = 'PT'
            break;
        }
        case '12H': {
            prefix = 'PT'
            break;
        }
    }
    return endTime.add(moment.duration(prefix+key)).toDate()
}


export function TimePeriodReducer(state: TimePeriod, action: ActionType) : TimePeriod {

  switch (action.type) {
    case 'SET_LIMIT': {
      return {
        ...state,
        listLimit: action.payload
      }
    }
    case 'SET_START_TIME': {
        const startTime = action.payload
        const endTime = getDefaultEndTime(state.graphPeriod, startTime)
        return {
        ...state,
        startTime: startTime,
        endTime: endTime
      }
    }
    case 'SET_END_TIME': {
      return {
        ...state,
        endTime: action.payload,
      }
    }
    case 'SET_TIMEPERIOD': {
      store.setItem(key, JSON.stringify(action.payload))
      const startTime = getDefaultStartTime(action.payload)
      const endTime = getDefaultEndTime(action.payload, startTime)
      return {
        ...state,
        startTime: startTime,
        endTime: endTime,
        graphPeriod: action.payload,
      }
    }
    default:
      return state
  }
}

