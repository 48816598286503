import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserConsumer } from "../stores/User";
import client from "../FlivaApolloClient";
import { Mixpanel } from "../Common/Mixpanel";

const unImpersonate = () => {
  if (localStorage) {
    Mixpanel.track("Unimpersonating");
    localStorage.removeItem("impersonation");
    localStorage.removeItem("actionToken");
    client.resetStore();
    window.location.replace("/dashboard");
  }
};

const logout = () => {
  Mixpanel.track("Logging out");
  return fetch("/users/sign_out", {
    method: "GET",
  }).then((response) => {
    Mixpanel.track("Logged out");
    window.location.replace("/dashboard");
  });
};

const MainHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const menuClasses = isMenuOpen
    ? "absolute w-auto bg-white rounded-lg shadow-lg py-2 mt-16 block z-50"
    : "hidden";

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isMenuOpen && !isHovered) {
        setMenuOpen(false);
      }
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isMenuOpen, isHovered]);

  return (
    <UserConsumer>
      {(user) => {
        if (!user) return <span></span>;
        return (
          <header className="w-full flex items-center bg-white py-2 px-6 sm:flex">
            <div className="w-1/2">
              <h1 className="text-3xl text-black pb-4">
                {user.impersonatedAccount ? user.impersonatedAccount.title : ""}
              </h1>
            </div>
            <div className="relative w-1/2 flex justify-end">
              <Link
                to="/dashboard/videos/upload/new"
                className="bg-brand-500 text-white hover:bg-brand-800 hover:text-brand-200 font-bold py-2 px-4 mr-8 rounded"
              >
                Upload Video
              </Link>
              <button
                onClick={() => setMenuOpen(!isMenuOpen)}
                className="realtive z-10 w-12 h-12 rounded-full overflow-hidden border-4 border-gray-400 hover:border-gray-300 focus:border-gray-300 focus:outline-none"
              >
                <img src={user.gravatar} />
              </button>
              <div
                className={menuClasses}
                onMouseOver={() => {
                  setHovered(true);
                }}
                onMouseOut={() => {
                  setHovered(false);
                }}
              >
                {user.settings.profile ? (
                  <Link
                    className="block px-4 py-2 account-link hover:text-brand-500 hover:bg-gray-400"
                    to="/dashboard/profile"
                    onClick={() => setMenuOpen(false)}
                  >
                    Profile
                  </Link>
                ) : (
                  <span />
                )}
                {user.settings.profile ? (
                  <Link
                    className="block px-4 py-2 account-link hover:text-brand-500 hover:bg-gray-400"
                    to="/dashboard/support"
                    onClick={() => setMenuOpen(false)}
                  >
                    Support
                  </Link>
                ) : (
                  <span />
                )}
                {user.isAdmin ? (
                  <Link
                    className="block px-4 py-2 account-link hover:text-brand-500 hover:bg-gray-400"
                    to="/dashboard/settings"
                    onClick={() => setMenuOpen(false)}
                  >
                    Account
                  </Link>
                ) : (
                  <span></span>
                )}
                {user.isAdmin ? (
                  <Link
                    className="block px-4 py-2 account-link hover:text-brand-500 hover:bg-gray-400"
                    to="/dashboard/settings/teams"
                    onClick={() => setMenuOpen(false)}
                  >
                    Teams
                  </Link>
                ) : (
                  <span></span>
                )}
                {user.isAdmin ? (
                  <Link
                    className="block px-4 py-2 account-link hover:text-brand-500 hover:bg-gray-400"
                    to="/dashboard/settings/tool"
                    onClick={() => setMenuOpen(false)}
                  >
                    Tools
                  </Link>
                ) : (
                  <span></span>
                )}
                {user.impersonatedAccount ||
                localStorage?.getItem("actionToken") ? (
                  <span
                    onClick={() => {
                      unImpersonate();
                      setMenuOpen(false);
                    }}
                    className="block px-4 py-2 account-link hover:text-brand-500 hover:bg-gray-400 cursor-pointer"
                  >
                    Unimpersonate
                  </span>
                ) : (
                  <span></span>
                )}
                {user.isAdmin && user.account && user.account.siteAdmin ? (
                  <Link
                    className="block px-4 py-2 account-link hover:text-brand-500 hover:bg-gray-400"
                    to="/admin/"
                    onClick={() => setMenuOpen(false)}
                  >
                    Admin
                  </Link>
                ) : (
                  <span></span>
                )}
                <span
                  onClick={() => {
                    logout();
                    setMenuOpen(false);
                  }}
                  className="block px-4 py-2 account-link hover:text-brand-500 hover:bg-gray-400 cursor-pointer"
                  to="/dashboard/sign-out"
                >
                  Sign Out
                </span>
              </div>
            </div>
          </header>
        );
      }}
    </UserConsumer>
  );
};

export default MainHeader;
