import React from 'react'
import {roundedPercentString} from '../helpers/PercentHelper'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import { Link } from 'react-router-dom'
import { VideoList } from '../custom_types'

const LatestEvents : React.FC<VideoList> = ({videos}) => {
        return (
            <div className="w-full mb-6 lg:mb-0 lg:w-1/2 px-4 flex flex-col">
                <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    <div className="border-b">
                        <div className="flex justify-between px-6 -mb-px">
                            <h3 className="text-blue-600 py-4 font-normal text-lg">Latest Played Videos</h3>
                        </div>
                    </div>
                    {videos.map((video, i) => {
                        return (
                            <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={video.id+"-"+i}>
                                <div className="w-1/5 px-4">
                                  <Link to={`/dashboard/videos/${video.id}`}>
                                    <img className="text-right text-grey" src={video.publicPoster} />
                                  </Link>
                                </div>
                                <div className="w-1/5 px-4 flex items-center">
                                  <Link to={`/dashboard/videos/${video.id}`}>
                                    <span className="text-lg"> {video.title} </span>
                                  </Link>
                                </div>
                                <div className="w-1/5 px-4">
                                    <div className="text-right text-grey" title="View through rate">
                                        {video.metrics ? roundedPercentString(video.metrics.items[0].viewThroughRate) : 'N/A'}
                                    </div>
                                </div>
                                <div className="w-1/5 px-4">
                                    <div className="text-right text-grey" title="Approximate location of user">
                                        {video.metrics ? 
                                            video.metrics.items[0].client.city ?  video.metrics.items[0].client.city + " (" + video.metrics.items[0].client.country + ")"  :  video.metrics.items[0].client.country
                                            : 'N/A'
                                        }
                                    </div>
                                </div>
                                <div className="w-1/5 px-4">
                                    <div className="text-right text-grey">
                                        {video.metrics ? timeDistanceInWords(video.metrics.items[0].sessionStart) : 'N/A'}
                                    </div>
                                </div>
                            </div>
                        )})}
                </div>
            </div>
        )
}

export default LatestEvents

