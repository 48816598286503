import React, { useState, useEffect } from 'react'
import { arrayMoveImmutable as arrayMove } from 'array-move'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faEdit, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { AssetUpload } from '../../../Video/Upload'

import Modal from '../../../Modal'

const ControlTypes = {
    StringControl: { title: "String Control", description: "Use for single value strings" },
    BooleanControl: { title: "Boolean Control", description: "Use for yes/no boolean values" },
    TextControl: { title: "Text Control", description: "For multiline strings" },
    IntegerControl: { title: "Integer Control", description: "For numerical input" },
    NumberControl: { title: "Number Control", description: "For numerical input" },
    ColorPicker: { title: "Color Picker", description: "For picking a rgb hex value" },
    ChoiceControl: { title: "Choice Control", description: "Select between options" },
    SwitchControl: { title: "Switch Control", description: "Select between options" },
    UploadControl: { title: "Upload Control", description: "Upload a file, and use the url as a value" },
    UploadImageControl: { title: "Upload Control for images", description: "Used in app to enable camera for image capture" },
    UploadVideoControl: { title: "Upload Control for video", description: "Used in app to enable camera for video capture" },
    SceneContainerControl: { title: "Scene Container", description: "Scene Container allows for multiple - templated - scenes" },
    LookupControl: { title: "Lookup control", description: "Requires a backend set up" },
    AssetControl: { title: "Asset control", description: "Requires a backend set up" },
    ImageLookupControl: { title: "Image Lookup control", description: "Requires a backend set up" },
    // SceneControl: {title: "Scene Control", description: "Template for a scene in a container"}
}

const Types = {
    "string": { title: "String value", description: "Use for single value strings", defaultControl: "StringControl" },
    "text": { title: "Text value", description: "Use for text values, where newlines are used", defaultControl: "TextControl" },
    "integer": { title: "Integer value", description: "Use for integer (whole) numbers", defaultControl: "IntegerControl" },
    "number": { title: "Number value", description: "Use for numerical values of any kind", defaultControl: "NumberControl" },
    "boolean": { title: "Boolean value", description: "Yes or no", defaultControl: "BooleanControl" },
    "compute": { title: "Computed value", description: "Template in value, using liquid syntax interpolate other values", defaultControl: false },
    "object": { title: "Object", description: "Use for object values", defaultControl: false },
    "array": { title: "Array", description: "Use for a list of values", defaultControl: "SceneContainerControl" },
}

interface XYCoords {
    x: number;
    y: number;
}

interface Constraints {
    mode: "portrait" | "landscape" | "square"
    width: number;
    height: number;
    fps: number;
    sweetspot: XYCoords;
}

const defaultConstraintsFromMode = (mode: "portrait" | "landscape" | "square"): Constraints => {
    const width = mode == "landscape" ? 1920 : 1080
    const height = mode == "portrait" ? 1920 : 1080

    let x = 16;
    let y = 9;

    if (mode == "portrait") {
        x = 4
        y = 5
    }
    if (mode == "square") {
        x = 1
        y = 1
    }

    return {
        mode: mode,
        width: width,
        height: height,
        fps: 25,
        sweetspot: {
            x: x,
            y: y
        }
    }
}

interface Field {
    title?: string;
    type?: "string" | "text" | "integer" | "number" | "boolean" | "compute" | "object" | "array";
    key?: string;
    oldKey?: string;
    description?: string;
    control?: "StringControl" | "BooleanControl" | "TextControl" | "IntegerControl" | "NumberControl" | "ColorPicker" | "ChoiceControl" | "SwitchControl" | "UploadControl" | "UploadImageControl" | "UploadVideoControl" | "SceneContainerControl" | "SceneControl" | "LookupControl" | "AssetControl" | "ImageLookupControl";
    label?: string;
    helpText?: string;
    value?: string | number | boolean;
    maxlength?: number;
    inject?: boolean;
    sceneTypes?: SceneControlField[];
    children?: SceneControlField[];
    choices?: ChoiceField[];
    maxChildren?: number;
    minChildren?: number;
    required?: boolean;
    constraints?: Constraints;
    prefill?: boolean;
}

type StandardField = Field;

type SceneControlField = Field & {
    control?: "SceneControl";
    children?: Field[];
}

type ChoiceField = {
    label: String;
    value: String;
}

type ResolutionChoiceField = ChoiceField & {
    previewURL: String;
}

const EditField: React.FC<FieldDialogProps> = ({ title, field = {}, saveField, cancelField }) => {
    const [state, setState] = useState<Field>({ ...field, oldKey: field.key })

    const [uploadPreview, setUploadPreview] = useState(false)

    const setType = (newType) => {
        setState({ ...state, type: newType, control: Types[newType].defaultControl })
    }

    const setControl = (newControl) => {
        setState({ ...state, control: newControl })
    }

    
    const updateResolutionChoice = (field, value, index) => {
        //Make copy
        const newChoices = [...state.choices]

        //spread out the object at the correct index, and replace the correct field with the value
        const newChoice = { ...newChoices[index], [field]: value }

        //insert the new object into the choices array at the correct index
        newChoices[index] = newChoice;

        //Update the state
        setState({ ...state, choices: newChoices })
    }

    const moveResolutionChoice = (old_index, new_index) => {
        //Make working copy
        const tmpChoices = [...state.choices];

        //Remove the choice at the old index
        const removedItem = tmpChoices.splice(old_index, 1)[0]

        //insert the choice at the new index
        tmpChoices.splice(new_index, 0, removedItem)

        //update the state
        setState({ ...state, choices: tmpChoices })
    }

    const removeResolutionChoice = (index) => {
        //Save copy of old choices
        const newChoices = [...state.choices]

        //remove the selected index
        newChoices.splice(index, 1);

        //Update the state
        setState({ ...state, choices: newChoices })
        console.log(state)
    }

    return (
        <Modal title={title} save={() => saveField(state)} cancel={cancelField} containerClassNames="relative w-1/2 my-6 mx-auto max-w-full">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="key" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Key
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input id="key" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.key} onChange={(evt) => setState({ ...state, key: evt.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="type" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Datatype</label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <select id="type" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => setType(evt.target.value)} defaultValue={state.type}>
                                {!state.type ? <option value={undefined} key={undefined}>[select type]</option> : ''}
                                {Object.entries(Types).map(([key, value]) => {
                                    return (
                                        <option value={key} key={key}>{value.title}</option>
                                    )
                                })}
                            </select>
                            <p className="text-xs">{Types[state.type] ? Types[state.type].description : ""}</p>
                        </div>
                    </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="label" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Label
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input id="label" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.label} onChange={(evt) => setState({ ...state, label: evt.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="helpText" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Help Text
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input id="helpText" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.helpText} onChange={(evt) => setState({ ...state, helpText: evt.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="control" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Custom Control</label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <select id="type" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => setControl(evt.target.value)} value={state.control}>
                                <option value={undefined} key={undefined}>Default Control</option>
                                {Object.entries(ControlTypes).map(([key, value]) => {
                                    return (
                                        <option value={key} key={key}>{value.title}</option>
                                    )
                                })}
                            </select>
                            <p className="text-xs">{ControlTypes[state.control] ? ControlTypes[state.control].description : "Default control for the selected data type"}</p>
                        </div>
                    </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="value" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Default Value</label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg flex rounded-md shadow-sm">
                            <textarea id="value" rows={5} className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.value as string} onChange={(evt) => setState({ ...state, value: evt.target.value })} ></textarea>
                        </div>
                    </div>
                </div>
                {state.control == "ChoiceControl" || state.control == "SwitchControl" ?
                    state.key == "resolution" ?
                        <div className="mt-8 flow-root sm:border-t sm:border-gray-200">
                            <label className="block py-4 text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Resolutions</label>
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className=" table-auto min-w-full divide-y divide-gray-300 ">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Label
                                                </th>
                                                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Value
                                                </th>
                                                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Preview url
                                                </th>
                                                <th scope="col" className=" py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Upload new preview
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Delete
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Move
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {state.choices.map((c, i) =>
                                                <tr key={i}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><input id="label" value={c.label ? c.label as string : ''} className="form-input pr-4     transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => updateResolutionChoice(evt.target.id, evt.target.value, i)}></input></td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><input id="value" value={c.value ? c.value as string : ''} className="form-input pr-4     transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => updateResolutionChoice(evt.target.id, evt.target.value, i)}></input></td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><input id="previewURL" value={(c as ResolutionChoiceField).previewURL ? (c as ResolutionChoiceField).previewURL as string : ''} className="form-input pr-4  transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => updateResolutionChoice(evt.target.id, evt.target.value, i)}></input></td>
                                                    <td>      <span className='pr-4'>
                                                        {uploadPreview ?
                                                            <span>
                                                                <AssetUpload className="inline" onUpload={(url) => {updateResolutionChoice("previewURL", url, i); setUploadPreview(false) }} />
                                                                <span onClick={() => setUploadPreview(!uploadPreview)} className=" inline p-4 m-4 text-sm">cancel</span>
                                                            </span>
                                                            :
                                                            <span onClick={() => setUploadPreview(!uploadPreview)}>
                                                                Upload new
                                                            </span>
                                                        }</span>
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><button><FontAwesomeIcon icon={faXmark} className="pr-4 h-3 w-3" aria-hidden="true" onClick={() => removeResolutionChoice(i)} /></button> </td>
                                                    <td> <button className="cursor-pointer" onClick={() => moveResolutionChoice(i, i - 1)}><FontAwesomeIcon icon={faChevronUp} className="pr-4 h-3 w-3" /></button>
                                                        <button className="cursor-pointer" onClick={() => moveResolutionChoice(i, i + 1)}><FontAwesomeIcon icon={faChevronDown} className="pr-4 h-3 w-3" /></button></td>
                                                </tr>
                                            )}
                                            <tr key={state.choices.length}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><input id="label" className="form-input pr-4  rounded ring-1 ring-inset ring-gray-300  transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => updateResolutionChoice(evt.target.id, evt.target.value, state.choices.length)}></input></td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><input id="value" className="form-input pr-4   rounded ring-1 ring-inset ring-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => updateResolutionChoice(evt.target.id, evt.target.value, state.choices.length)}></input></td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><input id="previewURL" className="form-input pr-4  rounded ring-1 ring-inset ring-gray-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => updateResolutionChoice(evt.target.id, evt.target.value, state.choices.length)}></input></td>
                                                <td>      <span className='pr-4'>
                                                    {uploadPreview ?
                                                        <span>
                                                            <AssetUpload className="inline" onUpload={(url) => { updateResolutionChoice("previewURL", url, state.choices.length); setUploadPreview(false) }} />
                                                            <span onClick={() => setUploadPreview(!uploadPreview)} className=" inline p-4 m-4 text-sm">cancel</span>
                                                        </span>
                                                        :
                                                        <span onClick={() => setUploadPreview(!uploadPreview)}>
                                                            Upload new
                                                        </span>
                                                    }</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> 
                        :
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="choices" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Choices (array of of label, value objects)</label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg flex rounded-md shadow-sm">
                                    <textarea id="choices" rows={5} className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={JSON.stringify(state.choices || [])} onChange={(evt) => setState({ ...state, choices: JSON.parse(evt.target.value) as ChoiceField[] })} ></textarea>
                                </div>
                            </div>
                        </div>
                    : <span />}

                {state.control == "SceneContainerControl" ?
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="sceneTypes" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Scene Types (must be valid json array)</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                                <textarea id="sceneTypes" rows={5} className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={JSON.stringify(state.sceneTypes || [])} onChange={(evt) => setState({ ...state, sceneTypes: JSON.parse(evt.target.value) as SceneControlField[] })} ></textarea>
                            </div>
                        </div>
                    </div>
                    : <span />}
                {state.control == "UploadVideoControl" ?
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="constraints" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Constraints (must be valid json)</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                                <textarea id="children" rows={5} className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={JSON.stringify(state.constraints || defaultConstraintsFromMode("landscape"))} onChange={(evt) => setState({ ...state, constraints: JSON.parse(evt.target.value) as Constraints })} ></textarea>
                            </div>
                        </div>
                    </div>
                    : <span />}
                {state.control == "SceneContainerControl" ?
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="children" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Children (must be valid json array)</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                                <textarea id="children" rows={5} className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={JSON.stringify(state.children || [])} onChange={(evt) => setState({ ...state, children: JSON.parse(evt.target.value) as SceneControlField[] })} ></textarea>
                            </div>
                        </div>
                    </div>
                    : <span />}
                {state.control == "SceneContainerControl" ?
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="minChildren" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Minimum children</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                                <input id="minChildren" type="number" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.minChildren || 0} onChange={(evt) => setState({ ...state, minChildren: parseInt(evt.currentTarget.value, 10) })} />
                            </div>
                        </div>
                    </div>
                    : <span />}
                {state.control == "SceneContainerControl" ?
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="maxChildren" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Maximum children</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                                <input id="maxChildren" type="number" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.maxChildren || 1} onChange={(evt) => setState({ ...state, maxChildren: parseInt(evt.currentTarget.value, 10) })} />
                            </div>
                        </div>
                    </div>
                    : <span />}
                {state.control == "TextControl" || state.control == "StringControl" ?
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="maxlength" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Max length</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                                <input id="maxlength" type="number" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.maxlength || 120} onChange={(evt) => setState({ ...state, maxlength: parseInt(evt.currentTarget.value, 10) })} />
                            </div>
                        </div>
                    </div>
                    : <span />}

                {state.type == "compute" ?
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="required" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Inject remote data?</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                                <input id="inject" type="checkbox" defaultChecked={state.inject} onChange={(evt) => setState({ ...state, inject: evt.currentTarget.checked })} />
                            </div>
                        </div>
                    </div>
                    : <span />}
                {state.control != "SceneContainerControl" ?
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="required" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Required?</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                                <input id="required" type="checkbox" defaultChecked={state.required} onChange={(evt) => setState({ ...state, required: evt.currentTarget.checked })} />
                            </div>
                        </div>
                    </div>
                    : <span />}
                {state.control != "SceneContainerControl" ?
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="prefill" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Cacheable?</label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                                <input id="prefill" type="checkbox" defaultChecked={state.prefill} onChange={(evt) => setState({ ...state, prefill: evt.currentTarget.checked })} />
                            </div>
                        </div>
                    </div>
                    : <span />}

            </div>

        </Modal>
    )
}


type FieldDialogProps = {
    title?: string;
    field?: StandardField | SceneControlField;
    saveField(field: Field): void;
    cancelField(): void;
}


const WorkflowFields = ({ fieldSettings, saveFields }) => {
    const fields = JSON.parse(fieldSettings)
    const [state, setState] = useState(fields || [])
    const [createField, setCreateField] = useState<Field | undefined>(undefined)
    const [editingField, setEditingField] = useState<Field | undefined>(undefined)

    const moveElement = (old_index, new_index) => {
        setState(arrayMove(state, old_index, new_index))
    }

    const save = () => {
        saveFields(state)
    }

    useEffect(() => save())

    const deleteField = (index) => {
        let existing = state
        existing.splice(index, 1)
        setState([...existing])
    }

    const findWithAttr = (array, attr, value) => {
        const l = array.length
        for (var i = 0; i < l; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    const addField = (field) => {
        const existing = state
        existing.push(field)
        setState(existing)
    }

    const saveExistingField = (field) => {
        const existing = state
        const index = findWithAttr(existing, "key", field.oldKey)
        existing[index] = field
        setState(existing)
    }

    const editField = (index) => {
        const field = state[index]
        setEditingField(field)
    }

    const saveField = (field) => {

        //If the key is resolution, make sure that there are no empty fields. 
        if (field.key == "resolution") {
            field.choices = field.choices.filter(c => c.label !== undefined && c.value !== undefined && (c as ResolutionChoiceField).previewURL !== undefined);
        }

        if (createField) {
            addField(field)
        } else {
            saveExistingField(field)
        }
        setCreateField(undefined)
        setEditingField(undefined)
    }
    const cancelField = () => {
        setCreateField(undefined)
        setEditingField(undefined)
    }

    return (
        <div className="border mt-8 p-4">
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                    <div className="ml-4 mt-2">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Fields
                        </h3>
                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0">
                        <span className="inline-flex rounded-md shadow-sm">
                            <button onClick={() => setCreateField({})} type="button" className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">
                                Add Field
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            {editingField ? <EditField title="Edit Field" field={editingField} saveField={saveField} cancelField={cancelField} /> : <span />}
            {createField ? <EditField title="Create Field" saveField={saveField} cancelField={cancelField} /> : <span />}
            <table>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Label</th>
                        <th>Help Text</th>
                        <th>Data Type</th>
                        <th>Control (custom)</th>
                        <th>Default value / template</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {state.map((field, i) => {
                        return (
                            <tr key={field.key} className="text-xs hover:bg-brand-500 hover:text-white">
                                <td>{field.key}</td>
                                <td>{field.label}</td>
                                <td>{field.helpText}</td>
                                <td>{field.type}</td>
                                <td>{field.control}</td>
                                <td>{field.value}</td>
                                <td><button className="cursor-pointer" onClick={() => moveElement(i, i - 1)}><FontAwesomeIcon icon={faChevronUp} className="mr-2" /></button></td>
                                <td><button className="cursor-pointer" onClick={() => moveElement(i, i + 1)}><FontAwesomeIcon icon={faChevronDown} className="mr-2" /></button></td>
                                <td><button className="cursor-pointer" onClick={() => editField(i)}><FontAwesomeIcon icon={faEdit} className="mr-2" /></button></td>
                                <td><button className="cursor-pointer" onClick={() => deleteField(i)}><FontAwesomeIcon icon={faTrash} className="mr-2" /></button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}




export default WorkflowFields
