import React, { useRef, useState} from 'react'
import { Query, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { useNavigate } from 'react-router-dom'
import Modal from '../Modal'
import { Mixpanel } from '../../Common/Mixpanel'
import { CompUpload } from '../Video/Upload'

export const CREATE_COMPOSITION_MUTATION = gql`
mutation create($name:String!,$compName:String,$url:String) {
  createComposition(name:$name,compName:$compName,url:$url ) {
    id
    name
    slug
    url
    compName
    createdAt
  }
}
`

type UploadVars = {
    url: string;
}

interface CompositionRouterProps {
}



interface CompositionRouteComponentProps<CompositionRouterProps> {
}


const New = () => {
    let navigate = useNavigate()
    const [uploadVars, setUploadVars] = useState(undefined as UploadVars)
    const onUpload = (url: string) => {
        console.log(url)
        setUploadVars({
            url
        })
    }
    const compositionTitle = useRef<HTMLInputElement>();
    const compositionName = useRef<HTMLInputElement>();
    const [createComposition, data] = useMutation(CREATE_COMPOSITION_MUTATION);
    const save = () => {
        const result = createComposition({ variables: {name: compositionTitle.current.value,compName: compositionName.current.value, url: uploadVars.url }})
        result.then((done) => {
            Mixpanel.track("Created composition", done.data.createComposition)

        }).catch(v => console.error(v))
    }
    const cancel = () => {
        navigate('/dashboard/comps/')
    }
    if(data && data.called && !data.loading && data.data) {
        navigate(`/dashboard/comps/${data.data.createComposition.id}`)
    }
    return (
        <Modal title="New Composition" save={save} cancel={cancel}>
                  <div className="my-4 text-gray-600 text-lg leading-relaxed">
                      <input aria-label="Comp name" name="name" type="string" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Composition Title" ref={compositionTitle}/>
                      <input aria-label="Comp Name" name="" type="compName" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Composition Name (from AE)" ref={compositionName}/>
                                        {uploadVars ? <span>{uploadVars.url}</span> :

                                        <CompUpload onUpload={onUpload} id="new" />
                                    }
                  </div>
        </Modal>
    )
}

export default New

