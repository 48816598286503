import React, { useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Route, Link, useParams } from "react-router-dom";
import { timeDistanceInWords } from "../../helpers/TimeInWords";
import Loading from "../loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal";
import Pagination from "../../Pagination";
import { User, TeamConnections } from "../../types";

interface TeamListData {
  teams: TeamConnections;
}

export const TEAMLIST_QUERY = gql`
  query TeamList($limit: Int, $page: Int, $search: String) {
    teams(limit: $limit, page: $page, search: $search) {
      currentPage
      totalPages
      previousPage
      nextPage
      items {
        id
        title
        displayTitle
        createdAt
        extraData
        socialEnabled
      }
    }
  }
`;

export const ONBOARD_TEAM_MUTATION = gql`
  mutation OnboardTeam($id: ID!) {
    onboardTeam(id: $id) {
      id
      title
      displayTitle
      createdAt
      extraData
      socialEnabled
    }
  }
`;

export const SYNC_TEAM_MUTATION = gql`
  mutation SyncTeam($id: ID!) {
    syncTeam(id: $id) {
      id
      title
      displayTitle
      createdAt
      extraData
      socialEnabled
    }
  }
`;

const TeamDetails = ({ team }) => {
  const extraData = JSON.parse(team.extraData) || {};

  let password = "";
  let email = "";

  if (
    extraData["estate_family_details"] &&
    extraData["estate_family_details"]["data"]
  ) {
    password = extraData["estate_family_details"]["data"]["generatedPassword"];
    email = extraData["email"];
  }

  return (
    <div>
      <div className="flex items-center">
        <div className="text-lg font-bold">Social:&nbsp;</div>
        <div className="text-lg font-light">
          {team.socialEnabled ? "Enabled" : "Disabled"}
        </div>
      </div>
      {password != "" ? (
        <div className="flex flex-col">
          <div className="text-lg font-bold flex">Email:</div>
          <div className="text-lg font-light flex">{email}</div>
          <div className="text-lg font-bold flex">Password:</div>
          <div className="text-lg font-light flex"> {password} </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const TeamList = () => {
  const params = useParams();

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [onboardMutation, onboardData] = useMutation(ONBOARD_TEAM_MUTATION);
  const [syncMutation, syncData] = useMutation(SYNC_TEAM_MUTATION);

  const { loading, error, data, subscribeToMore } = useQuery<TeamListData>(
    TEAMLIST_QUERY,
    { variables: { limit, page, search } },
  );

  const onboardTeam = (id) => {
    const result = onboardMutation({ variables: { id } });
    result.then((done) => {}).catch((v) => console.error(v));
  };

  const syncTeam = (id) => {
    const result = syncMutation({ variables: { id } });
    result.then((done) => {}).catch((v) => console.error(v));
  };

  if (loading) return <Loading />;

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }
  return (
    <div className="flex flex-wrap">
      <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
        <div className="flex justify-between px-6 -mb-px">
          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <div className="text-lg"></div>
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto justify-end">
              <input
                name="search"
                defaultValue={search}
                className="bg-purple-100 shadow rounded border-0 p-3"
                placeholder="Search"
                title="Search"
                onKeyDown={(evt) => {
                  if (evt.keyCode == 13) setSearch(evt.currentTarget.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
          <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4">
            <div className="w-1/6 px-2 flex items-center">
              <div className="text-lg font-bold">Display Title </div>
            </div>
            <div className="w-1/6 px-2 flex items-center">
              <div className="text-lg font-bold">Title </div>
            </div>
            <div className="w-1/2 px-2">
              <div className="text-lg font-bold text-left">Details</div>
            </div>
            <div className="w-1/12 px-2">
              <div className="text-lg font-bold text-right">Created </div>
            </div>
            <div className="w-1/12 px-2">
              <div className="text-lg font-bold text-right">&nbsp; </div>
            </div>
          </div>
          {data.teams.items.map((team, i) => {
            return (
              <div
                className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4"
                key={`user-${team.id}`}
              >
                <div className="w-1/6 px-2 flex items-center">
                  <span className="text-lg">
                    <Link to={`${team.id}`}>
                      {team.displayTitle || team.title}
                    </Link>
                  </span>
                </div>
                <div className="w-1/6 px-2 flex items-center">
                  <span className="text-lg">
                    <Link to={`${team.id}`}>{team.title}</Link>
                  </span>
                </div>
                <div className="w-1/2 px-2 flex items-center">
                  <span className="text-lg">
                    <TeamDetails team={team} />
                  </span>
                </div>
                <div className="w-1/12 px-2">
                  <div className="text-right text-grey">
                    {timeDistanceInWords(team.createdAt)}
                  </div>
                </div>
                <div className="w-1/12 px-2">
                  <div className="text-right text-grey">
                    {team.socialEnabled ? (
                      <button onClick={() => syncTeam(team.id)}>Sync</button>
                    ) : (
                      <button onClick={() => onboardTeam(team.id)}>
                        Onboard
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Pagination paginated={data.teams} setPage={setPage} />
      </div>
    </div>
  );
};
export default TeamList;
