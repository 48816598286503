import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { Query, useMutation } from "react-apollo";
import Loading from "../../../loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../Modal";

const findWithAttr = (array, attr, value) => {
  const l = array.length;
  for (var i = 0; i < l; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};

export const TEMPLATELIST_QUERY = gql`
  query TemplateList {
    templates(limit: 100) {
      items {
        id
        name
        createdAt
      }
    }
  }
`;
const WorkflowTemplate = ({ template, saveTemplate }) => {
  const [state, setState] = useState(template || {});

  const save = () => {
    saveTemplate(state);
  };

  useEffect(() => save());

  return (
    <Query query={TEMPLATELIST_QUERY}>
      {({ loading, error, data, subscribeToMore }) => {
        if (loading) return <Loading />;
        if (error) {
          console.log(error);
          return <div>Error</div>;
        }
        const setTemplate = (id) => {
          const index = findWithAttr(data.templates.items, "id", id);
          const newState = data.templates.items[index];
          setState(newState);
        };

        return (
          <div className="flex flex-row">
            <div className="w-1/4">
              <span className="text-sm leading-5 font-medium text-gray-700">
                Template
              </span>
            </div>
            <div>
              <select
                id="type"
                className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                onChange={(evt) => setTemplate(evt.target.value)}
                defaultValue={state.id ? state.id : ""}
              >
                {!state.type ? (
                  <option value={false} key={false}>
                    [select template]
                  </option>
                ) : (
                  ""
                )}
                {data.templates.items.map((template, i) => {
                  return (
                    <option value={template.id} key={template.id}>
                      {template.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default WorkflowTemplate;
