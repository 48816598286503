import React from 'react'
import {roundedPercentString} from '../helpers/PercentHelper'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import { Link } from 'react-router-dom'
import { AggregatedMetric, Video } from '../types'

const ShowMetricLocation : React.FC<AggregatedMetric> = (metric) => {
    if(!metric.client) return <span />
    return (
        <div className="text-grey" title="Approximate location of user">
            {metric.client.city ?  metric.client.city + " (" + metric.client.country + ")"  :  metric.client.country}
        </div>
    )
}

const ShowMetricVTR : React.FC<AggregatedMetric> = (metric) => {

    return (
        <div className="text-right text-grey" title="View through rate">
        {roundedPercentString(metric.viewThroughRate ? metric.viewThroughRate : 0)}
        </div>
    )
}

const ShowMetricTime : React.FC<AggregatedMetric> = (metric) => {

    return (
        <div className="text-right text-grey">
        {timeDistanceInWords(metric.sessionStart)}
        </div>
    )
}

const LatestBounces : React.FC<Video>  = ({bounces}) => {
        return (
            <div className="w-full mb-6 lg:mb-0 lg:w-1/2 px-4 flex flex-col">
                <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    <div className="border-b">
                        <div className="flex justify-between px-6 -mb-px">
                            <h3 className="text-blue-600 py-4 font-normal text-lg">Latest Bounced</h3>
                        </div>
                    </div>
                    {bounces ? bounces.items.map((metric, i) => {
                        return (
                            <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={i}>
                                <div className="w-3/5 px-4">
                                    <ShowMetricLocation {...metric} />
                                </div>
                                <div className="w-1/5 px-4">
                                    <ShowMetricVTR {...metric} />
                                </div>
                                <div className="w-1/5 px-4">
                                    <ShowMetricTime {...metric} />
                                </div>
                            </div>
                        )})
                        : <span />
                    }
                </div>
            </div>
        )
}

export default LatestBounces
