import gql from 'graphql-tag'
import {WorkflowInstanceConnections, WorkflowConnections, AccountConnections } from '../../../../types'

export interface InstanceListResult {
  adminWorkflowInstances: WorkflowInstanceConnections;
  adminWorkflows: WorkflowConnections;
  accounts: AccountConnections;
}

export const INSTANCE_LIST = gql`
query Instances($limit:Int, $accountId: String, $workflowId: String, $statuses:[WorkflowInstanceStatus!]) {
  adminWorkflowInstances(limit:$limit, sortBy:CreatedAt, sortDirection:DESC, accountId: $accountId, workflowId: $workflowId, statuses: $statuses) {
    items {
      id
      status
      createdAt
      videoWorkflow {
        id
        account {
          id
          title
        }
        title
        category {
          id
          title
        }
      }
      rendering {
        id
        renderrRendering {
          id
          status
          state
          priority
        }
        status
        state
        events(limit:5, sortBy:CreatedAt, sortDirection:DESC) {
          items {
            id
            createdAt
            type
            payload
          }
        }
      }
      viewable {
        id
        embedUrl
        title
      }
      preDeliveryActions {
        id
        title
        createdAt
        startedAt
        failedAt
        doneAt
        status
      }
      preRenderingActions {
        id
        title
        createdAt
        startedAt
        failedAt
        doneAt
        status
      }
      user {
        name
        email
        imageUrl
      }
    }
  }
  adminWorkflows(limit:100, accountId: $accountId) {
    items {
      id
      title
      category {
        id
        title
      }
    }
  }
  accounts(limit:100, page:1) {
    items {
      id
      title
    }
  }
}
`
