import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Query, useQuery, useMutation } from 'react-apollo'
import Loading from '../../../loading'
import { Combobox } from '@headlessui/react'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const TOUCHPOINTLIST_QUERY = gql`
query TouchpointList {
    touchpoints{id, title}
}
`
export const CREATE_TOUCHPOINT_MUTATION = gql`
mutation createTouchpoint($title:String!, $ordering_index: Int) {
    createTouchpoint(title:$title, orderingIndex: $ordering_index)
        {id, title}
} 
`

export const DELETE_TOUCHPOINT_MUTATION = gql`
mutation deleteTouchpoint($id:String!) {
    deleteTouchpoint(id:$id)
    {id, title}
}
`

/*
* Helperfunction to help readability of classnames
https://tailwindui.com/components/application-ui/forms/checkboxes
*/
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Combobox used for selecting, deleting and creating touchpoints
 * Based on component from tailwindui: https://tailwindui.com/components/application-ui/forms/checkboxes
 * @param touchPoint The workflows current touchpoint. Used to set the initial state
 * @param saveTouchpoint Function used to update the state in the parent component
 * @returns 
 */
const TouchpointCombobox = ({ touchpoint, saveTouchpoint }) => {

    const [state, setState] = useState(touchpoint);
    const [query, setQuery] = useState('')

    const save = () => {
        saveTouchpoint(state)
    }

    useEffect(() => save())

    const { loading, data, error, refetch } = useQuery(TOUCHPOINTLIST_QUERY);

    //mutation for saving the changes
    const [createTouchpointMutation, saveResult] = useMutation(CREATE_TOUCHPOINT_MUTATION)
    const [deleteTouchpointMutation, deleteResult] = useMutation(DELETE_TOUCHPOINT_MUTATION)


    const createTouchpoint = ({ title, ordering_index }) => {
        const vars = {};
        vars['title'] = title;

        //checks if the properties exist before assigning them
        if (ordering_index) {
            vars['ordering_index'] = ordering_index;
        }

        const result = createTouchpointMutation({ variables: vars })

        result.then((done) => {

            setState(done.data.createTouchpoint)

        }).catch(v => console.error(v)).finally(() => {

            //when its done writing to the database, refetch the data
            refetch().then(({ data }) => {
                console.log('Refetch is done!', data);

                //resets the query after saving
                setQuery('')
            });
        });
    }

    const deleteTouchpoint = (id) => {
        const vars = {};
        vars['id'] = id;

        const deleteResult = deleteTouchpointMutation({ variables: vars })

        deleteResult.then((done) => {

        }).catch(v => console.error(v)).finally(() => {
            //when its done writing to the database, refetch the data
            refetch().then(({ data }) => {
                console.log('Refetch is done!', data);
            });
        });
    }

    if (loading) return <Loading />

    if (error) {
        console.log(error)
        return <div>Error</div>
    }

    //Filters the touchpoints by name based on the query and returns an array of filtered touchpoints
    const filteredTouchpoints = query === '' ? data.touchpoints : data.touchpoints.filter(touchpoint => {
        return touchpoint.title.toLowerCase().includes(query.toLowerCase());
    });

    return (
        <div className="flex flex-row">
            <div className="w-1/4">
                <span className="text-sm leading-5 font-medium text-gray-700">Touchpoint</span>
            </div>
            <div>
                <Combobox as="div" value={state} defaultValue={"[Select touchpoint]"} by="id" onChange={setState} className=" ">
                    <div className="relative ">
                        <Combobox.Input
                            className="w-full border-0 bg-white pr-10 pl-1 text-gray-900 placeholder-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                            onChange={event => setQuery(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    //If enter is pressed an no touchpoints matches the query, then create a new touchpoint
                                    if (filteredTouchpoints.length === 0) {
                                        createTouchpoint({ title: query });
                                    }
                                }
                            }}
                            placeholder={state ? '' : '[Select touchpoint]'}
                            displayValue={touchpoint => touchpoint?.title}


                        />

                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3" aria-hidden="true" />
                        </Combobox.Button>

                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredTouchpoints.length > 0 ? (
                                filteredTouchpoints.map(touchpoint => (
                                    <Combobox.Option
                                        key={touchpoint.id}
                                        value={touchpoint}
                                        className={({ active }) =>
                                            classNames(
                                                'relative cursor-default select-none pl-2 pr-9',
                                                active ? 'bg-blue-700 text-white' : 'text-gray-900'
                                            )
                                        }
                                    >

                                        {({ active, selected }) => (
                                            <>
                                                <span className={classNames('block truncate', selected && 'font-semibold')}>
                                                    {touchpoint.title}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            active ? 'text-white' : 'text-black'
                                                        )}
                                                    >
                                                        <FontAwesomeIcon icon={faCheck} className="h-3 w-3" aria-hidden="true" />
                                                    </span>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className={classNames(
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            active ? 'text-white' : 'text-black'
                                                        )}
                                                        onClick={(e) => {

                                                            //Don't select the option when clicking the x button
                                                            e.stopPropagation();
                                                            deleteTouchpoint(touchpoint.id)
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faXmark} className="h-3 w-3" aria-hidden="true" />
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            ) : (
                                <Combobox.Option value={null} disabled>
                                    {query === '' ? 
                                    <>
                                        No touchpoints
                                    </> : 
                                    <>
                                        Create touchpoint: "{query}?"
                                    </>}

                                </Combobox.Option>
                            )}
                        </Combobox.Options>
                    </div>
                </Combobox>
            </div>
        </div>




    );
}

export default TouchpointCombobox