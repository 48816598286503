import React, { useState } from 'react'
import { Query, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useParams } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import InstanceForm from './InstanceForm'
import { Mixpanel } from '../../Common/Mixpanel'
import Editor from '../../player-builder/PlayerEditor'
import "ace-builds/src-noconflict/mode-json";
export const WORKFLOWSHOW_QUERY = gql`
query WorkflowInstanceShow($instanceId:ID!) {
    workflowInstance(id:$instanceId) {
        id
        videoWorkflow {
            id
            title
            createdAt
        }
        createdAt
        status
        originalData
        viewable {
            id
            title
            publicPoster
            embedUrl
        }
    }
}
`
const Instance = () => {
    let params = useParams()
    const vars = {workflowId: params.workflowId, instanceId: params.instanceId}
  const [latestMutation, setLatestMutation] = useState(undefined)

    const extract_last_error = (data) => {
        if(data) {
            try{
                const parsedData = JSON.parse(data)
                const parsedError = parsedData && parsedData.last_error ? parsedData.last_error : undefined
                return parsedError
            } catch(err) {
                console.error(err)
            }
        }

        return undefined
    }
    return (
        <Query fetchPolicy="no-cache" query={WORKFLOWSHOW_QUERY} variables={vars}>
            {({ loading, error, data,  refetch  }) => {
                if (loading) return <Loading />
                    if (error) {
                        console.log(error)
                        return <div>Error</div>
                    }
                const instance = data.workflowInstance
                const last_error = extract_last_error(instance.data)
                return (
                    <div>
                        <span className="text-xl block">{data.workflowInstance.videoWorkflow.title}</span>
                        <div className="flex">
                        <div className="w-1/2 p-4">
                                <div className="border-b flex" key={instance.id}>
                                    {instance.viewable ?
                                    (
                                  <a target="_blank" href={instance.viewable.embedUrl} >
                                    <img className="text-right text-grey" src={instance.viewable.publicPoster} />
                                  </a>
                                    ) : <span />}

                                </div>
                        </div>
                                
                                { data.workflowInstance.originalData ? 
                                    <div className="w-1/2 p-4">
                                        <Editor mode="json" value={JSON.stringify(JSON.parse(data.workflowInstance.originalData),  null, '\t')} />
                                    </div>
                                    : <span />}
                        </div>
                    </div>
                )}}
                    </Query>

            )
}

export default Instance
