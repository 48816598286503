import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { Link, useNavigate } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import {Composition, CompositionConnections, SortingField} from '../../types'
import Pagination from '../../Pagination'

interface CompositionRouterProps {
}

interface CompositionResult 
{
  compositions: CompositionConnections
}

export interface ListCompositionsProps {
    compositions: Composition[];
}

export const COMPOSITIONLIST_QUERY = gql`
query CompositionList ($limit: Int, $search: String, $page: Int, $sortDirection: SortingDirection, $sortBy: CompositionSortingField) {
  compositions(limit: $limit, search: $search, page: $page, sortDirection: $sortDirection, sortBy: $sortBy) {
    items {
      name
      slug
      url
      id
      createdAt
      isPublic
    }
    currentPage
    totalPages
    previousPage
    nextPage
    lastPage
    size
    totalItems
    totalPages
  }
}

`

const CompositionList : React.FC<ListCompositionsProps> = ({compositions}) => {
    return (
              <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                {compositions.map((composition, i) => {
                return (
                  <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`composition-${composition.id}`}>
                      <div className="w-4/5 px-4 flex items-center">
                          <span className="text-lg">
                            <Link to={`/dashboard/comps/${composition.id}`}>
                                  {composition.name}
                              </Link>
                          </span>
                      </div>
                      <div className="w-1/5 px-4">
                          <div className="text-right text-grey">
                              {timeDistanceInWords(composition.createdAt)}
                          </div>
                      </div>
                  </div>
                )
            })}
              </div>
    )
}

const Header = ({updateSort}) => {
  return (
  <div className="flex-grow flex px-6 py-1 text-grey-700 items-center border-b -mx-4">
      <div className="w-4/5 px-4  flex items-center">
        <span className="text-lg" onClick={() => updateSort("Name")}>
          Name
        </span>
      </div>
    </div>
  )
}

const List = () => {
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState("CreatedAt" as string)
  const [sortDirection, setSortDirection] = useState(false)

  const updateSort = (newSortBy) => {
    newSortBy == sortBy ? setSortDirection(!sortDirection) : setSortBy(newSortBy)
  }

  const updateSearchValue = (evt) => {
    if(evt.key == "Enter") {
        setSearch(evt.currentTarget.value)
        setPage(1)
    }
  }

  const {loading, error, data } = useQuery<CompositionResult>(COMPOSITIONLIST_QUERY, {variables: {limit, page, search, sortDirection: sortDirection ? "DESC" : "ASC", sortBy: sortBy? sortBy: undefined}, fetchPolicy: 'no-cache'})
  console.log(data)
  if (loading) return <Loading />
  if (error) {
      console.log(error)
      return <div>Error</div>
  }
  return (
    <div className="flex flex-wrap px-4 -mx-4">
        <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
            <div className="flex justify-between mb-2 -mb-px">
            <Header updateSort={updateSort}></Header>
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                  <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto justify-end">
                    <a href="/dashboard/comps/new/composition" className="block mt-4 lg:inline-block lg:mt-0 px-4 py-2 bg-brand-500 font-bold text-sm rounded text-white hover:bg-brand-400 active:bg-brand-600">Create new composition</a>
                  </div>
                  <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => updateSearchValue(evt)} />
                </div>
            </div>
            <CompositionList compositions={data.compositions.items} />
            <Pagination paginated={data.compositions} setPage={setPage} />
        </div>
    </div>
    )
}

export default List
