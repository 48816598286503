import React from 'react'
import {distanceInWords} from '../helpers/TimeInWords'
import {roundedPercentString} from '../helpers/PercentHelper'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import TimeButtons from './TimeButtons'
import {AggregatedStats} from '../types'

const formatEngagementTime = (time? : number) : string => {
  return time ? distanceInWords(time) : 'N/A'
}

const formatPlays = (plays? : number) : number => {
  return plays ? plays : 0;
}

const formatVtr = (vtr?:number) : string => {
  return vtr ? roundedPercentString(vtr) : 'N/A'
}

const formatUniquePlays = (value?:number, decimalPlaces: number = 1) : number => {
  const factor = decimalPlaces * 10;
  const rounded = Math.round((value + Number.EPSILON) * factor ) / factor
  return rounded
}


export interface StatsPanelProps {
  title: string;
  value: any;
  description: string;
  icon: IconProp;
}

export const StatsPanel : React.FC<StatsPanelProps> = ({title, value, description, icon}) => {
  return (
          <div className="lg:w-1/4 text-center py-2 lg:py-4">
            <div className="lg:border-r">
              <div className="text-grey-700 mb-2">
                <span className="text-xl lg:text-5xl">{value}</span>
              </div>
              <div className="text-xs lg:text-sm uppercase text-grey-500 tracking-wide">
                {title}
                <FontAwesomeIcon icon={icon} title={description} className="ml-2 hidden lg:inline" />
              </div>
            </div>
          </div>
  )
}

const QuickStats : React.FC<AggregatedStats> = ({engagementTime, plays, viewThroughRate, playsPerUniqueVideo}) => {
  const formattedEngagementTime = formatEngagementTime(engagementTime ? engagementTime : 0)
  const formattedPlays = formatPlays(plays ? plays : 0);
  const formattedPerVideo = formatUniquePlays(playsPerUniqueVideo ? playsPerUniqueVideo : 0);
  const vtr = formatVtr(viewThroughRate ? viewThroughRate : 0);

  return (
    <div>
      <div className="relative lg:w-full flex lg:justify-end">
        <TimeButtons />
      </div>
      <div className="bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow mb-6">
        <div className="flex flex-col lg:flex-row">
          <StatsPanel title="Views" icon={faInfoCircle} description="Unique views of more than 3 seconds" value={formattedPlays} />
          <StatsPanel title="Views per Video" icon={faInfoCircle} description="Views per video viewed in period" value={formattedPerVideo} />
          <StatsPanel title="View Through Rate" icon={faInfoCircle} description="Average view through rate" value={vtr} />
          <StatsPanel title="Engagement time" icon={faInfoCircle} description="Total time spent watching content" value={formattedEngagementTime} />
        </div>
      </div>
    </div>
  )
}

export default QuickStats
