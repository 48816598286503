import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useParams} from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'
import Modal from '../Modal'
import Pagination from '../../Pagination'
import {User, UserConnections} from '../../types'


interface UserListData {
  users: UserConnections;
}

export const USERLIST_QUERY = gql`
query UserList($limit: Int, $page: Int, $search: String) {
  users(limit:$limit, page: $page, search: $search) {
    currentPage
    totalPages
    previousPage
    nextPage
    items {
      id
      email
      name
      createdAt
      lastSignInAt
      isAdmin
      imageUrl
      instanceCount
      appVersion
      deviceName
      osVersion
      teams {
        id
        title
      }

      level {
        title
      }

    }
  }
}
`

export const CREATE_USER_MUTATION = gql`
mutation CreateUser($email: String!, $name: String, $isAdmin: Boolean)  {
  createUser(email: $email, name: $name, isAdmin:$isAdmin) {
    id
    isAdmin
    name
    email
  }
}
`

export const UPDATE_USER_MUTATION = gql`
mutation UpdateUser($id: String, $name: String, $isAdmin: Boolean)  {
  updateUser(id: $id, name: $name, isAdmin:$isAdmin) {
    id
    isAdmin
    name
    email
  }
}
`

type EditingUser = User & {
  password?: string;
}

interface EditUserProps {
  title: string;
  user?: EditingUser;
  saveUser: any;
  cancelUser: any;
}

const EditUser : React.FC<EditUserProps> = ({title, user, saveUser, cancelUser}) => {
  const [state, setState] = useState(user)

  const setName = (name) => {
    setState({...state, name})
  }

  const setEmail = (email) => {
    setState({...state, email})
  }

  const setIsAdmin = (isAdmin) => {
    setState({...state, isAdmin})
  }

  return (
    <Modal title={title} save={() => { saveUser(state)}} cancel={cancelUser} containerClassNames="relative w-1/2 my-6 mx-auto max-w-full">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8"  >
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
            Name
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
              <input id="name" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.name} onChange={(evt) => setName(evt.currentTarget.value) }/>
            </div>
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
            Email
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
              <input id="email" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.email} onChange={(evt) => setEmail(evt.currentTarget.value) } readOnly={user.id != undefined} />
            </div>
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="isAdmin" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Admin?</label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex rounded-md shadow-sm">
              <input type="checkbox" id="isAdmin" className="form-checkbox" defaultChecked={state.isAdmin} onChange={(evt) => setIsAdmin(evt.currentTarget.checked) }/>
            </div>
          </div>
        </div>


      </div>
    </Modal>
  )
}


const UserList = () => {
  const params = useParams()
  const [createUser, setCreateUser] = useState<User | undefined>(undefined)
  const [editingUser, setEditingUser] = useState<User | undefined>(undefined)
  const [createMutation, createData] = useMutation(CREATE_USER_MUTATION);
  const [updateMutation, updateData] = useMutation(UPDATE_USER_MUTATION);

  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")

  const editUser = (user) => {
    setEditingUser(user)
  }

  const saveUser = (data) => {
    const mutation = createUser ? createMutation : updateMutation


    const result = mutation({ variables: data })
    result.then((done) => {
    }).catch(v => console.error(v))

    setCreateUser(undefined)
    setEditingUser(undefined)
  }
  const cancelUser = () => {
    setCreateUser(undefined)
    setEditingUser(undefined)
  }

  const deleteUser = (something) => {
  }


  const { loading, error, data, subscribeToMore } = useQuery<UserListData>(USERLIST_QUERY, {variables: {limit, page, search}})

      if (loading) return <Loading />

      if (error) {
        console.log(error)
        return <div>Error</div>
      }
  return (
      <div className="flex flex-wrap">
        <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
          <div className="flex justify-between px-6 -mb-px">

            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
              <div className="text-lg">
                <button className="block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase text-black hover:text-brand-500" onClick={() => setCreateUser({} as User)}>
                  New
                </button>
              </div>
              <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto justify-end">
                <input name="search" defaultValue={search} className="bg-purple-100 shadow rounded border-0 p-3" placeholder="Search" title="Search" onKeyDown={(evt) => { if(evt.keyCode == 13)  setSearch(evt.currentTarget.value) } } />
              </div>
            </div>

          </div>
          {editingUser ? <EditUser title="Edit User" user={editingUser} saveUser={saveUser} cancelUser={cancelUser} /> : <span />}
          {createUser ? <EditUser title="Create User" user={{name: '', email: '', isAdmin: false}} saveUser={saveUser} cancelUser={cancelUser} /> : <span />}
          <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
            <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" >
              <div className="w-1/12 px-2 flex items-center">
                <div className="text-lg font-bold">&nbsp;</div>
              </div>
              <div className="w-1/12 px-2 flex items-center">
                <div className="text-lg font-bold">Name </div>
              </div>
              <div className="w-1/12 px-2 flex items-center">
                <div className="text-lg font-bold">Email </div>
              </div>
              <div className="w-1/6 px-2">
                <div className="text-lg font-bold text-center">Teams </div>
              </div>
              <div className="w-1/12 px-2">
                <div className="text-lg font-bold text-center">Level </div>
              </div>
              <div className="w-1/12 px-2">
                <div className="text-lg font-bold text-center">Videos </div>
              </div>
              <div className="w-1/6 px-2">
                <div className="text-lg font-bold text-right">Created </div>
              </div>
              <div className="w-1/6 px-2">
                <div className="text-lg font-bold text-right">Details</div>
              </div>
              <div className="w-1/12 px-2">
                <div className="text-lg font-bold text-right">&nbsp;</div>
              </div>
            </div>
            {data.users.items.map((user, i) => {
              return (
                <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={`user-${user.id}`}>
                  <div className="w-1/12 px-2 flex items-center">
                    <span className="text-lg">
                      {user.imageUrl ? 
                      <Link to={`${user.id}`}>
                        <img src={user.imageUrl} className="w-32 p-2 mr-2" />
                      </Link>
                        : <span />}
                    </span>
                  </div>
                  <div className="w-1/12 px-2 flex items-center">
                    <span className="text-lg">
                      <Link to={`${user.id}`}>
                        {user.name}
                      </Link>
                    </span>
                  </div>
                  <div className="w-1/12 px-2 flex items-center">
                    <span className="text-lg">
                      <Link to={`${user.id}`}>
                        {user.email}
                      </Link>
                    </span>
                  </div>
                  <div className="w-1/6 px-2 items-center">
                    <div className="text-center text-grey">
                      {user?.teams.map((team, i) => {
                        return (
                          <p key={team.id}>{team.title}</p>
                        )
                      })}
                    </div>
                  </div>
                  <div className="w-1/12 px-2 items-center">
                    <div className="text-center text-grey">
                      {user?.level?.title}
                    </div>
                  </div>
                  <div className="w-1/12 px-2 items-center">
                    <div className="text-center text-grey">
                      {user?.instanceCount || 0}
                    </div>
                  </div>
                  <div className="w-1/6 px-2">
                    <div className="text-right text-grey">
                      {timeDistanceInWords(user.createdAt)}
                    </div>
                  </div>
                  <div className="w-1/6 px-2">
                    <div className="text-right text-grey">
                      {user.isAdmin ? <div>Admin: yes</div> : <span />}
                      Device: {user.deviceName}<br />
                      Os Version: {user.osVersion}<br />
                      App Version: {user.appVersion}<br />
                    </div>
                  </div>
                  <div className="w-1/12 px-2">
                    <div className="text-right text-grey">
                      <span><button className="cursor-pointer" onClick={() => editUser(user) }><FontAwesomeIcon icon={faEdit} className="mr-2" /></button></span>
                      <span><button className="cursor-pointer" onClick={() => deleteUser(user)}><FontAwesomeIcon icon={faTrash} className="mr-2" /></button></span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <Pagination paginated={data.users} setPage={setPage} />
        </div>
      </div>
      )
}
export default UserList
