import React, { useState, useRef, useEffect } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import Loading from '../../../loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Modal from '../../../Modal'

import Editor from '../../../../player-builder/PlayerEditor'

const findWithAttr = (array, attr, value) => {
    const l = array.length
    for (var i = 0; i < l; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}


export const WORKFLOW_WORKERS_QUERY = gql`
query Workers {
 workflowWorkers {
    id
    stepName
    workerName
  }
}
`


const EditStep = ({ title, step = {}, saveStep, cancelStep }) => {
    const [state, setState] = useState({ ...step, selectWorker: step.worker ? step.worker.id : false })


    return (
        <Query query={WORKFLOW_WORKERS_QUERY} variables={{}}>
            {({ loading, error, data, subscribeToMore }) => {
                if (loading) return <Loading />
                if (error) {
                    console.log(error)
                    return <div>Error</div>
                }
                const Workers = data.workflowWorkers
                const setWorker = (id) => {
                    const existing = state
                    existing["selectWorker"] = id
                    existing["worker"] = Workers[findWithAttr(Workers, "id", id)]
                    setState(existing)
                }

                return (
                    <div>
                        
                        <Modal title={title} save={() => saveStep(state)} cancel={cancelStep} containerClassNames="relative w-1/2 my-6 mx-auto max-w-full">
                            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="key" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                        Title
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                            <input id="title" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.title} onChange={(evt) => setState({ ...state, title: evt.target.value })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="worker" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Worker</label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                            <select id="worker" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={(evt) => setWorker(evt.target.value)} defaultValue={state.selectWorker}>
                                                {!state.selectWorker ? <option value={false} key={false}>[select worker]</option> : ''}
                                                {Workers.map((value) => {
                                                    return (
                                                        <option value={value.id} key={value.id}>{value.workerName} - {value.stepName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="settings" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Settings</label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <div className="max-w-lg flex rounded-md shadow-sm">
                                            <textarea id="value" rows="5" className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={state.settings} onChange={(evt) => setState({ ...state, settings: evt.target.value })} ></textarea>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </Modal>
                    </div>

                )
            }}
        </Query>
    )
}

const WorkflowSteps = ({ steps, saveSteps, stepsObject, saveStepsObject}) => {
    const [state, setState] = useState(steps || [])

    const [createStep, setCreateStep] = useState(false)
    const [editingStep, setEditingStep] = useState(false)
    const [editingStepObject, setEditingStepObject] = useState(false)
    
    const localData = useRef();

    const updateLocalData = (value) => {
        localData.current = value
    }

    const save = () => {
        saveStepsObject(localData.current)
        saveSteps(state)
    }

    useEffect(() => save())

    const addStep = (step) => {
        const existing = state
        existing.push(step)
        setState(existing)
    }

    const saveExistingStep = (step) => {
        const existing = state
        const index = findWithAttr(existing, "id", step.id)
        existing[index] = step
        setState(existing)
    }

    const saveStep = (step) => {
        if (createStep) {
            addStep(step)
        } else {
            saveExistingStep(step)
        }
        setCreateStep(false)
        setEditingStep(false)
    }
    const cancelStep = () => {
        setCreateStep(false)
        setEditingStep(false)
    }

    const editStep = (i) => {
        const step = state[i]
        setEditingStep(step)
    }

    const deleteStep = (index) => {
        let existing = state
        existing.splice(index, 1)
        setState([...existing])
    }

    return (
        <div className="border mt-8 p-4">
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                    <div className="ml-4 mt-2">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Steps
                        </h3>
                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0">
                        <span className="inline-flex rounded-md shadow-sm">

                            <button onClick={() => setEditingStepObject(true)} type="button" className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 mr-2">
                                Edit steps object
                            </button>

                            <button onClick={() => setCreateStep({})} type="button" className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 ml-2">
                                Add Step
                            </button>


                        </span>
                    </div>
                </div>
            </div>
            {editingStep ? <EditStep title="Edit Step" step={editingStep} saveStep={saveStep} cancelStep={cancelStep} /> : <span />}
            {editingStepObject ? <Modal title="Edit steps object" save={() => {save; setEditingStepObject(false)}} cancel={() => setEditingStepObject(false)} show={true}  containerClassNames="w-3/4" >
                                    <Editor mode="json" update={updateLocalData} value={JSON.stringify(JSON.parse(stepsObject), null, '\t')} />
                                </Modal> : <span />}
            {createStep ? <EditStep title="Create Step" saveStep={saveStep} cancelStep={cancelStep} /> : <span />}
            <table>
                <thead>
                    <tr>
                        <th>Worker Title</th>
                        <th>Step Name </th>
                        <th>Worker Name</th>
                        <th>Settings (json)</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {state.map((step, i) => {
                        return (
                            <tr key={"step-" + i} className="text-xs">
                                <td> {step.title} </td>
                                <td>{step.worker ? step.worker.stepName : 'N/A'} </td>
                                <td>{step.worker ? step.worker.workerName : 'N/A'} </td>
                                <td>{step.settings}</td>
                                <td><button className="cursor-pointer" onClick={() => editStep(i)}><FontAwesomeIcon icon={faEdit} className="mr-2" /></button></td>
                                <td><button className="cursor-pointer" onClick={() => deleteStep(i)}><FontAwesomeIcon icon={faTrash} className="mr-2" /></button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}


export default WorkflowSteps
