import gql from 'graphql-tag'
import { Caption, Video } from '../types'

export interface CaptionResult {
  caption: Caption;
  video: Video;
}

export interface AlternativeWord {
    word: string;
    distance: number;
}


export type Word = {
    type: 'word';
    text: string;
    originalWord: string;
    startTime?: number;
    endTime?: number;
    duration?: number;
    sentenceStart?: boolean;
    confidence?: number;
    alternatives?: AlternativeWord[];
}


export interface CaptionEntry {
    type: 'captionentry';
    startTime: number;
    endTime: number;
    changed?: boolean;
    text?: string;
    children: Word[];
}

export interface CaptionFlowEntry {
    inPoint: number;
    outPoint: number;
    content?: string;
}

export function captionEntryToCaptionFlowEntry(entry: CaptionEntry) : CaptionFlowEntry {
    return {
        inPoint: entry.startTime,
        outPoint: entry.endTime,
        content: entry.text
    }
}

export function captionFlowEntryToCaptionEntry(entry: CaptionFlowEntry) : CaptionEntry {
    return {
        type: 'captionentry',
        changed: true,
        startTime: entry.inPoint,
        endTime: entry.outPoint,
        text: entry.content,
        children: []
    }
}

export function captionListToCaptionFlowEntryList(list: CaptionList) : CaptionFlowEntry[] {
    return list.entries.map( item => captionEntryToCaptionFlowEntry(item))
}

export function captionFlowListToCaptionList(list : CaptionFlowEntry[]) : CaptionList {
    return {
        entries: list.map( item => captionFlowEntryToCaptionEntry(item))
    }
}

export interface CaptionList {
    entries: CaptionEntry[];
}

export interface Sentence {
    type: 'sentence';
    sentence: string;
    start_time: number;
    end_time: number;
    words: Word[];
}

export interface TranscriptionWord {
    word: string;
    offset: string;
    duration: string;
    confidence: number;

}

export interface TranscriptionResult {
    words: TranscriptionWord[];
}

export const CAPTIONEDIT_QUERY = gql`
query CaptionEdit($captionId:ID!, $videoId:ID!) {
    caption(id:$captionId) {
        id
        label
        text
        isDefault
        captionType
        language
        source
        transcriptionSource
        createdAt
        updatedAt
    }
    captionFlowSettings(captionId:$captionId)
    {
        backgroundColor {
            r,g,b,a
        }
        fontColor {
            r,g,b,a
        }
        font {
            fontFamily
            fontUrl
        }
        fontSize
        resolution
        renderingResolution {
            force
            width
            height
        }
        textPosition {
            align
            valign
            x
            y
            maxWidth
        }
    }
    video(id:$videoId) {
        id
        hasFile
        filePresent
        title
        embedUrl
        publicPoster
        originalPublicUrl
        publicUrl
        versions {
            hasFile
            filePresent
            publicUrl
            mimeType
            label
            resolution
        }
        channel {
            id
            account {
                id
            }
        }
        workflowInstance{
            id
            originalData
        }
    }
}
`

export const CAPTIONEDIT_UPDATE_MUTATION = gql`
mutation UpdateCaptions($id:ID!,$source:JSON, $style:JSON, $text:String, $captionType:String, $language:String) {
  updateCaption(id:$id, source:$source, style:$style, text:$text, captionType: $captionType, language: $language) {
         id
        label
        text
        isDefault
        captionType
        language
        source
        style
        transcriptionSource
        createdAt
        updatedAt

  }
}
`


export function ApiResultToCaptionFlow() {
}
