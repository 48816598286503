import React, { useRef } from 'react'
import { Query, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useParams} from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import Editor from './../../player-builder/PlayerEditor'
import { Mixpanel } from '../../Common/Mixpanel'

// import "@fortawesome/fontawesome-free/css/all.css";

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";


export const PLAYERSHOW_QUERY = gql`
query PlayerShow($id:ID!) {
    player(id:$id) {
      title
      id
      html
      styles
    }
}
`

export const SAVE_PLAYER_MUTATION = gql`
mutation savePlayer($id:String!, $title:String, $html:String, $styles:String) {
  updatePlayer(id:$id, title:$title, html:$html, styles:$styles) {
    id
    title
    html
    styles
  }
}
`

const Show = () => {
    let params = useParams()
    let localData = useRef({});
    const updateData = (field, value) => {
        localData.current[field] = value
    }
    const updateHtml = (value) => {
        updateData('html', value)
    }

    const updateCss = (value) => {
        updateData('css', value)
    }

    const updateTitle = (value) => {
        updateData('title', value)
    }

    const [savePlayer, { data }] = useMutation(SAVE_PLAYER_MUTATION);

    const save = () => {
        const vars = { 
            id: params.playerId,
            title: localData.current['title'],
            html: localData.current['html'],
            styles: localData.current['css'],

        }
        Mixpanel.track("Saving player", {id: params.playerId, title: vars.title}) 
        const result = savePlayer({ variables: vars })
        result.then((done) => {
            console.log(done)
            Mixpanel.track("Saved player", {id: params.playerId, title: vars.title}) 
        })
    }
    const vars = {id: params.playerId}
    return (
        <Query query={PLAYERSHOW_QUERY} variables={vars}>
            {({ loading, error, data, subscribeToMore }) => {
                if (loading) return <Loading />
                    if (error) {
                        console.log(error)
                        return <div>Error</div>
                    }
              if(data) {
                  updateHtml(data.player.html)
                  updateCss(data.player.styles)
                  updateTitle(data.player.title)
              }
                return (
                  <div className="h-full p-4 px-3 py-10 bg-grey-300 flex justify-center mx-auto align-middle">
                      <div className="w-full">
                          <input defaultValue={data.player.title} className="w-1/2" onChange={(e) => updateTitle(e.target.value)}/>
                          <Editor mode="html" update={updateHtml} value={data.player.html} />
                          <hr className="my-4" />
                          <Editor mode="css" update={updateCss} value={data.player.styles} />
                          <hr className="my-4" />
                          <button onClick={save} className="bg-brand-500 hover:bg-blue-700 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline">Save</button>
                      </div>
                  </div>
                )}}
                    </Query>

            )
}

export default Show
