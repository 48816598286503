import React, { useState } from "react";
import { Routes, Route, Outlet, NavLink } from "react-router-dom";

const NotYetCreated = () => {
  return (
    <div className="flex flex-wrap">
      <h1>Not yet created</h1>
      <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
        <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
          <p>
            This dashboard will be implemented in the future; context
            claus@fliva.com if you really need this now!
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotYetCreated;
