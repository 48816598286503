import React, {useContext} from 'react'
import { useQuery } from 'react-apollo'
import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'
import { DashboardTimeContext} from '../../stores/TimePeriod'
import Loading from '../loading'
import QuickStats from '../QuickStats'
import ViewGraphs from '../ViewGraphs'
import LatestVideos from '../LatestVideos'
import LatestEvents from '../LatestEvents'
import { ChannelWithGraph } from '../../custom_types'

export const CHANNELSHOW_QUERY = gql`
query ChannelShow($id:ID!,$limit: Int!,$period: TimePeriod!, $resolution: AggregationPeriod!, $startTime: Date, $endTime: Date) {
    channel(id:$id) {
        id
        title
        videoConnections(limit: $limit) {
            items {
                id
                createdAt
                channel {
                    id
                    title
                }
                tags {
                    id
                    name
                }
                publicPoster
                metadata
                title
                stats(period: $period, startTime: $startTime, endTime: $endTime) {
                    plays
                    engagementTime
                    playsPerUniqueVideo
                    viewThroughRate
                }
            }
        }
        latestPlayedVideos(limit: $limit, unique: true) {
            id
            title
            createdAt
            channel {
                id
                title
            }
            tags {
                id
                name
            }
            publicPoster
            title
        }
        graph: stats(period: $period, startTime: $startTime, endTime: $endTime) {
            data: playsPer(period: $resolution) {
                key
                value
            }
        }
        stats: stats(period: $period, startTime: $startTime, endTime: $endTime) {
            plays
            playsPerUniqueVideo
            engagementTime
            viewThroughRate
        }       createdAt
    }
}
`


const Dashboard : React.FC<ChannelWithGraph> =  ({title, stats, graph, videoConnections, latestPlayedVideos}) => {
    return (
        <div className="flex-grow w-full">
        <h1>{title}</h1>
        <div className="flex-grow mx-auto sm:px-4 pt-6 pb-8">
            <QuickStats  {...stats}/>
            <ViewGraphs list={graph} />
            <div className="flex flex-wrap -mx-4">
                <LatestVideos videos={videoConnections.items} />
                <LatestEvents videos={latestPlayedVideos} />
            </div>
        </div>
        </div>
    )
}


interface ChannelWithGraphEntry {
    channel: ChannelWithGraph;
}

const Show = () => {
    const params = useParams()
    const {timePeriod} = useContext(DashboardTimeContext);
    const vars = {
        id: params.channelId,
        limit: timePeriod.listLimit,
        period: timePeriod.graphPeriod.period,
        resolution: timePeriod.graphPeriod.resolution,
        startTime: timePeriod.startTime,
        endTime: timePeriod.endTime
    }

    const { loading, data, error } = useQuery<ChannelWithGraphEntry>(CHANNELSHOW_QUERY, {variables: vars});
    if (loading) return <Loading />
        if (error) {
            console.log(error)
            return <div>Error</div>
        }

    return (
        <div>
            <div className="flex flex-wrap">
                <Dashboard {...data.channel}  />
            </div>
        </div>
    )

}

export default Show
