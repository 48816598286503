import React, { useRef } from 'react'
import { Query, useMutation} from 'react-apollo'
import gql from 'graphql-tag'
import { useNavigate, useParams} from 'react-router-dom'
import Modal from '../../../../Modal'
import { DELETE_SUPPORT_PAGE_QUESTION_MUTATION } from '../Query'
import { Mixpanel } from '../../../../../Common/Mixpanel'



const Delete = () => {
    console.log("hello")
    const { questionId } = useParams();
    console.log("new: " + questionId)
    let navigate = useNavigate()
    const question = useRef<HTMLInputElement>();
    const [deleteQuestion, data] = useMutation(DELETE_SUPPORT_PAGE_QUESTION_MUTATION);

    const save = () => {
        // console.log("question.current.value: " +question.current.value)
        const result = deleteQuestion({ variables: {id: questionId}})
        result.then((done) => {
            console.log(done.data)
            Mixpanel.track("deleted Question", done.data.deleteSupportPageQuestion) 
            // alert("deleted question")

        }).catch(v => console.error(v))
    }
    const cancel = () => {
        navigate(-1)
    }
    if(data && data.called && !data.loading && data.data) {
        navigate(-1)
    }
    return (
        <Modal title="Delete question?" save={save} cancel={cancel} buttonText={"Delete"} 
        saveButtonClassNames={"bg-red-500 text-black active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"}
        cancelButtonClassNames={"text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"} >
                  {/* <div className="my-4 text-gray-600 text-lg leading-relaxed">
                      <input aria-label="Email address" name="email" type="email" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Channel Title" ref={question}/>
                  </div> */}
        </Modal>
    )
}


export default Delete

