import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Routes, Route, Link, Outlet } from 'react-router-dom'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import List from './Rendering/List'
import Show from './Rendering/Show'
import Edit from './Rendering/Edit'
import { Mixpanel } from '../Common/Mixpanel'

const RenderingDashboard = () => {
    return (
                <div>
                    <h1 className="text-3xl text-black pb-4">Renderings</h1>
                    <div className="flex flex-wrap">
                        <div className="flex-grow w-full mx-auto">
                            <Routes>
                            <Route path={`:renderingId/edit`} element={<Edit  />} />
                            <Route path={`:renderingId`}  element={<Show  />} />
                            <Route index element={<List  />} />
                            </Routes>
                            <Outlet />
                        </div>
                    </div>
                </div>
    )
}

export default RenderingDashboard
