import React from 'react'
import {roundedPercentString} from '../helpers/PercentHelper'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import { Link } from 'react-router-dom'

import { MetricsList } from '../custom_types'

const LatestMetrics : React.FC<MetricsList> = ({metrics}) => {
        return (
            <div className="w-full mb-6 lg:mb-0 lg:w-1/2 px-4 flex flex-col">
                <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                    <div className="border-b">
                        <div className="flex justify-between px-6 -mb-px">
                            <h3 className="text-blue-600 py-4 font-normal text-lg">Latest Played</h3>
                        </div>
                    </div>
                    {metrics.items.map((metric, i) => {
                        return (
                            <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={i}>
                                <div className="w-3/5 px-4">
                                    <div className="text-grey" title="Approximate location of user">
                                        {metric?.client?.city ?  metric?.client?.city + " (" + metric?.client?.country + ")"  :  metric?.client?.country}
                                    </div>
                                </div>
                                <div className="w-1/5 px-4">
                                    <div className="text-right text-grey" title="View through rate">
                                        {roundedPercentString(metric.viewThroughRate)}
                                    </div>
                                </div>
                                <div className="w-1/5 px-4">
                                    <div className="text-right text-grey">
                                        {timeDistanceInWords(metric.sessionStart)}
                                    </div>
                                </div>
                            </div>
                        )})}
                </div>
            </div>
        )
}

export default LatestMetrics

