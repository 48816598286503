import React from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { useNavigate, useLocation } from 'react-router-dom'
import { Routes, Route, Link, Outlet } from 'react-router-dom'
import Loading from '../dashboard/loading'

export const FORM_QUERY = gql`
query Form($path:String, $host:String) {
    interactionForm(formType: "embed", path: $path, host: $host){
        id
        title
        settings
    }

}
`

const MultiformSection = ({options}) => {
    return <div>
        <ul>
        {options.map((option) => {
            return <li>
                <input type="checkbox" /><span>{option.title}</span>
            </li>
        })}
    </ul>
    </div>
}

const MessageSection = ({settings}) => {
    let location = useLocation();
    const values = Object.fromEntries(new URLSearchParams(location.search));
    const caseId = values.caseId ? values.caseId : values.caseld ? values.caseld : ''
    const customer = values.customer ? values.customer : ''
    const subject = `Reklamation ${customer} ${caseId}`
    return (
        <div>
            <p className="text-sm">Reklamationer sendes midlertidigt direkte på mail til <a href={`mailto:support@fliva.com?subject=${subject}`} className="underline text-brand-500">support@fliva.com</a></p>
            <p className="text-xs text-grey-400">Ny formular på vej</p>
        </div>

        )

}

const Section = (props) => {
    switch(props.type) {
        case 'multiform':
        return <MultiformSection options={props.options} />

        case 'message':
        return <MessageSection settings={props.settings} />

        default:
        return <span>Type: {props.type} not found</span>;
    }
}

const Form = ({header, logo, sections}) => {
    return <div>
        {logo ? <img src={logo} /> : <span />}
        <h1 className="text-xl">{header}</h1>
        <div>
            {sections.map((section, i) => {
                return <Section {...section} key={i} />
            })}
        </div>
    </div>

}

const FormFromString = ({settings}) => {
    const data = JSON.parse(settings)
    return <Form {...data.content} />
}

const Container = () => {
  let location = useLocation();
    const {loading, error, data, refetch } = useQuery(FORM_QUERY, {variables: {host: window.location.hostname, path: location.pathname}})

  if (error) {
    console.log(error)
    return <div>Error</div>
  }
  if (loading) return <Loading />


  return data.interactionForm ? <FormFromString settings={data.interactionForm.settings} /> : <h1>Not found</h1>
}


export default Container;
