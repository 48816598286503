import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Query, useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import Loading from '../loading'

export const USER_QUERY = gql`
query ToolsUser {
    currentUser {
        id
        isAdmin
        impersonatedAccount {
          id
          title
          toolSettings
        }
        account {
          siteAdmin
          title
          toolSettings
        }
    }
}
`

const ToolFrame = ({settings}) => {
  console.log("ToolFrame")
  console.log(settings)
  return <iframe src={settings.url} className="w-full h-full fixed" height="100%" width="100%"></iframe>
}


const Tool = () => {
  const { loading, data, error } = useQuery(USER_QUERY);
  console.log(data)
  if(loading) {
    return <Loading />
  }

  if(error) {
    console.log(error)
    return <div>Error</div>
  }
  if(data.currentUser.impersonatedAccount) {
    console.log("IMPERSONATED")
    if(data.currentUser.impersonatedAccount.toolSettings) {
      console.log("FOUND")
      return <ToolFrame settings={JSON.parse(data.currentUser.impersonatedAccount.toolSettings)} />
    } else {
      console.log("NOT FOUND")
      return <h1>No tool for {data.currentUser.impersonatedAccount.title}</h1>
    }
  } else if (data.currentUser.account) {
    console.log("ACCOUNT")
    if(data.currentUser.account.toolSettings) {
      return <ToolFrame settings={JSON.parse(data.currentUser.account.toolSettings)} />
    } else {
      return <h1>No tool for {data.currentUser.account.title}</h1>
    }
  } else {
    return <h1>No tool for account</h1>
  }
}

export default Tool
