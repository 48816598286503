import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Routes, Route, Link, Outlet, useParams} from 'react-router-dom'
import Loading from '../loading'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import { v4 as uuidv4 } from 'uuid';
import { Mixpanel } from '../../Common/Mixpanel'


export const ENTRY_QUERY = gql`
query Entry($id:String!) {
  textTemplateCollection(id:$id) {
    id
    title
    textTemplates {
      id
      constraints {
        path
        comparator
        value
      }
      template
      title
      rating
    }
  }
}
`

export const SAVE_ENTRY_MUTATION = gql`
mutation UpdateEntry($entryId:String!, $title:String!, $constraints:JSON, $template:String, $rating: Int) {
  updateTextTemplate(templateId:$entryId, , title:$title, constraints:$constraints, template:$template, rating: $rating) {
    id
      constraints {
        path
        comparator
        value
      }
      template
      title
      rating
    }
}
`



export const CREATE_ENTRY_MUTATION = gql`
mutation CreateEntry($collectionId:String!, $title:String!, $constraints:JSON, $template:String) {
  createTextTemplate(collectionId:$collectionId, title:$title, constraints:$constraints, template:$template) {
    id
      constraints {
        path
        comparator
        value
      }
      template
      title
      rating
    }
  }
`

export const GET_PASSING_QUERY = gql`
query Entry($collectionId:String!, $data:JSON!) {
  textTemplateCollection(id:$collectionId) {
    matching(data:$data) {
      id
    }
  }
}
`

export const GENERATE_QUERY = gql`
query Entry($collectionId:String!, $data:JSON!, $strategy:TextTemplateStrategy!) {
  textTemplateCollection(id:$collectionId) {
    generate(data:$data, strategy:$strategy)
  }
}
`

const Constraint = ({path, comparator, value, onChange, onDelete}) => {
  const [constraintPath, setConstraintPath] = useState(path)
  const [constraintComparator, setConstraintComparator] = useState(comparator)
  const [constraintValue, setConstraintValue] = useState(value)

  const changed = (constraintPath != path || constraintComparator != comparator || constraintValue != value)

  const possible_values = ['contain', 'start_with', 'end_with', '==', '>', '>=', '<', '<=',  '!=',  'present', 'missing']

  const rowClass = changed ? 'flex mt-2 bg-red-200 p-2' : 'flex mt-2 bg-green-200 p-2'

  return (
    <div className={rowClass}>
      <input value={constraintPath} onChange={(evt) => {setConstraintPath(evt.target.value)}} />
      <select defaultValue={constraintComparator} onChange={(evt) => setConstraintComparator(evt.target.value)}>
          {possible_values.map(( option ) => {
            return <option value={option}>{option}</option>
          })}
        </select>
        <input value={constraintValue} onChange={(evt) => {setConstraintValue(evt.target.value)}} />
        <button className="relative inline-flex items-center px-2 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700" onClick={() => onChange(constraintPath, constraintValue, constraintComparator)}>Save</button>
        <button className="relative inline-flex items-center px-2 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700" onClick={() => onDelete()}>Remove</button>
    </div>
  )
}


const EntryMapConstraints = ({template, save}) => {
  for(let i=0; i<template.constraints.length;i++) {
  template.constraints[i].id = uuidv4()
  }
  return <Entry template={template} save={save} />
}

const Entry = ({template, save}) => {
  const [text, setText] = useState(template.template)
  const [constraints, setConstraints] = useState(template.constraints)
  const [title, setTitle] = useState(template.title)
  const [rating, setRating] = useState(template.rating)

  const changed = (template.id == undefined || template.title != title || template.template != text || template.constraints != constraints || template.rating != rating)

  const addConstraint = () => {
    setConstraints((old) => {
      return [...old, {path: "path.to.data", comparator: "==", value: "0", id: uuidv4()}]
    })
  }

  const removeConstraint = (id) => {
    setConstraints((old) => {
      return old.filter((data) => {  return data.id != id})
    })
  }

  const saveConstraint = (id, path, comparator, value) => {
    setConstraints((old) => {
      return old.map((data) => { if(id==data.id) {data.path = path; data.comparator = comparator; data.value = value;} return data})
    })
  }

  const saveEntry = () => {
    const data = {
      title: title,
      text: text,
      constraints: constraints,
      rating: parseInt(rating, 10) > -1 ? parseInt(rating, 10) : 1000
     }
    save(data)
  }

  const buttonStyle = changed ? 'relative my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700' : 'relative my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:shadow-outline-gray focus:border-gray-700 active:bg-gray-700 cursor-not-allowed'

          return (
            <div >
              <input value={title} onChange={(evt) => setTitle(evt.target.value)} className="block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mb-2"/>
              <div className="flex flex-row">
                <div className="flex flex-grow">
              <textarea rows="5" className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={text} onChange={(evt) => setText(evt.target.value)} ></textarea>
            </div>
              <div className="border w-1/2 ml-4">
                <h3>Constraints</h3>
              { constraints.map((constraint) => {
                return <Constraint {...constraint} onDelete={() => removeConstraint(constraint.id)} onChange={(path, value, comparator) => saveConstraint(constraint.id, path, comparator, value)} key={constraint.id} />
              })}
                <button className="relative my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700" onClick={() => addConstraint()}>Add constraint</button>
            </div>
          </div>
              <fieldset>
                <legend>Rating</legend>
                <input value={rating} type="number" onChange={(evt) => setRating(evt.target.value)} className="block w-1/12 transition duration-150 ease-in-out sm:text-sm sm:leading-5 mb-2"/>
            </fieldset>
                <button className={buttonStyle} onClick={() => saveEntry()} disabled={!changed}>Save</button>
            </div>
          )
}

const Collection = () => {
  const params = useParams()
  const vars = {id:params.collectionId}
  const {loading, error, data, refetch } = useQuery(ENTRY_QUERY, {variables: vars})

  const [saveEntry, { data: savedData}] = useMutation(SAVE_ENTRY_MUTATION);
  const [createEntry, { data: createdData }] = useMutation(CREATE_ENTRY_MUTATION);

  const save = (id, data) => {
    let saveParams = {
      title: data.title,
      rating: data.rating,
      template: data.text,
      constraints: JSON.stringify(data.constraints.map((item) => {return {path: item.path, comparator: item.comparator, value: item.value}})),
    }
    let mutation = saveEntry
    if(id) {
      saveParams['entryId'] = id
    } else {
      mutation = createEntry
      saveParams['collectionId'] = params.collectionId
    }

    const mutationParams = {variables: saveParams}

    const result = mutation(mutationParams)


    result.then((done) => {
      Mixpanel.track("Saved text template", {})
      console.log(done)
    }).catch(v => console.error(v))
  }

  if(createdData) refetch();

  if (error) {

    console.log(error)
    return <div>Error</div>
  }
  if (loading) return <Loading />


  return (
    <>
      <h1>{data.textTemplateCollection.title}</h1>
        <button className="relative inline-flex items-center px-2 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700" onClick={() => save(undefined, {title: "New Entry", text: "", constraints: [{path: "dummy.constraint", comparator: "==", value: "here"}]})}>Create Entry</button>
      <div className="border p-4">
        {data.textTemplateCollection.textTemplates.map((template) => {
          return <EntryMapConstraints template={template} key={template.id} save={(data) => save(template.id, data)} />
        })}
      </div>
      </>
    )
}


export default Collection
