import React, { useState, useCallback, useRef, MutableRefObject, useEffect, useMemo} from 'react'
import { Caption, Video } from '../../types'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import VideoPlayer from './Player'
import { Mixpanel } from '../../Common/Mixpanel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useParams} from 'react-router-dom'
import { faDownload, faLink, faSave, faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import {distance, closest} from 'fastest-levenshtein'
import {CAPTIONEDIT_QUERY, CAPTIONEDIT_UPDATE_MUTATION, Sentence, CaptionEntry, Word, AlternativeWord, CaptionResult, CaptionList, TranscriptionWord} from '../../api/captions'
import { secondsToVttTimestamp, captionEntryToVttEntry, VttTimestamp, vttTimestampToPresentationString, VttEntry, vttTimeStampStringToSeconds, captionEntryListToVtt} from '../../api/captions/vtt'





function sentenceToCaptionEntry(sentence: Sentence): CaptionEntry {
    return {
        type: 'captionentry' as const,
        startTime: sentence.start_time,
        endTime: sentence.end_time,
        children: sentence.words,
        changed: true
    }
}



function nearestOption(word:string, list:string[], index:number): string {
    if(list[index] == word) return word
    if(list[index] && distance(list[index], word) < 2) return list[index]

    const minIndex = index > 4 ? index - 4 : index
    const maxIndex = index < list.length - 5 ? index + 4 : list.length - 1

    let bestIndex = -1
    let bestScore = -1

    for(var i=minIndex;i<maxIndex;i++) {
        const score = distance(word, list[i])
        if(score < bestScore) {
            bestIndex = i
            bestScore = score
        }
        if(score == 0) {
            break
        }
    }

    if(bestIndex == -1) return word
    return list[bestIndex]

}

function wordListToCaptionEntryList(words: TranscriptionWord[], displayString:string, shortList: string[]) : CaptionEntry[] {
    const spaceWord = {
        type: 'word' as const,
        text: ' ',
        originalWord: ' ',
        sentenceStart: false,
        confidence: 1.0,
        alternatives: []
    } as Word

    const displayArray = displayString.split(/ /)
    const mappedWords = words.length == displayArray.length ? 
        words.map((word, i) => { return {...word, word: displayArray[i]} })
    : words.map((word, i) => { return {...word, word: nearestOption(word.word, displayArray, i)} })


    const entryWords = mappedWords.map((word) => transcriptionWordToWord(word, shortList)).reduce((acc, current) => acc.concat([current, {...spaceWord}]), []);

    return wordListToSentences(entryWords).map((sentence) => sentenceToCaptionEntry(sentence))
}

function wordListToSentences(words: Word[]) : Sentence[] {
    const wordCount = words.length;
    const sentences = []

    let currentSentence = {
        sentence: "",
        start_time: -1,
        end_time: -1,
        words: []
    }

    var previousIndex = 0;

    for(var i=0;i<wordCount;i++) {
        const previousWord = (i>0) ? words[i-1] : undefined
        const currentWord = words[i]
        const nextWord = (i<wordCount - 1) ? words[i+1] : undefined


        currentSentence.sentence += currentWord.text
        currentSentence.end_time = currentWord.endTime || previousWord.endTime
        if(currentSentence.start_time == -1) currentSentence.start_time = currentWord.startTime || previousWord.endTime

        let splitNow = false;
        if(currentWord.text.endsWith('.') || (currentWord.text.endsWith(",") && currentSentence.sentence.split(' ').length > 10)) {
            splitNow = true;
        }
        if(nextWord && (nextWord.duration > 0.25 && currentSentence.sentence.split(' ').length > 10)) {
            splitNow = true;
        }
        if(!nextWord) {
            splitNow = true;
        }

        if(splitNow) {
            currentSentence.words = words.slice(previousIndex, i + 1)
            previousIndex = i + 1
            if(currentSentence.words.length > 0 && currentSentence.words[0].text == " ") currentSentence.words.shift()
            sentences.push(currentSentence)
            currentSentence = {
                sentence: "",
                start_time: -1,
                end_time: -1,
                words: []
            }
        }
    }

    return sentences.filter((sentence) => sentence.words.length > 1 || (sentence.words.length > 0 && sentence.words[0].text != ' '))
}

function objectValues(metadata:object): any[] {
    const values = Object.values(metadata).map((value) => {
        if(value instanceof Object) return objectValues(value)
        return value
    })


    const cleanedValues = [].concat.apply([], values).filter((item) => {
        return (typeof item === 'string' || item instanceof String)
    }).filter((item) => {
        return !item.startsWith('http')
    })


    return cleanedValues.join(" ").split(" ")
}

var deepValue = function(obj: object, path:string){
    for (var i=0, pathArr=path.split('.'), len=pathArr.length; i<len; i++){
        if(obj) obj = obj[pathArr[i]];
    };
    return obj;
};

function transformMetadata(obj: object, paths: string[]): object {
    let out = {}

    paths.forEach((path) => {
        out[path] = deepValue(obj, path)
    })

    return out
}

function metadataToWordList(metadata:object): string[] {
    if(metadata['caption_keys']) {
        const keys = metadata['caption_keys'].split(',')
        metadata = transformMetadata(metadata, keys);
    }

    return objectValues(metadata).filter((value, index, self) => self.indexOf(value) === index)
}

function parseAlternatives(word: Word, shortList: string[]): AlternativeWord[] {

    return shortList.map((alternativeWord) => {
        return {
            word: alternativeWord,
            distance: distance(word.text, alternativeWord)
        }
    }).filter((item) => item.distance < 3)

}

function transcriptionWordToWord(word: TranscriptionWord, shortList: string[]): Word {
    const duration = parseDurationString(word.duration)
    const startTime = parseDurationString(word.offset)
    const endTime = startTime + parseDurationString(word.duration)

    const newWord = {
        type: 'word' as const,
        text: word.word,
        originalWord: word.word,
        startTime: startTime,
        endTime: endTime,
        sentenceStart: false,
        duration: duration,
        confidence: word.confidence,
        alternatives: []
    }

    if(newWord.text.length > 3) {
        newWord['alternatives'] = parseAlternatives(newWord, shortList)
    }

    return newWord
}

function parseDurationString( durationString: string ): number {
    var stringPattern = /^PT(?:(\d+)D)?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d{1,3})?)S)?$/;
    var stringParts = stringPattern.exec( durationString );
    return (
        (
            (
                ( stringParts[1] === undefined ? 0 : parseInt(stringParts[1], 10) )  /* Days */
                * 24 + ( stringParts[2] === undefined ? 0 : parseInt(stringParts[2], 10) ) /* Hours */
            )
            * 60 + ( stringParts[3] === undefined ? 0 : parseInt(stringParts[3], 10) ) /* Minutes */
            )
        * 60 + ( stringParts[4] === undefined ? 0 : parseFloat(stringParts[4]) ) /* Seconds */
            );
}

function download(captionList: CaptionList) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(captionEntryListToVtt(captionList)));
  element.setAttribute('download', 'captions.webvtt');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


function parseCaption(caption: Caption, wordList: string[]): CaptionList {
    let source = undefined
    if(caption.source) {
        source = JSON.parse(caption.source)
    }

    if(source && source['entries'] && source['entries'].length > 0 &&  source['entries'][0]['children']) {
        return source
    } else if (caption.transcriptionSource) {
        const parsed = JSON.parse(caption.transcriptionSource)
        return {entries: parsed.recognizedPhrases.map((phrase) => wordListToCaptionEntryList(phrase.nBest[0].words, phrase.nBest[0]["display"], wordList)).flat() }
    }

    return {entries: []}
}

interface PartialCaptionData {
    language?: string;
    source?: CaptionList;
    text?: string;
    captionType?: string;
}

interface EditingCaption extends Caption {
    captions: CaptionList;
}

interface CaptionEditorProps {
    caption: EditingCaption;
    video: Video;
    save: (caption:PartialCaptionData, boolean) => void;
}

interface PlayerStateType {
    currentTime: number;
}

const CaptionEditor : React.FC<CaptionEditorProps>= ({caption: {captions, captionType}, video, save}) => {
    const [playerState, setPlayerState] = useState({currentTime: 0} as PlayerStateType);
    const [isPlaying, setIsPlaying] = useState(false);
    const [state, setState] = useState(captions)
    const [startTime, setStartTime] = useState<number>(undefined)
    const [endTime, setEndTime] = useState<number>(undefined)
    const [editedCaptionType, setEditedCaptionType] = useState(captionType)
    const [textTrack, setTextTrack] = useState("")

    const playerRef = React.useRef(null);

    const stateRef = React.useRef(null);

    stateRef.current = {
        startTime, endTime, isPlaying, playerState
    }

    let metadata = {}
    if(video.workflowInstance && video.workflowInstance.originalData) {
        metadata = JSON.parse(video.workflowInstance.originalData)
    }

    const saveCaption = () => {

        save( {
            source: state,
            text: captionEntryListToVtt(state),
            captionType: editedCaptionType
        }, true)
    }


    const autoSave = () => {
        let vtt = captionEntryListToVtt(state)
        let normalized = vtt.replace(/\\N/g, "\n")
        setTextTrack(normalized)

        save( {
            source: state,
        }, false)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            autoSave()
        }, 2000);
        return () => clearTimeout(timer);
    }, [state]);

    

    const jumpVideoToCaption = (index:number) => {
        const entry = state.entries[index]
        setStartTime(entry.startTime)
        setEndTime(entry.endTime)
        if(playerRef.current) {
            if(!isPlaying) {
                playerRef.current.play();
            }
        }
        setTimeout(() => setStartTime(-1), 100)
    }

    const onTimeUpdate = (obj, evt) => {
        const currentTime = obj.currentTime()
        if(stateRef.current.endTime > -1 && currentTime > stateRef.current.endTime) {
            setEndTime(-1)
            playerRef.current.pause();
        }
        setPlayerState({...playerState, currentTime: obj.currentTime()})
    }

    const onPlay = (obj, evt) => {
        setIsPlaying(true)

    }
    const onPause = (obj, evt) => {
        setIsPlaying(false)
    }

    const play = () => {
        if(playerRef.current) {
            if(isPlaying) {
                playerRef.current.pause();
            } else {
                playerRef.current.play();
            }
        }
    }

    const setEntries = (entries: CaptionEntry[]) => {

        const newState = entries.map((element : CaptionEntry, index, array) => {
            if(index == 0) return element;

            if(element.startTime < array[index-1].endTime) {
                return {...element, startTime: array[index-1].endTime}
            }


            return element
        })


        setState({...state, entries: newState})
    }

    return (
        <div>
            <h1 className="w-full text-lg font-bold pb-4">{video.title}</h1>
            <div className="lg:flex">
                <div className="lg:w-1/2 sm:w-full">
                    <CaptionEditorList captions={state} timeRef={playerState} isPlaying={isPlaying} setEntries={setEntries} jumpVideoToCaption={jumpVideoToCaption} play={play} />
                </div>
                <div className="lg:w-1/2 sm:w-full flex flex-col">
                    <div className="lg:fixed lg:w-1/2 sm:w-full lg:p-4 lg:m-2 flex flex-col flex-grow">
                        <div className="lg:flex mb-2">
                            <div className="lg:w-1/3 sm:w-full">
                                <button className="block flex text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-full mb-4" onClick={() => saveCaption()}>
                                    <FontAwesomeIcon icon={faSave} className="mr-2" />Send til Produktion</button>
                            </div>
                        </div>
                        <div className="relative w-4/5 h-64 flex-grow md:block">
                            <ShowVideo video={video} onTimeUpdate={onTimeUpdate} onPlay={onPlay} onPause={onPause} startTime={startTime} textTrack={textTrack} onReady={(player) => playerRef.current = player}/>
                            {!document.location.href.endsWith("realtor") ? 
                            <div className="w-1/2 flex flex-col border border-gray-300 text-white mt-4 p-4">
                                <div className="my-2 p-2  w-full flex bg-brand-500">
                                    <span className="text-lg w-1/2">⏎</span>
                                    <span className="ml-2 w-1/2">Split text</span>
                                </div>
                                <div className="my-2 p-2  w-full flex bg-brand-500">
                                    <span className="text-lg w-1/2">⌫</span>
                                    <span className="ml-2 w-1/2">Combine text</span>
                                </div>
                                <div className="my-2 p-2 w-full flex bg-brand-500">
                                    <span className="text-lg w-1/2">⇧ ⏎</span>
                                    <span className="ml-2 w-1/2">Forced linebreak</span>
                                </div>
                                <div className="my-2 p-2 w-full flex bg-brand-500">
                                    <span className="text-lg w-1/2">⌘  ⏎</span>
                                    <span className="ml-2 w-1/2">Play/pause</span>
                                </div>
                                <div className="my-2 p-2 w-full flex bg-brand-500">
                                    <span className="text-lg w-1/2">⇧ ⌘  ⏎</span>
                                    <span className="ml-2 w-1/2">Play video from location</span>
                                </div>
                </div> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface CaptionEditorListProps {
    captions: CaptionList;
    setEntries: (entries:CaptionEntry[]) => void;
    timeRef: PlayerStateType;
    isPlaying: boolean;
    jumpVideoToCaption?: (index: number) => void;
    play?: () => void;
}

const CaptionEditorList : React.FC<CaptionEditorListProps> = ({captions, timeRef, isPlaying, setEntries, jumpVideoToCaption, play}) => {
    const setEntryText = (index, text) => {
        const entries = [...captions.entries]
        let entry = {...entries[index]};
        entry.text = text;
        entries[index] = entry;

        setEntries(entries)
    }

    const setEntryStartTime = (index, time) => {
        const entries = [...captions.entries]
        let entry = {...entries[index]};
        entry.startTime = time;
        entries[index] = entry;

        setEntries(entries)
    }

    const setEntryEndTime = (index, time) => {
        const entries = [...captions.entries]
        let entry = {...entries[index]};
        entry.endTime = time;
        entries[index] = entry;

        setEntries(entries)
    }

    const splitToNext = (index, keep, text) => {
        const entries = [...captions.entries]
        let entry = entries[index]
        keep = keep || ''
        text = text || ''

        const newEntry = {
            type: 'captionentry',
            startTime: entries[index].endTime,
            endTime: entries[index].endTime + 2.0,
            text: text,
            children: []
        } as CaptionEntry

        entry.text = keep

        entries[index] = entry
        entries.splice(index, 1, entry, newEntry)

        setEntries(entries)
    }
    const combineWithNext = (index) => {
        const entries = [...captions.entries]
        let currentEntry = {...entries[index]};
        let nextEntry = {...entries[index+1]};

        currentEntry.text = currentEntry.text || currentEntry.children.map((item) => item.text).join('')
        nextEntry.text = nextEntry.text || nextEntry.children.map((item) => item.text).join('')

        currentEntry.text = currentEntry.text.replace(/\|/g, '').trim() + '|' + nextEntry.text.replace(/\|/g, '').trim()
        currentEntry.endTime = nextEntry.endTime

        entries[index] = currentEntry

        entries.splice(index+1, 1)
        setEntries(entries)
    }
    const combineWithPrevious = (index) => {
        const entries = [...captions.entries]
        let currentEntry = {...entries[index]};
        let previousEntry = {...entries[index-1]};

        currentEntry.text = currentEntry.text || currentEntry.children.map((item) => item.text).join('')
        previousEntry.text = previousEntry.text || previousEntry.children.map((item) => item.text).join('')

        setFocusIndex([(index - 1) * 2 + 1, previousEntry.text.length - previousEntry.text.indexOf('|') - 1])

        previousEntry.text = previousEntry.text.trim() + ' ' +  currentEntry.text.replace(/\|/g, '').trim()
        previousEntry.endTime = currentEntry.endTime

        entries[index-1] = previousEntry
        entries.splice(index, 1)
        setEntries(entries)
    }

    const [focusIndex, setFocusIndex] = useState([0, 0])
    useEffect(() => {
        if(focusIndex && containerRef.current) {
            const list = containerRef.current.querySelectorAll('textarea')
            if(list[focusIndex[0]]) {
                list[focusIndex[0]].focus()
                list[focusIndex[0]].selectionStart = focusIndex[1]
                list[focusIndex[0]].selectionEnd = focusIndex[1]
            }
        }
    }, [focusIndex])

    const containerRef = useRef(null)

    return <div ref={containerRef}>
        {captions.entries.map((entry, i) => {
            const text = entry.text || entry.children.map((child) => child.text).join('')


            const line1 = text.split('|')[0] || ''
            const line2 = text.split('|')[1] || ''

            const line1Count = line1.length
            const line2Count = line2.length

            const lineLimit = 42

            const updateText = () => {
                if(line2 == "") {
                    setEntryText(i, line1)
                } else {
                    setEntryText(i, line1 + '|' + line2)
                }
            }

            const changeLine1 = (e) => {
                setEntryText(i, e.target.value + '|' + line2.replace(/\|/g, ' '))
            }
            const changeLine2 = (e) => {
                setEntryText(i, line1 + '|' + e.target.value )
            }

            const editStartTime = () => {
                const newTime = window.prompt('Edit start time', vttTimestampToPresentationString(secondsToVttTimestamp(entry.startTime)))
                if(newTime) setEntryStartTime(i, vttTimeStampStringToSeconds(newTime))
            }

            const editEndTime = () => {
                const newTime = window.prompt('Edit end time', vttTimestampToPresentationString(secondsToVttTimestamp(entry.endTime)))
                if(newTime) setEntryEndTime(i, vttTimeStampStringToSeconds(newTime))
            }

            const commonKeyDown = (e) => {

                if(!e.shiftKey && (e.metaKey || e.ctrlKey) && e.keyCode == 13) {
                    event.preventDefault();
                    if(play) play();
                    return true
                }

                if(e.shiftKey && (e.metaKey || e.ctrlKey) && e.keyCode == 13) {
                    event.preventDefault();
                    jumpVideoToCaption(i)
                    return true
                }

                // Cmd s
                if(!e.shiftKey && (e.metaKey || e.ctrlKey) && e.keyCode == 83) {
                    editStartTime()
                }
                if(e.shiftKey && (e.metaKey || e.ctrlKey) && e.keyCode == 83) {
                    const newTime = timeRef.currentTime
                    setEntryStartTime(i, newTime)
                }
                // Cmd e
                if(!e.shiftKey && (e.metaKey || e.ctrlKey) && e.keyCode == 69) {
                    editEndTime()
                }
                if(e.shiftKey && (e.metaKey || e.ctrlKey) && e.keyCode == 69) {
                    const newTime = timeRef.currentTime
                    setEntryEndTime(i, newTime)
                }

                return false
            }


            const keyDownLine1 = (e) => {
                if(commonKeyDown(e)) return;

                // Arrow down
                if(e.keyCode == 40) {
                    setFocusIndex([i * 2 + 1, 0])
                }
                // Arrow up
                if(e.keyCode == 38) {
                    if(i > 0) setFocusIndex([(i - 1) * 2 + 1, 0])
                }
                if(!e.shiftKey && !(e.metaKey || e.ctrlKey) && e.keyCode == 13) {
                    e.preventDefault()
                    const position = e.target.selectionStart
                    const value = e.target.value;
                    const keep = value.substring(0, position).replace(/\|/g, '').trim()
                    const send = value.substring(position).replace(/\|/g, '').trim()
                    setEntryText(i, keep + '|' + send + (text.split('|')[1] || ''))
                    setFocusIndex([i * 2 + 1, 0])
                }
                if(e.shiftKey && !(e.metaKey || e.ctrlKey) && e.keyCode == 13) {
                    e.preventDefault()
                    const position = e.target.selectionStart
                    const value = e.target.value;
                    const keep = value.substring(0, position).replace(/\|/g, '').trim()
                    const send = value.substring(position) || ''
                    splitToNext(i, keep, send.trim() + ' ' + (text.split('|')[1] || '' ))
                    setFocusIndex([i * 2 + 2, 0])
                }
                if(e.keyCode == 46) {
                    const position = e.target.selectionStart
                    if(position >= e.target.value.length) {
                        e.preventDefault()
                        setFocusIndex([i * 2 , text.indexOf('|')])
                        setEntryText(i, text.replace(/\|/g, ''))
                    }
                }
                if(e.keyCode == 8) {
                    const position = e.target.selectionStart
                    if(position == 0) {
                        e.preventDefault()
                        combineWithPrevious(i)
                    }
                }
            }
            const keyDownLine2 = (e) => {
                if(commonKeyDown(e)) return;

                // Arrow down
                if(e.keyCode == 40) {
                    setFocusIndex([(i + 1) * 2, 0])
                }
                // Arrow up
                if(e.keyCode == 38) {
                    setFocusIndex([i * 2, 0])
                }

                if(!(e.metaKey || e.ctrlKey) && e.keyCode == 13) {
                    e.preventDefault()
                    const position = e.target.selectionStart
                    const value = e.target.value;
                    const keep = value.substring(0, position)
                    const send = value.substring(position)
                    splitToNext(i, text.split('|')[0] + '|' + keep, send)
                    setFocusIndex([(i + 1) * 2, 0])
                }
                if(e.keyCode == 8) {
                    const startPosition = e.target.selectionStart
                    const endPosition = e.target.selectionEnd
                    if(startPosition == 0 && endPosition == 0) {
                        e.preventDefault()
                        const value = e.target.value;
                        const keep = ""
                        const send = value
                        setFocusIndex([i * 2, text.indexOf('|')])
                        setEntryText(i, text.replace(/\|/g, ' '))
                    }
                }
                if(e.keyCode == 46) {
                    const startPosition = e.target.selectionStart
                    const endPosition = e.target.selectionEnd
                    if(startPosition >= e.target.value.length && endPosition >= e.target.value.length) {
                        e.preventDefault()
                        combineWithNext(i)
                    }
                }
            }

            const isEntryPlaying = () => {
                return isPlaying && timeRef.currentTime >= entry.startTime && timeRef.currentTime < entry.endTime;
            }

            return <div key={i}>
                <div>
                    <div className={ (line1Count <= lineLimit && line2Count <= lineLimit) ? "border-b border-solid border-brand-500 border-b my-4 relative sm:mx-8" : "border-b border-solid border-danger border-b my-4 relative sm:mx-8 bg-red-300" }>
                        <span className="absolute top-0 right-0 lg:mb-4 lg:-mr-2 lg:mt-2 bg-brand-500 rounded-full h-8 w-8 flex items-center justify-center text-white" onClick={(e) => isPlaying ? play() :  jumpVideoToCaption(i)}>{isEntryPlaying() ? <FontAwesomeIcon icon={faPause} className="" /> : <FontAwesomeIcon icon={faPlay} className="" />}</span>
                        <div className="flex flex-col">
                            <div className={line1Count < lineLimit ? 'ml-2 mt-2 text-xs text-gray-600' : 'ml-2 mt-2  text-xs text-gray-600'}>
                                <span>{line1Count} af {lineLimit} tegn</span>
                            </div>
                            <textarea value={line1} className="border border-solid border-gray-300 p-1 lg:p-2 m-1 lg:m-2 text-xs lg:text-xl text-center" onChange={changeLine1} onKeyDown={keyDownLine1} />
                            <div className={line2Count < lineLimit ? 'ml-2 mt-2 text-xs text-gray-600' : 'ml-2 mt-2 text-xs text-gray-600'}>
                                <span>{line2Count} af {lineLimit} tegn</span>
                            </div>
                            <textarea value={line2} className="border border-solid border-gray-300 p-1 lg:p-2 m-1 lg:m-2 text-xs lg:text-xl text-center" onChange={changeLine2} onKeyDown={keyDownLine2} />
                        </div>
                        <div className="text-right m-2 text-xs text-gray-600">
                            <span onClick={(e) => editStartTime()}>{vttTimestampToPresentationString(secondsToVttTimestamp(entry.startTime))}</span>
                            <span className="px-4"> =&raquo;</span>
                            <span onClick={(e) => editEndTime()}>{vttTimestampToPresentationString(secondsToVttTimestamp(entry.endTime))}</span>
                        </div>
                    </div>
                </div>
            </div>
        })}
    </div>
}



interface ShowVideoProps {
    video: Video;
    onPlay?: (obj: any, evt: any) => void;
    onPause?: (obj: any, evt: any) => void;
    onTimeUpdate?: (obj: any, evt: any) => void;
    startTime?: number;
    textTrack?: string;
    onReady?: (obj: any) => void;
}

const ShowVideo : React.FC<ShowVideoProps> = ({video, onPlay, onPause, onTimeUpdate, startTime, textTrack, onReady}) => {
    return <VideoPlayer video={video} onTimeUpdate={onTimeUpdate} onPlay={onPlay} onPause={onPause} startTime={startTime} textTrack={textTrack} onReady={onReady} playsInline={true}></VideoPlayer>
}



const EditCaption = () => {
    let navigate = useNavigate()
    let options = useParams()
    const params = {variables:{captionId: options.captionId, videoId: options.videoId}}

    const [ closing, setClosing ] = useState(false)

    const { loading, data, error } = useQuery<CaptionResult>(CAPTIONEDIT_QUERY, params);

    const [saveCaption, newData] = useMutation(CAPTIONEDIT_UPDATE_MUTATION);

    const save = (caption : PartialCaptionData, close: boolean) => {
        const variables = {
            id: options.captionId
        }
        if(caption.source) {
            variables['source'] = JSON.stringify(caption.source)
        }
        if(caption.language) {
            variables['language'] = caption.language
        }
        if(caption.captionType) {
            variables['captionType'] = caption.captionType
        }
        if(caption.text) {
            variables['text'] = caption.text
        }
        const result = saveCaption({ variables: variables})
        result.then((done) => {
            Mixpanel.track("Updated caption", {}) 

            if(close) {
                setClosing(true)
            }

        }).catch(v => console.error(v))
    }

    if(error) {
        console.log(error)
        return <p>Failed loading caption</p>
    }
    if(loading) {
        return <h1>Loading</h1>
    }

    if (closing ) {

        if(document.location.href.indexOf('action') > -1) {
        return (
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
              <div className="w-1/2 h-full">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-2xl font-semibold">
                      Undertekster gemt.
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                    <p>Videoen sendes nu til produktion.</p>
                    <p>Du kan lukke dette vindue</p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                </div>
              </div>
            </div>
          </div>
        )
        }
        return navigate(`/dashboard/videos/${options.videoId}`)
    }

    const wordList = data?.video?.workflowInstance?.originalData ? metadataToWordList(JSON.parse(data.video!.workflowInstance!.originalData))  : []

    const captions = parseCaption(data.caption, wordList)

    const editCaption = {...data.caption, captions}

    return (
        <CaptionEditor caption={editCaption} video={data.video} save={save}  />
    )
}

export default EditCaption
