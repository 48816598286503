import React, {useState, useEffect, useRef } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import { ChromePicker } from 'react-color';
import { Mixpanel } from '../../Common/Mixpanel'
import { AssetUpload } from '../Video/Upload'


const InstanceForm = ({ fieldSettings, workflowId, createInstance, showCreate, hideHeader, buttonTitle, postFields }) => {
    const [ showCreateInstance, setCreateInstance] = useState(showCreate)
    const arr = typeof fieldSettings === 'string' ? JSON.parse(fieldSettings) : fieldSettings
    const [ formData, updateFormData ] = useState(arr)
    const [ postData, updatePostData ] = useState(postFields)

    const updateFormField = (key, value) => {
        const newData = formData.reduce((last, current, index) => {
            if(current.key == key) {
                current.value = value
            }
            last.push(current)
            return last
        }, [])

        updateFormData(newData)
    }

    const updatePostField = (key, value) => {
        const newData = postData.reduce((last, current, index) => {
            if(current.key == key) {
                current.value = value
            }
            last.push(current)
            return last
        }, [])

        updatePostData(newData)
    }


    const extractSettingValue = (setting) => {
        if(setting.value && Array.isArray(setting.value)) {
        } else if(setting.value && typeof setting.value === 'object') {
            return setting.value.selected.value
        } else {
            return setting.value || setting.default
        }
    }

    const extractWorkflowData = (inputData) => {
        if(!inputData) return []

        const data = inputData.filter((setting) => setting.type != 'compute'  ).reduce((last, current, index) => {
            last[current.key] = extractSettingValue(current)
            return last
        }, {})

        return data
    }

    const startWorkflow = () => {
        const data = extractWorkflowData(formData)
        const post = extractWorkflowData(postData)
        const vars = {
            id: workflowId,
            data: JSON.stringify(data)
        }
        if(postData && postData.length > 0) {
            vars['sharingProfileId'] = post.sharingProfileId
            vars['networkName'] = post.networkName
            vars['postText'] = post.postText
            vars['scheduledFor'] = post.scheduledFor
        }
        const result = createInstance({ variables: vars })
        window.res = result
        result.then((done) => {
            Mixpanel.track("Created workflow instance", {id: workflowId, data: data}) 
            setCreateInstance(false)
        }).catch(v => console.error(v))
    }

    return (
        <div className="bg-white overflow-hidden shadow rounded-lg md:w-100 lg:w-1/2">
            <div className="px-4 py-5 sm:p-6">
                { hideHeader ? <span /> : <h1 onClick={() => setCreateInstance(!showCreateInstance)} className="cursor-pointer">Generate video flow</h1> }
                <div className={showCreateInstance ? "" : "hidden"}>
                    { postData && postData.length > 0 ?
                         <div className="my-8">
                            <h1>Post</h1>
                    { postData.map((settings) => {
                        const Control = controlFinder(settings)
                        return (
                            Control ? <div className="p-4 bg-blue-200 border"><Control settings={settings}  updateFormField={updatePostField} key={settings.key} /></div> : <span key={settings.key} />
                        )
                    })}
                        </div> : <span /> }
                         <div>
                            <h1>Video</h1>
                    {formData.map((settings, i) => {
                        const Control = controlFinder(settings)
                        return (
                            Control ? <div className="p-4 bg-blue-200 border"><Control settings={settings}  updateFormField={updateFormField} key={settings.key} /></div> : <span key={settings.key} />
                        )

                    })}
                         </div>
                <div className="p-4 mt-2 flex justify-end border-top">
                    <button onClick={() => startWorkflow() } className="bg-brand-500 hover:bg-blue-700 text-white font-bold w-1/3 py-2 px-4 rounded focus:outline-none focus:shadow-outline">{buttonTitle || "Start Workflow" }</button>
                </div>
                </div>
            </div>
        </div>
    )
}


const StringControl = ({settings, updateFormField}) => {
    return (
        <div className="mt-8">
            <label htmlFor={settings.key} className="block text-sm font-medium leading-5 text-gray-700">{settings.label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <input type="text" id={settings.key} className="form-input block w-full sm:text-sm sm:leading-5" value={settings.value || settings.default}  onChange={(evt) => updateFormField(settings.key, evt.target.value)}/>
                </div>
            <p className="mt-1 text-sm text-gray-500">{settings.helpText}</p>
            </div>
    )
}

const TextControl = ({settings, updateFormField}) => {
    return (
        <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={settings.key} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {settings.label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <textarea onChange={(evt) => updateFormField(settings.key, evt.target.value)} id={settings.key} rows="3" className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>
                </div>
                <p className="mt-2 text-sm text-gray-500">{settings.helpText}</p>
            </div>
        </div>
    )
}

const IntegerControl = ({settings, updateFormField}) => {
    return (
        <div className="mt-8">
            <label htmlFor={settings.key} className="block text-sm font-medium leading-5 text-gray-700">{settings.label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <input id={settings.key} className="form-input block w-full sm:text-sm sm:leading-5" value={settings.value || settings.default} type="number" min={settings.min || 0} step={settings.step || 1} max={settings.max || Number.MAX_SAFE_INTEGER} aria-describedby="email-description" onChange={(evt) => updateFormField(settings.key, evt.target.value)}/>
                </div>
            <p className="mt-1 text-sm text-gray-500" id="email-description">{settings.helpText}</p>
            </div>
    )
}

const NumberControl = ({settings, updateFormField}) => {
    return (
        <div className="mt-8">
            <label htmlFor={settings.key} className="block text-sm font-medium leading-5 text-gray-700">{settings.label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <input id={settings.key} className="form-input block w-full sm:text-sm sm:leading-5" value={settings.value || settings.default} type="number"  min={settings.min || 0} step={settings.step || 0.001} max={settings.max || Number.MAX_SAFE_INTEGER}aria-describedby="email-description" onChange={(evt) => updateFormField(settings.key, evt.target.value)}/>
                </div>
            <p className="mt-1 text-sm text-gray-500" id="email-description">{settings.helpText}</p>
            </div>
    )
}

const ChoiceControl = ({settings, updateFormField}) => {
    const [open, setOpen] = useState(false)
    const choices = settings.choices || [{ label: 'Red', value: '#f00' }, { label: 'Green', value: '#0f0' }, { label: 'Blue', value: '#00f' }]
    const selectedIndex = settings.value ? settings.value.index : -1
    const selectedValue = selectedIndex > -1 && selectedIndex < choices.length ? choices[selectedIndex] : {label: '', value: ''}
    const selectedLabel = selectedValue.label
    const menuClasses = open ? "absolute mt-1 w-full rounded-md bg-white shadow-lg z-50" : "hidden"
    return (
<div className="space-y-1">
  <label id="listbox-label" className="block text-sm leading-5 font-medium text-gray-700">
      {settings.label}
  </label>
  <div className="relative">
    <span className="inline-block w-full rounded-md shadow-sm">
        <button type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5" onClick={() => setOpen(!open)}>
        <span className="block truncate">
            {selectedLabel}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
            <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      </button>
    </span>

    <div className={menuClasses}>
      <ul tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
        {/* <!-- */}
        {/*   Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation. */}

        {/*   Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900" */}
        {/* --> */}
        {choices.map((data, index) => {
            return (
                <li onClick={() => { updateFormField(settings.key, {index: index, selected: data}) ; setOpen(false)  }} role="option" className="text-gray-900 hover:text-white hover:bg-indigo-600 cursor-default select-none relative py-2 pl-3 pr-9" key={data.key}>
                    {/* <!-- Selected: "font-semibold", Not Selected: "font-normal" --> */}
                    <span className={index == selectedIndex ? "font-semibold block truncate" :  "font-normal block truncate"}>
                        {data.label}
                    </span>

                    {/* <!-- */}
                    {/*     Checkmark, only display for selected option. */}

                    {/*     Highlighted: "text-white", Not Highlighted: "text-indigo-600" */}
                    {/* --> */}
                    {index == selectedIndex ? (
                    <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                        {/* <!-- Heroicon name: check --> */}
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                    </span>
                    ) : <span />
                    }
                </li>
            )
          })}
      </ul>
    </div>
  </div>
</div>
    )
}

const BooleanControl = ({settings, updateFormField}) => {
    const on = settings.value === undefined ? (settings.default === true) || false : settings.value
    return (
        <div className="mt-8">
            <label htmlFor={settings.key} className="block text-sm font-medium leading-5 text-gray-700">{settings.label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
            <span id={settings.key} role="checkbox" tabIndex="0" aria-checked="false" className={"relative inline-flex flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline" + (on ? " bg-green-600" : " bg-gray-200")} onClick={() => updateFormField(settings.key, !on)}>
                <span aria-hidden="true" className={"relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200" + (on ? " translate-x-5" : " translate-x-0")}>
                    <span className={"absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" + (on ? " opacity-0 ease-out duration-100" : " opacity-100 ease-in duration-200")} >
                        <svg className="h-3 w-3 text-red-400" fill="none" viewBox="0 0 12 12">
                            <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    <span className={"absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" + (on ? " opacity-100 ease-in duration-200" : " opacity-0 ease-out duration-100")}>
                        <svg className="h-3 w-3 text-green-600" fill="currentColor" viewBox="0 0 12 12">
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                    </span>
                </span>
            </span>
                </div>
            <p className="mt-1 text-sm text-gray-500" id="email-description">{settings.helpText}</p>
            </div>
    )
}

const DictionaryControl = ({settings, updateFormField}) => {
    // Not implemented; and not yet enabled
}

const MultiSelectControl = ({settings, updateFormField}) => {
    // Not implemented; and not yet enabled
    // min count
    // max count
    // allow duplicates
}

const UploadControl = ({settings, updateFormField}) => {
    const onUpload = (url) => {
        updateFormField(settings.key, encodeURI(url))
    }
    return (
        <div className="mt-8">
            <label htmlFor={settings.key} className="block text-sm font-medium leading-5 text-gray-700">{settings.label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                {settings.value ? <span>{settings.value}</span> : <AssetUpload onUpload={onUpload} />}
            </div>
            <p className="mt-1 text-sm text-gray-500" id="email-description">{settings.helpText}</p>
        </div>
    )
}


const ColorPicker = ({settings, updateFormField}) => {
    return (
        <div className="mt-8">
            <label htmlFor={settings.key} className="block text-sm font-medium leading-5 text-gray-700">{settings.label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <ChromePicker
                    color={settings.value || settings.default}
                    onChangeComplete={ (value) => updateFormField(settings.key, value.hex) }
                />
                </div>
            <p className="mt-1 text-sm text-gray-500" id="email-description">{settings.helpText}</p>
            </div>
    )
}



const controls = {
    StringControl: StringControl,
    BooleanControl: BooleanControl,
    TextControl: TextControl,
    IntegerControl: IntegerControl,
    NumberControl: NumberControl,
    ColorPicker: ColorPicker,
    ChoiceControl: ChoiceControl,
    UploadControl: UploadControl,
}

const controlFinder = (settings)  => {
    if(settings.control && controls[settings.control]) {
        return controls[settings.control]
    } else if(settings.choices && settings.choices.length > 0) {
        return controls["ChoiceControl"]
    } else {
        switch(settings.type) {
            case "string":
                return controls["StringControl"]
            case "text":
                return controls["TextControl"]
            case "integer":
                return controls["IntegerControl"]
            case "number":
                return controls["NumberControl"]
            case "boolean":
                return controls["BooleanControl"]
        }
    }
    return undefined
}


export default InstanceForm
