import React, {useReducer, useState } from 'react'

import Navigation from './Navigation'
import MainSection from './MainSection'
import MainHeader from './MainHeader'

import { IntlProvider } from 'react-intl'

import { translationMessages, defaultLocale } from '../i18n'

import { DashboardTimeContext, initialTimePeriod, TimePeriodReducer } from '../stores/TimePeriod'
import { UserProvider, UserConsumer } from '../stores/User'

// import "@fortawesome/fontawesome-free/css/all.css";


export const IntlContext = React.createContext({
  locale: defaultLocale,
  messages: [],
})

const FlivaDashboard = () => {
    const [timePeriod, setTimeperiod] = useReducer(TimePeriodReducer, initialTimePeriod)
    const [showMenu, setShowMenu] = useState(false)
    const globalButtonClass = showMenu ? 'md:hidden' : ''


    const [intl, dispatch] = useReducer((state, { action, payload }) => {
        if (action == 'SET_LOCALE') {
            window?.localStorage?.setItem('App.locale', payload)

            return {
                ...state,
                locale: payload,
                messages: {
                    ...translationMessages[payload],
                    ...state.overwrites,
                }
            }
        }

        if (action == 'OVERWRITE') {
            return {
                ...state,
                messages: { ...state.messages, ...payload },
                overwrites: payload,
            }
        }

        return state
    }, {
        locale: window?.localStorage?.getItem('App.locale') || 'en',
        messages: translationMessages[window?.localStorage?.getItem('App.locale') || 'en' ],
        overwrites: {},
    })

    return (
        <UserProvider>

            <IntlContext.Provider value={{ intl, dispatch }}>
                <IntlProvider
                    locale={intl.locale}
                    messages={intl.messages}
                    defaultLocale={defaultLocale}
                >
                    <DashboardTimeContext.Provider value={{ timePeriod, dispatch: setTimeperiod}}>
                        <div className="h-screen flex overflow-hidden bg-gray-100">
                            <Navigation showMenu={showMenu} setShowMenu={setShowMenu}/>
                            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
                                    <MainHeader />
                                    <MainSection />
                        </main>
                    </div>
                </div>
            </DashboardTimeContext.Provider>

        </IntlProvider>
      </IntlContext.Provider>
        </UserProvider>
    )
}

export default FlivaDashboard
