import React, {useRef, useEffect} from 'react'
import videojs from '../../videojs-with-plugins';
import 'video.js/dist/video-js.css';
import './Player.css'

function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
}

const Player = ({video, onTimeUpdate = undefined, onPlay = undefined, onPause = undefined, options={}, startTime = undefined, onReady=undefined, textTrack=undefined, playsInline=false}) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);


    React.useEffect(() => {
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            options['nativeTextTracks'] = false
            options['textTrackSettings'] = false


            const player = playerRef.current = videojs(videoElement, options, () => {
                if(onReady) onReady(player);
            });
            if(onPlay) player.on("play", (evt) =>  onPlay(player, evt));
            if(onPause) player.on("pause", (evt) => onPause(player, evt));
            if(onTimeUpdate) player.on("timeupdate", (evt) => onTimeUpdate(player, evt));
        } else {
            // you can update player here [update player through props]
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options]);

    useEffect(() => {
        if(playerRef.current && startTime > -1) {
            const player = playerRef.current
            player.currentTime(startTime)
        }

    }, [startTime]);

    useEffect(() => {
        if(textTrack && playerRef.current) {
            var tracks = playerRef.current.textTracks().tracks_
            tracks.forEach(function(track) {track.mode = "disabled"; track.default = false});
            let captionOption = {
                kind: 'subtitles',
                srclang: 'da',
                label: 'Danish',
                mode: 'showing',
                src: 'data:text/vtt;base64,'+b64EncodeUnicode(textTrack)
            };
            const result = playerRef.current.addRemoteTextTrack(captionOption, false)
            result.mode = 'showing';
            result.default = true;
        }
    }, [textTrack, playerRef.current])

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, []);

    return (
            <video
                ref={videoRef}
                className="absolute top-0 left-0 w-full h-full video-js vjs-big-play-centered"
                poster={`${video.publicPoster}`}
                preload="true"
                controls={true}
                playsInline={playsInline}
            >
                {video.versions.length > 0 ? video.versions.map((version) => {
                    return (
                        <source
                            src={`${version.publicUrl}`}
                            type={version.mimeType}
                            label={version.label}
                            res={version.resolution}
                            key={version.publicUrl}
                        />
                    )
                })
                :
                        <source
                            src={`${video.originalPublicUrl || video.publicUrl}`}
                            type={video.mimeType}
                            label={video.label}
                            res={video.resolution}
                            key={video.publicUrl}
                        />
                }
            </video>
    )
}

export default Player
