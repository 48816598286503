import React, { useRef } from 'react'
import { Query, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { useNavigate} from 'react-router-dom'
import Modal from '../Modal'
import { Mixpanel } from '../../Common/Mixpanel'

export const CREATE_CHANNEL_MUTATION = gql`
mutation update($title:String!) {
  createChannel(title:$title) {
    id
    title
  }
}
`

const New = () => {
    let navigate = useNavigate()
    const channelName = useRef<HTMLInputElement>();
    const [createChannel, data] = useMutation(CREATE_CHANNEL_MUTATION);
    const save = () => {
        const result = createChannel({ variables: {title: channelName.current.value }})
        result.then((done) => {
            Mixpanel.track("Created channel", done.data.createChannel) 

        }).catch(v => console.error(v))
    }
    const cancel = () => {
        navigate(-1)
    }
    if(data && data.called && !data.loading && data.data) {
        navigate(`/dashboard/channels/${data.data.createChannel.id}`)
    }
    return (
        <Modal title="New Channel" save={save} cancel={cancel}>
                  <div className="my-4 text-gray-600 text-lg leading-relaxed">
                      <input aria-label="Email address" name="email" type="email" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Channel Title" ref={channelName}/>
                  </div>
        </Modal>
    )
}

export default New

