import React from 'react'
import { useQuery } from 'react-apollo'
import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'
import { Mixpanel } from '../../Common/Mixpanel'
import client from '../../FlivaApolloClient'
import Loading from '../loading'
import {Account} from '../../types'

export const ACCOUNTSHOW_QUERY = gql`
query AccountShow($id:ID!) {
  account(id:$id) {
    id
    title
    createdAt
    subscription {
      settings
      tier {
        id
        settings
        title
      }
    }
  }
}
`

const impersonate = (id:string) => {
    if ( localStorage ) {
      Mixpanel.track("Impersonating account", {id})
      localStorage.setItem('impersonation', id)
      client.resetStore()
      window.location.replace('/dashboard')
    }
}

const ShowAccount : React.FC<Account> = ({id, title, subscription}) => {
  return (
    <div>
      <h1>{title}</h1>
      <h2>{subscription ? subscription.tier.title : "No Subscription"}</h2>
      <span>
        <button className="bg-brand-500 rounded-full font-bold text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6 mt-4" onClick={() => impersonate(id)}>Impersonate</button>
      </span>
    </div>
  )
}

interface AccountRouterProps {
  accountId: string
}


interface AccountResult {
    account: Account;
}
const Show = () => {
  let params = useParams()
  const vars = {id: params.accountId}
  const { loading, data, error } = useQuery<AccountResult>(ACCOUNTSHOW_QUERY, {variables: vars});
  if (loading) return <Loading />
  if (error) {
    console.log(error)
    return <div>Error</div>
  }
  return <ShowAccount {...data.account} />
}

export default Show
