import React, {useContext} from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useParams} from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import { DashboardTimeContext} from '../../stores/TimePeriod'
import QuickStats from '../QuickStats'
import ViewGraphs from '../ViewGraphs'
import LatestVideos from '../LatestVideos'
import LatestMetrics from '../LatestMetrics'
import LatestBounces from '../LatestBounces'
import VideoPlayer from './Player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faLink } from '@fortawesome/free-solid-svg-icons'
import { VideoUpload } from './Upload'
import QRCode from "react-qr-code";

export const VIDEOSHOW_QUERY = gql`
query VideoShow($id:ID!, $period: TimePeriod!, $resolution: AggregationPeriod!) {
    video(id:$id) {
        id
        title
        createdAt
        embedUrl
        publicPoster
        publicUrl
        metadata
        title
        hasFile
        filePresent
        workflowInstance {
            id
        }
        stats(period: $period) {
            plays
            engagementTime
            playsPerUniqueVideo
            viewThroughRate
        }
        versions {
            hasFile
            filePresent
            publicUrl
            mimeType
            label
            resolution
        }
        graph: stats(period: $period) {
            data: playsPer(period: $resolution) {
                key
                value
            }
        }
        metrics(limit: 25, sortBy:SessionStart, sortDirection:DESC) {
            items {
                viewThroughRate
                engagementTime
                sessionStart
                client {
                    city
                    country
                    latitude
                    longitude
                }
            }
        }
        bounces(limit: 25, sortBy:CreatedAt, sortDirection:DESC) {
            items {
                viewThroughRate
                engagementTime
                sessionStart
                client {
                    city
                    country
                    latitude
                    longitude
                }
            }
        }
        channel {
            id
            title
        }
        tags {
            id
            name
        }
        captions {
            id
            label
            language
            createdAt
            text
            source
            transcriptionSource
        }
    }
}
`

export const VIDEO_SET_VALID_UNTIL = gql`
mutation update($id:String!, $date:Date) {
  updateVideo(options:{id:$id, validUntil:$date})
  {
    id
    public
    }
}
`

export const VIDEO_SET_TAGS = gql`
mutation update($id:String!, $tags:[String!]) {
  updateVideo(options:{id:$id, tags:$tags})
  {
    id
    tags {
      id
      name
    }
    }
}
`


const copyEmbedValue = (evt) => {
    evt.target.select()
}

const Dashboard = ({data: {video: {title, stats, graph, metrics, bounces}}}) => {
    return (
        <div className="w-full">
        <div className="mx-auto sm:px-4 pt-6 pb-8">
            <QuickStats  {...stats}/>
            <ViewGraphs list={graph} />
            <div className="flex flex-wrap -mx-4">
                <LatestMetrics metrics={metrics} />
                <LatestBounces bounces={bounces} />
            </div>
        </div>
        </div>
    )
}


const Show = () => {
    let params = useParams()
    const replaceVideoUploaded = (id, filename) => {
        console.log(id, filename)
    }
    const {timePeriod, dispatch} = useContext(DashboardTimeContext);
    const vars = {
        id: params.videoId,
        limit: timePeriod.listLimit,
        period: timePeriod.graphPeriod.period,
        resolution: timePeriod.graphPeriod.resolution,
        startTime: timePeriod.startTime,
        endTime: timePeriod.endTime
    }
    return (
        <Query query={VIDEOSHOW_QUERY} variables={vars}>
            {({ loading, error, data, subscribeToMore }) => {
                if (loading) return <Loading />
                    if (error) {
                        console.log(error)
                        return <div>Error</div>
                    }
                return (
                    <div className="w-full">
                        <div className="flex">
                            <div className="mx-auto sm:px-4 pt-6 pb-8 w-2/3">
                                <h1 className="text-xl border-b">{data.video.title}</h1>
                                <div className="flex flex-col">
                                    <div className="text-sm"><span className="mr-2">Channel:</span>{data.video.channel.title}</div>
                                    <div className="text-sm">
                                        {data.video.tags.map((tag) => {
                                            return (
                                                <span className="ml-2" key={tag.id}>Tag: {tag.name}</span>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="">
                                    { data.video.workflowInstance != null ? 
                                    <>
                                    <h3 className="text-brand-500 py-4 font-normal text-lg">Admin</h3>
                                    <div  className="block flex text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-1/4 m-2">
                                        <Link to={`/admin/instance/${data.video.workflowInstance.id}`}>
                                            Admin Link
                                        </Link>
                                    </div>
                                    </> : <></>
                                    }

                                    <h3 className="text-brand-500 py-4 font-normal text-lg">Edit</h3>
                                    <div  className="block flex text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-1/4 m-2">
                                        <Link to={`/dashboard/videos/${data.video.id}/edit`}>
                                            Edit
                                        </Link>
                                    </div>
                                    <h3 className="text-brand-500 py-4 font-normal text-lg">Captions</h3>
                                        {data.video.captions.map((caption) => {
                                            return (
                                    <div  className="block flex text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-1/4 m-2" key={caption.id}>
                                            <Link to={`/dashboard/videos/${data.video.id}/captions/${caption.id}`} >
                                                Caption ({caption.language})
                                            </Link>
                                    </div>
                                            )
                                        })}
                                    <h3 className="text-brand-500 py-4 font-normal text-lg">QR</h3>
                                    <div className="w-1/4">
                                        <QRCode value={data.video.embedUrl} />
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/3">
                                <div className="w-full h-64 relative">
                                    <VideoPlayer video={data.video} ></VideoPlayer>
                                </div>
                                <div className="flex-col">
                                    <h3 className="text-brand-500 py-4 font-normal text-lg">Embed</h3>
                                    <input readOnly={true} onClick={(evt) => copyEmbedValue(evt)} value={`<iframe src="${data.video.embedUrl}"></iframe>`} className="w-full block rounded appearance-none block bg-grey-200 text-grey-700 border border-grey-300 rounded py-3 px-2" />
                                </div>
                                <div className="flex flex-col">
                                    <h3 className="text-brand-500 py-4 font-normal text-lg">Download</h3>
                                    {data.video.filePresent ?
                                        <>
                                    <a key="open-in-player" href={data.video.embedUrl} target="_blank" className="block flex text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-64 m-2"><FontAwesomeIcon icon={faLink} className="mr-2" />Open in player</a>
                                    <a key="download-original" href={data.video.publicUrl} target="_blank" className="block flex text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-64 m-2"><FontAwesomeIcon icon={faDownload} className="mr-2" />Original</a>
                                        </>
                                    : <span /> }
                                    {data.video.versions.filter(version => version.hasFile && version.filePresent).map((version) => {
                                        return (
                                            <a key={version.mimeType+"-"+version.label+version.id} href={version.publicUrl} target="_blank" className="block flex text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-64 m-2"><FontAwesomeIcon icon={faDownload} className="mr-2" />{version.mimeType} - {version.label}</a>
                                        )
                                    })}

                                </div>
                            </div>
                            {data.video.versions.filter(version => !version.hasFile).length > 0 ?
                                <div className="flex flex-col">
                                    <h3 className="text-brand-500 py-4 font-normal text-lg">Pending transcodings</h3>
                                    <a href={data.video.publicUrl} target="_blank" className="block flex text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-64 m-2"><FontAwesomeIcon icon={faDownload} className="mr-2" />Original</a>
                                    {data.video.versions.filter(version => !version.hasFile).map((version) => {
                                        return (
                                            <span>
                                                {version.mimeType} - {version.label}
                                            </span>
                                        )
                                    })}

                                </div>
                            : <span />}
                        </div>
                        <Dashboard  data={data}  />
                    </div>
                )}}
        </Query>

    )
}


export default Show
