import React from 'react'
import {Pageable} from './types'

interface PaginationNumberProps {
  currentNumber: number;
  active: boolean;
  setPage(page: number): void;
}

const GenerateNumbers = (current: number, max: number, numbersToGenerate: number = 10) : number[] => {
    let numbers = []
    const minNumber = current < (numbersToGenerate / 2) ? 1 : current + numbersToGenerate - 1 > max ? max - numbersToGenerate + 1 : current - (numbersToGenerate / 2)

    const maxNumber = minNumber + numbersToGenerate - 1 > max ? max : minNumber + numbersToGenerate - 1
    for(let i=minNumber;i<=maxNumber;i++) {
        numbers.push(i)
    }
    return numbers
}

const PaginationNumber : React.FC<PaginationNumberProps> = ({currentNumber, active,  setPage}) => {
    const className = active ? "border-brand-50 text-brand-50 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium" : "border-transparent text-brand-500 hover:text-brand-400 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
    return (
        <a href="#" onClick={(evt) => setPage(currentNumber) } className={className} >
        {currentNumber}
        </a>
    )
}

interface PaginationProps {
  paginated: Pageable;
  setPage(page: number): void;
}


const Pagination : React.FC<PaginationProps> = ({paginated, setPage}) => {
    const numberList = GenerateNumbers(paginated.currentPage || 1, paginated.totalPages || 0)
    if(numberList.length == 1) return <span />
    return (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 mt-1">
            <div className="-mt-px w-0 flex-1 flex">
        { paginated.previousPage ?
            <a href="#" onClick={(evt) => setPage(paginated.previousPage) } className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
            <svg className="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            Previous
            </a>
            : <span /> }
            </div>

        <div className="hidden md:-mt-px md:flex">
            {numberList.map((i) => {
                return <PaginationNumber currentNumber={i} active={i==paginated.currentPage} setPage={setPage} key={i} />
            })}
        </div>
            <div className="-mt-px w-0 flex-1 flex justify-end">
        { paginated.nextPage ?
            <a href="#" onClick={(evt) => setPage(paginated.nextPage)} className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
            Next
            <svg className="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
            </a>
            : <span /> }
            </div>
        </nav>
    )
}

export default Pagination

