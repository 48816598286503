import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import MainDashboard from './MainDashboard'
import AccountDashboard from './AccountDashboard'
import PlayerDashboard from './PlayerDashboard'
import ChannelDashboard from './ChannelDashboard'
import TagDashboard from './TagDashboard'
import DomainDashboard from './DomainDashboard'
import VideoDashboard from './VideoDashboard'
import TemplateDashboard from './TemplateDashboard'
import RenderingDashboard from './RenderingDashboard'
import WorkflowDashboard from './WorkflowDashboard'
import WebhookDashboard from './WebhookDashboard'
import ProfileDashboard from './ProfileDashboard'
import SupportDashboard from './SupportDashboard'
import AccountSettingsDashboard from './AccountSettingsDashboard'
import CompsDashboard from './CompsDashboard'

import CaptionFlow from './CaptionFlow/CaptionFlow'

import InteractionFormContainer from '../InteractionForm/Container'

import TextTemplate from './TextTemplate/Dashboard'

import ActionDashboard from './sections/Action'
import DesignDashboard from './sections/Design'
import AdminDashboard from './sections/Admin'
import EditCaption from './Video/EditCaption'



const MainSection = () => {

    return (
        <div className="flex bg-gray-100">
            <main className="w-full flex-grow p-6">
                <Routes>
                    <Route path="/action/caption/:videoId/:captionId" element={ <EditCaption  /> } />
                    <Route path="/action/:token" element={<ActionDashboard  />} />
                    <Route path="/design/*" element={ <DesignDashboard  />} />
                    <Route path="/admin/*" element={ <AdminDashboard  />} />

                    <Route path="/dashboard/channels/*" element={<ChannelDashboard  />} />
                    <Route path="/dashboard/videos/*" element={<VideoDashboard  />} />
                    <Route path="/dashboard/tags/*" element={<TagDashboard  />} />
                    <Route path="/dashboard/players/*" element={<PlayerDashboard />} />
                    <Route path="/dashboard/domains/*" element={<DomainDashboard  />} />
                    <Route path="/dashboard/templates/*" element={<TemplateDashboard  />} />
                    <Route path="/dashboard/comps/*" element={<CompsDashboard />} />
                    <Route path="/dashboard/renderings/*" element={<RenderingDashboard  />} />
                    <Route path="/dashboard/workflows/*" element={<WorkflowDashboard />} />
                    <Route path="/dashboard/webhooks/*" element={<WebhookDashboard  />} />
                    <Route path="/dashboard/accounts/*" element={<AccountDashboard  />} />

                    <Route path="/dashboard/captionflow/*" element={<CaptionFlow  />} />

                    <Route path="/dashboard/text/template/*" element={<TextTemplate />} />

                    <Route path="/dashboard/support" element={<SupportDashboard />} />
                    <Route path="/dashboard/profile" element={<ProfileDashboard  />} />
                    <Route path="/dashboard/settings/*" element={<AccountSettingsDashboard  />} />

                    <Route exact path="/dashboard" element={<MainDashboard  />} />

                    <Route path="/realtor/*" element={<InteractionFormContainer />} />
                    <Route path="/forms/*" element={<InteractionFormContainer />} />
                </Routes>
            </main>
        </div>

    )
}

export default MainSection
