import React, {useContext} from 'react'
import { useQuery } from 'react-apollo'


const Edit = () => {
    return <span>Nothing</span>

}

export default Edit
