// if (!Intl.PluralRules) {
//   require('@formatjs/intl-pluralrules/polyfill')
//   require('@formatjs/intl-pluralrules/dist/locale-data/en')
//   require('@formatjs/intl-pluralrules/dist/locale-data/de')
//   require('@formatjs/intl-pluralrules/dist/locale-data/da')
// }

// if (!Intl.RelativeTimeFormat) {
//   require('@formatjs/intl-relativetimeformat/polyfill')
//   require('@formatjs/intl-relativetimeformat/dist/locale-data/en')
//   require('@formatjs/intl-relativetimeformat/dist/locale-data/de')
//   require('@formatjs/intl-relativetimeformat/dist/locale-data/da')
// }

import en from './translations/en.json'
import da from './translations/da.json'
import de from './translations/de.json'

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== defaultLocale ? formatTranslationMessages(defaultLocale, en) : {}
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== defaultLocale
        ? defaultFormattedMessages[key]
        : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }, {})
}

const defaultLocale = 'en'

const supportedLocales = {
  en: 'English',
  da: 'Dansk',
  de: 'Deutsch',
}

const translationMessages = {
    en: formatTranslationMessages('en', en),
    da: formatTranslationMessages('da', da),
    de: formatTranslationMessages('de', de),
}

export { translationMessages, defaultLocale, supportedLocales }
