import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useParams} from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'
import Modal from '../Modal'
import Pagination from '../../Pagination'
import {User, UserConnections} from '../../types'
import Multiselect from './Multiselect'
import { Mixpanel } from '../../Common/Mixpanel'


export const USER_QUERY = gql`
query User($id: ID!) {
  user(id:$id) {
      id
      email
      name
      createdAt
      lastSignInAt
      isAdmin
      imageUrl
      instanceCount
      teams {
        id
        title
      }

    account {
      id
      teams(limit:1000) {
        id
        title
      }
    }

    level {
        title
      }
  }
}
`

export const UPDATE_USER_MUTATION = gql`
mutation UpdateUser($id: String, $teams: [TeamInput!])  {
  updateUser(id: $id, teams:$teams) {
    id
    name
    email
    teams {
      id
      title
    }
  }
}
`


const User = () => {
  const params = useParams()
  const id = params.userId;

  const [selectedTeams, setSelectedTeams] = useState([])

  const { loading, error, data, subscribeToMore } = useQuery(USER_QUERY, {variables: {id}})
  const [saveUser, savePageResult] = useMutation(UPDATE_USER_MUTATION);

  const save = () => {
    const vars = {
      id: id,
      teams: selectedTeams.map((item) => { return {id: item.id, name: item.title} }),
    }
    const result = saveUser({ variables: vars })
    result.then((done) => {
      Mixpanel.track("Saved user teams", vars) 
      console.log(done)
    }).catch(v => console.error(v))
  }

      if (loading) return <Loading />

      if (error) {
        console.log(error)
        return <div>Error</div>
      }

  return (
    <div>
    <h1>{data.user.email}</h1>
    <h2>Teams</h2>
    <Multiselect title="Teams" options={data.user.account.teams} preSelectedOptions={data.user.teams} optionsChanged={(values) => setSelectedTeams(values)} />
    <div>
  </div>

  <button onClick={() => {save()} } className="bg-brand-500 hover:bg-brand-400 active:bg-brand-600 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Save</button>
  </div>
      )
}
export default User
