import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Loading : React.FC = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-white opacity-75 z-50">
            <span className="text-green-500 opacity-75 mx-auto my-auto block relative">
                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-5x mx-auto" />
            </span>
        </div>
    )
}

export default Loading
