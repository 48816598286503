import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Routes, Route, Link, Outlet } from 'react-router-dom'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import List from './Video/List'
import Show from './Video/Show'
import Edit from './Video/Edit'
import New from './Video/New'
import NewCaption from './Video/NewCaption'
import EditCaption from './Video/EditCaption'
import { Mixpanel } from '../Common/Mixpanel'

const VideoDashboard = () => {
    return (
        <div>
            <div className="flex flex-wrap">
                <div className="flex-grow w-full mx-auto">
                    <Routes>
                    <Route path={`upload/new`} exact element={<New  />} />
                    <Route path={`:videoId/edit`} element={<Edit  /> } />
                    <Route path={`:videoId/captions/new`} element={<NewCaption  /> } />
                    <Route path={`:videoId/captions/:captionId`} element={<EditCaption  /> } />
                    <Route path={`:videoId`} exact element={<Show  />} />
                    <Route index exact element={<List  />} />
                    </Routes>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default VideoDashboard
